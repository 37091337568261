import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './loader.scss';
import { ArthLogo } from '../../../assets/svg';
export default function Loader() {
  return (
    <div>
        <div>
        <Box sx={{ display: 'flex', justifyContent:"center" }}>
        <div>
        <div class="centerLoader">
      <div class="circleLoader pulseLoader roseLoader">
        <div className='rose-color'><ArthLogo/></div>
      </div>
     
      </div>
      <div style={{textAlign:'center'}}>
            Please Wait....
         </div>
        </div>
         
    </Box>
        </div>
        <div>
      
        </div>
    </div>
    
  );
}
