function BusinessVideo({videoUrl}) {
  return (
    
    <div style={{display: "flex", justifyContent: "center", padding: "10px"}}>
      {" "}
      <video controls>
        <source src={videoUrl} type="video/mp4" />
        <source src={videoUrl} type="video/webm" />

      </video>{" "}
    </div>
  );
}

export default BusinessVideo;
