import React from 'react';
import './button.scss';
import PrevButton from './PrevButton';
import NextButton from './NextButton';

const FormButton = (props:any) => {

  const {showPrevButton, showNextButton=true, onNextClick, onPrevClick, isLastPage=false} = props;

  return (
    <div className='partner-button-footer'>
      {showPrevButton && <PrevButton onPrevClick={onPrevClick} />}
      {showNextButton && <NextButton onNextClick={onNextClick} isLastPage={isLastPage}/>}
    </div>
  )
}

export default FormButton
