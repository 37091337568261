import { BlueCircle, PinkCircle, ZigZagPinkCircle, ArthLogo, LanguageChange } from "../../../assets/svg";
import '../../../components/customer-application/headers/headers.scss'

const LoanSummaryHeader =()=>{

    return (
      <div >
        <div className="blurr-images">
          <BlueCircle className='blue-circle' />
          <PinkCircle className='pink-circle' />
          <ZigZagPinkCircle className='zig-zag' />
          <span className="purple-circle"></span>
        </div>
        <div className="blurr-div">
          <div className="arth-logo">
            <ArthLogo/>
          </div>
        </div>
      </div>
    )
}

export default LoanSummaryHeader