import React from "react";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./image-slider.scss";
import { Modal } from "react-bootstrap";

function ImageSlider({ kyc_details }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)};
  const sliderImages = [
    {
      url: kyc_details?.kyc_poa_image_front,
    },
    {
      url: kyc_details?.kyc_poa_image_back,
    },
  ];
  const [activeImageNum, setCurrent] = useState(0);
  const length = sliderImages.length;
  const nextSlide = () => {
    setCurrent(activeImageNum === length - 1 ? 0 : activeImageNum + 1);
  };
  const prevSlide = () => {
    setCurrent(activeImageNum === 0 ? length - 1 : activeImageNum - 1);
  };
  if (!Array.isArray(sliderImages) || sliderImages.length <= 0) {
    return null;
  }
  return (
    <div class="container">
      <section className="image-slider">
        <div class="col-md-2">
          <div class="left-slider-btn">
            <ArrowBackIosIcon onClick={prevSlide} />
          </div>
        </div>
        <div class="col-md">
          {sliderImages.map((currentSlide, ind) => {
            return (
              <div
                className={
                  ind === activeImageNum
                    ? "currentSlide active"
                    : "currentSlide"
                }
                key={ind}
              >
                {ind === activeImageNum && (
                  <img src={currentSlide.url} className="image" alt="" style={{cursor: "pointer"}} onClick={() => handleShow()}/>
                )}
              </div>
            );
          })}
        </div>
        <div class="col-md-2">
          <div class="right-slider-btn">
            <ArrowForwardIosIcon onClick={nextSlide} />
          </div>
        </div>
        <Modal show={show} onHide={handleClose} style={{ justifyContent: "center", alignItems: "center", flex: 1}}>
          <img src={sliderImages[activeImageNum].url} alt="" style={{height: "100%", width: "100%"}} />
        </Modal>
      </section>
    </div>
  );
}

export default ImageSlider;
