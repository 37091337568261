import "./pagination.scss";
import Pagination from "@mui/material/Pagination";

import React, { useState } from "react";

function PaginationComponent({ params, uParams, setUParams }) {
  const [maxNumber, setMaxNumber] = useState(20);
  const [minNumber, setMinNumber] = useState(1);

  const handlePageChange = (e, value) => {
   // console.log(value);
    setUParams({ ...uParams, page: value });
   
    setMinNumber(value === 1 ? 1 : 20 * (value - 1) + 1);
    setMaxNumber(
      value === Math.round(params.total / 20) ? params.total : value * 20
    );
    //console.log(params.total / 20);
  };

  return (
    <div className="pagination-container">
      <div className="row">
        <div className="col-md-auto">
          Showing {minNumber}-{maxNumber}
        </div>

        <div className="col-md-auto pagination-buttons">
          <Pagination
            count={Math.round(params.total / 20)}
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default PaginationComponent;
