import React, { useEffect, useState } from "react";
import "./filter-button.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import FilterComponent from "../FilterComponent/FilterComponent";
import NavNotifications from "../NavNotifications/NavNotifications";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Funnel } from "../../../../assets/svg";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';


function FilterButtons({ setParams, statusCounts }) {
  const [filterData, setFilterData] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState()

  useEffect(() => {
    fetchFilters();
  }, []);

  const fetchFilters = async () => {
    const requestOptions = {
        headers: {'Authorization': process.env.REACT_APP_ICE_AUTHORIZATION}
    }
    const response = await fetch(
      process.env.REACT_APP_ICE_BASE_URL + "/filters-dropdown-values", requestOptions
    );
    const data = await response.json();
    setFilterData(data); //Setting the response into state
  };

  const handleDateClick = () => {
    setShowDatePicker(true);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end)
    setApplied(true);
  };

  const [partnerFilter, setPartnerFilter] = useState();
  const [centerFilter, setCenterFilter] = useState();
  const [typeFilter, setTypeFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [applied, setApplied] = useState(false);
  const [opened, setOpened] = useState();
  const [oldState, setOldState] = useState();

  function createFresh() {
    if (filterData) {
      const partnerData = {};
      const centerData = {};
      const statusData = {};
      const customerData = { New: false, Repeat: false };
      filterData.partners.forEach((value) => {
        partnerData[value] = false;
      });
      filterData.centers.forEach((value) => {
        centerData[value] = false;
      });
      filterData.statuses.forEach((value) => {
        statusData[value] = false;
      });

      setPartnerFilter(partnerData);
      setCenterFilter(centerData);
      setStatusFilter(statusData);
      setTypeFilter(customerData);
      setStartDate()
      setEndDate()
      
      setParams({ status: "", location: "", customer_ucic: "", start_date: "", end_date: "" });
    }
  }

  useEffect(() => {
    createFresh();
  }, [filterData]);

  function paramLoop(obj) {
    let paramStr = "";
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        if (paramStr === "") {
          paramStr += key;
        } else {
          paramStr += `,${key}`;
        }
      }
    });
    return paramStr;
  }

  function formatDate(date) {
    const day =
    date.getDate() < 10
          ? `0${date.getDate()}`
          : date.getDate();

      const month =
      date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1;
      return `${date.getFullYear()}-${month}-${day}`;
  }
  function generateUrlParams() {
    const params = {};
    if (partnerFilter) {
      params["partner"] = paramLoop(partnerFilter);
    }
    if (statusFilter) {
      params["status"] = paramLoop(statusFilter);
    }
    if (centerFilter) {
      params["center"] = paramLoop(centerFilter);
    }
    if (typeFilter) {
      params["customer_type"] = paramLoop(typeFilter);
    }
    if (startDate) {
      params["start_date"] = formatDate(startDate);
    }
    if (endDate) {
      params["end_date"] = formatDate(endDate)
    }

    return params;
  }

  useEffect(() => {
    const urlParams = generateUrlParams();
    setParams(urlParams);
    setApplied(false);
  }, [applied]);

  function previousState(type) {
    if (type === "Partner") {
      setOldState(partnerFilter);
    } else if (type === "Center") {
      setOldState(centerFilter);
    } else if (type === "Customer") {
      setOldState(typeFilter);
    } else if (type === "Status") {
      setOldState(statusFilter);
    }
  }

  function checkApplied() {
    if (applied) {
      return;
    }
    if (opened === "Partner") {
      setPartnerFilter(oldState);
    } else if (opened === "Center") {
      setCenterFilter(oldState);
    } else if (opened === "Customer") {
      setTypeFilter(oldState);
    } else if (opened === "Status") {
      setStatusFilter(oldState);
    }
  }

  if (filterData) {
    return (
      <div className="filters">
        <NavNotifications
          statusCounts={statusCounts}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          setApplied={setApplied}
          applied={applied}
        />
        <div className="filter-buttons">
          <div className="outer-button">
            <Funnel />
          </div>
          <span className="divider" />
          <OverlayTrigger
            rootClose={true}
            trigger="click"
            key="datepicker"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <DatePicker
                  endDate={endDate}
                  selectsRange
                  className="date-picker"
                  startDate={startDate}
                  onChange={handleDateChange}
                  isClearable={true}
                  open={showDatePicker}
                />
              </Popover>
            }
          >
            <div className="inner-button" onClick={() => handleDateClick()}>
              Date <ExpandMoreIcon fontSize="medium"/>
            </div>
          </OverlayTrigger>

          <span className="divider" />
          <OverlayTrigger
            key="partner"
            onEnter={() => {
              setOpened("Partner");
              previousState("Partner");
            }}
            rootClose={true}
            onExit={() => {
              checkApplied();
            }}
            trigger="click"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Body className="popover-body">
                  <FilterComponent
                    type={"Partner"}
                    setApplied={setApplied}
                    data={partnerFilter}
                    setData={setPartnerFilter}
                    setState={setOldState}
                  />
                </Popover.Body>
              </Popover>
            }
          >
            <div className="inner-button">
              Partner{" "}
              <span className="arrow-spc">
                <ExpandMoreIcon fontSize="medium"/>
              </span>
            </div>
          </OverlayTrigger>
          <span className="divider" />
          <OverlayTrigger
            onEnter={() => {
              setOpened("Center");
              previousState("Center");

            }}
            rootClose={true}
            onExit={() => {
              checkApplied();
            }}
            trigger="click"
            key="center"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Body className="popover-body">
                  <FilterComponent
                    type={"Center"}
                    setApplied={setApplied}
                    data={centerFilter}
                    setData={setCenterFilter}
                    setState={setOldState}
                  />
                </Popover.Body>
              </Popover>
            }
          >
            <div className="inner-button">
              Center{" "}
              <span className="arrow-spc">
                <ExpandMoreIcon fontSize="medium"/>
              </span>
            </div>
          </OverlayTrigger>
          <span className="divider" />
          <OverlayTrigger
            onEnter={() => {
              setOpened("Customer");
              previousState("Customer");
            }}
            rootClose={true}
            onExit={() => {
              checkApplied();
            }}
            trigger="click"
            key="customer"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Body className="popover-body">
                  <FilterComponent
                    type={"Customer"}
                    setApplied={setApplied}
                    data={typeFilter}
                    setData={setTypeFilter}
                    setState={setOldState}
                  />
                </Popover.Body>
              </Popover>
            }
          >
            <div className="inner-button">
              Type{" "}
              <span className="arrow-spc">
                <ExpandMoreIcon fontSize="medium"/>
              </span>
            </div>
          </OverlayTrigger>
          <span className="divider" />
          <OverlayTrigger
            onEnter={() => {
              setOpened("Status");
              previousState("Status");
            }}
            rootClose={true}
            onExit={() => {
              checkApplied();
            }}
            trigger="click"
            key="status"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Body className="popover-body">
                  <FilterComponent
                    type={"Status"}
                    setApplied={setApplied}
                    data={statusFilter}
                    setData={setStatusFilter}
                    setState={setOldState}
                  />
                </Popover.Body>
              </Popover>
            }
          >
            <div className="inner-button">
              Status{" "}
              <span className="arrow-spc">
                <ExpandMoreIcon fontSize="medium"/>
              </span>
            </div>
          </OverlayTrigger>
          <span className="divider" />
          <div
            onClick={() => {
              createFresh();
            }}
          >
            <div className="outer-button outer-btn-scnd">
              <ReplayOutlinedIcon fontSize="small" color="#707070" sx={{marginRight: "2px"}} />
              Reset
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterButtons;
