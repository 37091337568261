import { useDispatch, useSelector } from "react-redux";
import { BlueCircle, PinkCircle, ZigZagPinkCircle, ArthLogo, LanguageChange } from "../../../assets/svg";
import './headers.scss';
import { changeLanguage } from "../../../store/actions/customer-application/languageAction";

const JourneyHeader = ({heading}:any) => {

  const currentLanguage = useSelector((state:any) => (state.language.language));
  const current_action = useSelector((state:any) => (state.current_action.action));
  let journey_list = useSelector((state:any) => (state.journey_list?.list));
  journey_list = journey_list?.includes('wait_for_disbursal') ? journey_list?.slice(0, journey_list?.indexOf('wait_for_disbursal')) : journey_list;
  const dispatch = useDispatch();

  const switchLanguage = () => {
    const lang = currentLanguage === 'English' ? 'Hindi' : 'English';
    localStorage.setItem('lang', lang);
    dispatch(changeLanguage(lang));
  }

  const progress = () => {
    let done = (journey_list?.indexOf(current_action)+1)/journey_list?.length;
    return `${done*100}%`;
  }

  return (
    <>
      <div className="blurr-images">
        <BlueCircle className='blue-circle' />
        <PinkCircle className='pink-circle' />
        <ZigZagPinkCircle className='zig-zag' />
        <span className="purple-circle"></span>
      </div>
      <div className="blurr-div">
        <div className="arth-logo">
          <ArthLogo/>
          <span className="custom-progress-bar">
            <div className="progress-top">
	            <div className="progress-done" style={{width: progress(), opacity: 1}} >
	            </div>
            </div>
          </span>
        </div>
        <div className="heading">
          {heading} <span onClick={switchLanguage}><LanguageChange /></span>
        </div>
      </div>
    </>
  )
}

export default JourneyHeader;
