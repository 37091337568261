import "./navbar.scss";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HelpIcon from "@mui/icons-material/Help";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useState } from "react";
import { Mixpanel } from "../../../../../routing/MixPannelWrapper";
import { useLocation } from "react-router-dom";

const UdyamNavbar = ({ setScreen, screen }) => {
  const location = useLocation();
  const { mobile, name } = location.state || {};

  const handleClick = (screen) => {
    setScreen(screen);
  };

  return (
    <>
      <div className="udyam">
        <div className="udyam-navbar">
          <div className="nav-icon">
            <div className={screen === "UdyamDashBoard" ? "active" : ""}>
              <div onClick={() => handleClick("UdyamDashBoard")}>
                <HomeIcon />
              </div>
            </div>
            <div
              className={screen === "Paymint" ? "active" : ""}
              onClick={() => {
                Mixpanel.identify(
                  mobile +
                    "-" +
                    localStorage.getItem("Device") +
                    "-" +
                    "Paymint Section"
                );
                Mixpanel.track("Dashboard- Payments Section", "");
                Mixpanel.people.set({
                  $first_name: name,
                });
              }}
            >
              <div onClick={() => handleClick("Paymint")}>
                <QrCode2Icon />
              </div>
            </div>
            <div
              className={screen === "Credit" ? "active" : ""}
              onClick={() => {
                Mixpanel.identify(
                  mobile +
                    "-" +
                    localStorage.getItem("Device") +
                    "-" +
                    "Credit Section"
                );
                Mixpanel.track("Dashboard- Credit Section", "");
                Mixpanel.people.set({
                  $first_name: name,
                });
              }}
            >
              <div onClick={() => handleClick("Credit")}>
                <AccountBalanceWalletIcon />
              </div>
            </div>
            <div
              className={screen === "Help" ? "active" : ""}
              onClick={() => {
                Mixpanel.identify(
                  mobile +
                    "-" +
                    localStorage.getItem("Device") +
                    "-" +
                    "Queries Section"
                );
                Mixpanel.track("Dashboard- Queries Section", "");
                Mixpanel.people.set({
                  $first_name: name,
                });
              }}
            >
              <div onClick={() => handleClick("Help")}>
                <HelpIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UdyamNavbar;
