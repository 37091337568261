import "./nav-notifications.scss";

import React, { useEffect, useState } from "react";

function NavNotifications({
  statusCounts,
  statusFilter,
  setStatusFilter,
  setApplied,
  applied,
}) {
  const [activeTab, setActiveTab] = useState("All");

  const filterList = {
    All: 0,
    Pending: statusCounts?.["In Progress"] ?? 0,
    "On Hold": statusCounts?.["On Hold"] ?? 0,
    Rejected: statusCounts?.["Rejected"] ?? 0,
    "Not Offered": statusCounts?.["Not Offered"] ?? 0
  };
  const handleTabClick = (filter) => {
    setActiveTab(filter);
    const newStatus = {};
    Object.keys(statusFilter).map((key) => (newStatus[key] = false));
    if (filter === "Pending") {
      newStatus["In Progress"] = true;
    }
    else if (filter === "On Hold") {
      newStatus["On Hold"] = true;
    }
    else if (filter === "Rejected") {
      newStatus["Rejected"] = true;
    }
    else if (filter === "Not Offered") {
      newStatus["Not Offered"] = true;
    }

    setStatusFilter(newStatus);
    setApplied(true)
  };

  useEffect(() => {
    if (!applied) {
      return
    }
    const newStatus = {};
    Object.keys(statusFilter).map((key) => (newStatus[key] = false));
    if (activeTab === "Pending") {
      newStatus["In Progress"] = true
      if (JSON.stringify(statusFilter) !== JSON.stringify(newStatus)) {
        setActiveTab("All")
      }
    }
    else if (activeTab === "On Hold") {
      newStatus["On Hold"] = true
      if (JSON.stringify(statusFilter) !== JSON.stringify(newStatus)) {
        setActiveTab("All")
      }
    }
    else if (activeTab === "Rejected") {
      newStatus["Rejected"] = true
      if (JSON.stringify(statusFilter) !== JSON.stringify(newStatus)) {
        setActiveTab("All")
      }
    }  
    else if (activeTab === "Not Offered") {
      newStatus["Not Offered"] = true
      if (JSON.stringify(statusFilter) !== JSON.stringify(newStatus)) {
        setActiveTab("All")
      }
    }  
  }, [applied, statusFilter]);

  return (
    <div>
      <div className="row text-style">
        {Object.keys(filterList).map((filter) => (
          <div className="col-md-auto ">
            <div className="grid-layout">
              <div
                key={filter}
                className={`filter-tab ${activeTab === filter ? "active" : ""}`}
                onClick={() => handleTabClick(filter)}
              >
                {filter}
                {filter !== "All" && (
                  <div class="postion-absolute translate-middle badge rounded-pill noti-circle">
                    {filterList[filter]}
                  </div>
                )}
              </div>
            </div>
            <div className={`${activeTab === filter ? "underline" : ""}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavNotifications;
