import BureauDetails from "../BureauDetails/BureauDetails";
import { Col, Row } from "react-bootstrap";
import CoBorrowerKYC from "../KYC/CoBorrowerKYC";
import CoBorrowerDemographics from "../DetailsBox/CoBorrowerDemographics";


function CoBorrowerDetailsTab({ userData, report, activeTab }) {

  
  
  return (
    <div>
      <Row style={{ paddingTop: "20px" }}>
        <Col>
          <CoBorrowerDemographics
            demographicsData={userData?.co_borrower_details?.demographics}
          />
        </Col>
        <Col>
          <BureauDetails bureauData={userData?.co_borrower_bureau_details} bureauType={"coborrower"} userId={userData?.basic_details?.id} bureauHistory={userData?.co_borrower_bureau_history} report={report} activeTab={activeTab} />
        </Col>
      </Row>

      <Row style={{ paddingInline: "10px", marginTop: "20px" }}>
        <CoBorrowerKYC data={userData?.co_borrower_details?.kyc_details}/>
      </Row>
    </div>
  );
}

export default CoBorrowerDetailsTab;
