import React from "react";
import "./transaction-summary.scss";
import Col from "react-bootstrap/Col";

function TransactionSummary(transaction_data) {

  return (
    <div style={{marginBottom: "10px"}}>
      <div style={{margin: "10px"}}>
      Transaction Summary
      </div>
     
      <div className="hdng-container">
        <Col xs={8}>
          <span className="first-col-style">Month</span>
        </Col>
        <Col class="col-md">Transactions</Col>
        <Col class="col-md">Amount</Col>
      </div>
      <div>
        
        <div>
          { Object.keys(transaction_data["transaction_data"]).map ((row, index) => 
        <div className="trns-cntnt">
          <Col xs={8}>
            <span className="first-col-style">{row}</span>
          </Col>
          <Col class="col-md">{transaction_data["transaction_data"][row]["txn_count"]}</Col>
          <Col class="col-md">₹{transaction_data["transaction_data"][row]["txn_volume"]}</Col>
        </div>
        )}
        </div>

      </div>
    </div>
  );
}

export default TransactionSummary;
