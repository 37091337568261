import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import "./offer-slider.scss";

function OfferSlider({
  setAmount,
  amount,
  defaultAmount,
  userData,
  offerStatus,
}) {
  const handleChange = (_, newValue) => {
    setAmount(newValue);
  };

  const nf = new Intl.NumberFormat();
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "15px",
        marginTop: "5px",
        height: "100%",
      }}
    >
      <div class="row">
        <div class="col-md">Amount</div>
        <div class="col-md-auto" style={{ marginLeft: "auto" }}>
          {offerStatus != "not_offered" ? (
            "₹ " + nf.format(amount)
          ) : (
            <span style={{ color: "red", fontWeight: "bold" }}>
              Not Offered
            </span>
          )}
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Box sx={{ width: "auto" }}>
          <Slider
            sx={{
              "& .MuiSlider-thumb": {
                color: "#F78D2D !important",
              },

              "& .MuiSlider-rail": {
                color: "#E9ECEF !important",
                paddingBlock: "5px !important",
                backgroundColor: "#E9ECEF !important",
                border: "0px !important",
              },
            }}
            // marks={5}
            step={5000}
            // marks = {5}
            value={amount}
            valueLabelDisplay="auto"
            // min={(defaultAmount - ((defaultAmount * 20)/100))}
            // max={(defaultAmount + ((defaultAmount * 20)/100))}
            max={parseInt(userData?.basic_details?.maximum_loan_limit)}
            min={parseInt(userData?.basic_details?.minimum_loan_limit)}
            onChange={handleChange}
            valueLabelFormat={"₹ " + nf.format(amount)}
            disabled={
              (userData?.ice_status === "In Progress" ||
                userData?.ice_status === "On Hold") &&
              offerStatus !== "not_offered"
                ? false
                : true
            }
          />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2" className="slider-sub-text">
              {"₹ " +
                (offerStatus === "not_offered"
                  ? 0
                  : nf.format(userData?.basic_details?.minimum_loan_limit))}
            </Typography>
            <Typography variant="body2" className="slider-sub-text">
              {"₹ " +
                (offerStatus === "not_offered"
                  ? 0
                  : nf.format(userData?.basic_details?.maximum_loan_limit))}
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default OfferSlider;
