import { lazy, useEffect, useState } from 'react';
import { Route, Routes, useParams, Navigate } from 'react-router-dom';
import Login from '../../pages/hunting/login/Login';
import HuntingData from '../../pages/hunting/list/HuntingData';
import HuntingForm from '../../pages/hunting/form/HuntingForm';
import HuntingQC from '../../pages/hunting/qc/HuntingQC';
import GlobalLoader from '../../components/customer-application/loader/GlobalLoader';



const HuntingRoute = () => {

  const [activateRoutes, setActivateRoutes] = useState<boolean>(true);

  return (
    <>
      <Routes>
        {activateRoutes && <>
          <Route path='login' element={<Navigate to="/" />} />
          <Route path='list' element={<HuntingData />} />
          <Route path='details/:formId' element={<HuntingQC />} />
          <Route path='create' element={<HuntingForm />} />
        </>}
      </Routes>    
      <GlobalLoader />
    </>
  )
}

export default HuntingRoute;
