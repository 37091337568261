import {useEffect, useRef, useState} from 'react';
import './file-picker.scss';
import { ToolTipSVG, WhiteCrossButtonSVG } from '../../../assets/svg';
import { Tooltip } from 'react-tooltip';

const FilePicker = (props:any) => {

  const {heading, 
    supportiveText, 
    accept,
    identifier,
    handleInput,
    file = '',
    required = false,
    disabled,
    reKycRemarks,
    toolTipText,
    convertType = 'base64',
    formDataKey = 'shop_video'
  } = props;
  let fileInputRef = useRef(null);
  const [fileName, setFileName] = useState<any>(file);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e:any) => {
    const files = e.target.files;
    setFileName(files[0].name);
    if(convertType === 'formData'){
      convertToFormData(e.target.files[0]);
    } else {
      converFileToBase64(e.target.files[0]);
    }
  }

  const handleCrossButtonClick = () => {
    setFileName('');
  }

  useEffect(() => {
    setFileName(file);
  },[file]);

  const converFileToBase64 = (file:any) => {
    if(file){
      const reader = new FileReader();

      reader.onloadend = () => {
        const result = reader.result;
        handleInput(identifier, result)
      }

      reader.readAsDataURL(file);
    }
  }

  const convertToFormData = (file:any) => {
    if(file){
      const formData:any = new FormData();
      formData.append(formDataKey, file);
      handleInput(identifier, formData);
    }
  }

  return (
    <div className="">
      <div className='partner-heading'>
        {heading} {(required && <>*</>)} &nbsp;
        <a data-tooltip-id="my-tooltip" data-tooltip-content={toolTipText}>
          <ToolTipSVG className='question-svg'/>
        </a>
        <Tooltip id="my-tooltip" />
      </div>
      <input type="file"
        style={{display: 'none', cursor: 'pointer'}} 
        ref={fileInputRef} 
        onChange={(e) => handleFileChange(e)}
        accept={accept}
        disabled={disabled}
      />
      {fileName ? 
        <div className='picked-file'>{fileName} <span onClick={handleCrossButtonClick}><WhiteCrossButtonSVG /></span></div> : 
        <div className="file-picker-button" onClick={handleFileSelect}>Choose File</div>}
      {supportiveText && <div className="supportive-text">{supportiveText}</div>}
      {reKycRemarks?.map((remark:string, index:number) => {
        return (
          <div key={index} className='partner-supportive-text-red'>{remark}</div>
        )
      })}
    </div>
  )
}

export default FilePicker;
