import { useEffect, useState } from "react";
import LoanSummaryHeaderPWA from "./loansummaryHeader2";
import "./repayment-schedule-screen.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApiService } from "../../../../services/api/apiService";

const RepaymentSchedule = () => {
  const [repaymentTable, setRepaymentTable] = useState([]);
  const [convertedDate, setConvertedDate] = useState("");
  const displayText = useSelector((state: any) => state.language.languageText);
  const location = useLocation();
  const [ediOpted, setEDIOpted] = useState(true);
  const { loanAmount, transferAmount, tranferToBankAccount, loanDetails } =
    location.state || {};
  const apiService = new ApiService();
  // const loanDetails = useSelector((state: any) => state.loan.loan);
  // const EDI = localStorage.getItem("BP");

  useEffect(() => {
    repayment_Table();
    if (ediOpted === true) {
      localStorage.setItem("EDI", "True");
    } else {
      localStorage.setItem("EDI", "False");
    }
  }, [ediOpted]);

  function formatNumberToIndianStyle(num) {
    const numString = num.toString();
    const lastThreeDigits = numString.slice(-3);
    const otherDigits = numString.slice(0, -3);

    if (otherDigits) {
      return `${otherDigits.replace(
        /\B(?=(\d{2})+(?!\d))/g,
        ","
      )},${lastThreeDigits}`;
    }

    return lastThreeDigits;
  }

  const repayment_Table = () => {
    const payload = {
      amount: parseInt(loanDetails?.loan_amount),
      loan_uid: loanDetails?.loan_uid,
    };

    apiService.getRepaymentDetails(payload).then((response: any) => {
      if (response?.data) {
        setRepaymentTable(response?.data?.schedule);
      }
    });
  };

  const handleDateFormat = () => {
    const convertedDate = new Date(repaymentTable[0]?.due_date);
    const day = String(convertedDate.getDate()).padStart(2, "0");
    const month = String(convertedDate.getMonth() + 1).padStart(2, "0");
    const year = convertedDate.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleEDI = (event) => {
    const isChecked = event.target.checked;
    setEDIOpted(isChecked);
    if (isChecked) {
    }
  };

  // console.log(tranferToBankAccount)

  return (
    <>
      <LoanSummaryHeaderPWA />
      <div className="repayment-details-page">
        <h6 style={{ fontSize: "0.95rem" }}>Repayment Schedule</h6>
        <br></br>
        <table className="description-table">
          <thead>
            <tr style={{ background: "#F2F2F2" }}>
              <th>S.no</th>
              <th></th>
              <th>Outstanding Principal</th>
              <th>Principal</th>
              <th>Interest</th>
              <th>Repayment Amount</th>
            </tr>
          </thead>
          <tbody>
            {repaymentTable.map((installment, index) => (
              <tr>
                <td>{index + 1}</td>
                <td></td>
                <td>
                  {formatNumberToIndianStyle(
                    parseInt(installment.outstanding_principal)
                  )}
                </td>
                <td>
                  {formatNumberToIndianStyle(
                    parseInt(installment.principal_due)
                  )}
                </td>
                <td>
                  {formatNumberToIndianStyle(
                    parseInt(installment.interest_due)
                  )}
                </td>
                <td>
                  {formatNumberToIndianStyle(parseInt(installment.total_due))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="breakLine"></div>
        <br></br>
        {false && (
          <>
            <div className="repayment">
              <div>
                <input
                  type="checkbox"
                  className="check-box"
                  onChange={handleEDI}
                  checked={ediOpted}
                />
              </div>
              <div className="heading-1">Repayment Type - Daily</div>
            </div>
            <br></br>
          </>
        )}
        <div
          style={{
            fontSize: "0.75rem",
            display: "grid",
            alignItems: "center",
            marginTop: "5%",
            gridTemplateColumns: "1fr auto",
          }}
        >
          <div>
            <h6 style={{ fontSize: "0.8rem", margin: 0 }}>
              {displayText?.transfer_to_bank}
            </h6>
          </div>
          <div
            style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              textAlign: "right",
            }}
          >
            ₹ {(tranferToBankAccount)}
            {/* $ 54,000 */}
          </div>
        </div>
        <br></br>
        <div
          style={{
            fontSize: "0.75rem",
            display: "grid",
            alignItems: "center",
            // marginTop: "5%",
            gridTemplateColumns: "1fr auto",
          }}
        >
          <div>
            <h6 style={{ fontSize: "0.8rem", margin: 0 }}>
              First Payment Date
            </h6>
          </div>
          <div style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              textAlign: "right",
            }}>
            {handleDateFormat()}
          </div>
        </div>
        <br></br>
        <div style={{ color: "#9f9d9d9d", fontSize: "0.75rem" }}>
          <tr style={{ fontWeight: "500" }}>
            {" "}
            Repayment Schedule is subject to change basis the date of loan
            disbursement
          </tr>
        </div>
        <br></br>
        <h6 style={{ color: "#9f9d9d9d", fontSize: "0.85rem" }}>
          Contingent Charges
        </h6>
        <div style={{ color: "#9f9d9d9d", fontSize: "0.75rem" }}>
          <table>
            <tr>
              <th style={{ fontWeight: "500" }}>Delay Charges</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style={{ fontWeight: "500" }}>
                {" "}
                0.1% of EMI per day in case of delayed payment
              </th>
            </tr>
          </table>
          <br></br>
          <tr style={{ fontWeight: "500" }}>
            {" "}
            Delay Charges are applicable, in case the repayment is delayed from
            due date for any of the EPI
          </tr>
        </div>
        <br></br>
        <div style={{ color: "#9f9d9d9d", fontSize: "0.75rem" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tr>
              <th style={{ fontWeight: "500", textAlign: "left" }}>
                Foreclosure Charges
              </th>
              <th style={{ padding: "2px" }}></th>
              <th style={{ fontWeight: "500", textAlign: "left" }}>
                Nill, Option to opt after 45 days
              </th>
            </tr>
          </table>
        </div>
        <br></br>
        <h6 style={{ color: "#9f9d9d9d", fontSize: "0.95rem" }}>
          Customer Support Details
        </h6>
        <div
          style={{
            color: "#9f9d9d9d",
            fontSize: "0.75rem",
            display: "flex",
            gap: "12%",
          }}
        >
          <div>Email ID</div>
          <div>
            <a
              href="mailto:support@arthdigital.net"
              style={{ color: "#ee3e80" }}
            >
              Support@arthdigital.net
            </a>
          </div>
        </div>
        <div
          style={{
            color: "#9f9d9d9d",
            fontSize: "0.75rem",
            display: "flex",
            gap: "9%",
            marginTop: "2%",
          }}
        >
          <div>Phone No.</div>
          <div>
            <a href="tel:9044052259" style={{ color: "#ee3e80" }}>
              9044052259
            </a>
          </div>
        </div>
        <div
          style={{
            color: "#9f9d9d9d",
            fontSize: "0.75rem",
            display: "flex",
            gap: "12.5%",
            marginTop: "2%",
          }}
        >
          <div>Website</div>
          <div>
            <a href="https://arthimpact.in/" style={{ color: "#ee3e80" }}>
              www.arthimpact.in
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepaymentSchedule;
