import React, { useState } from "react";
import BreadCrumb from "../../../components/partner-application/breadcrumb/BreadCrumb";
import TextInput from "../../../components/partner-application/text-input/TextInput";
import BoxInput from "../../../components/partner-application/box-input/BoxInput";
import "../invoice/invoice-form.scss";
import ActionButton from "../../../components/partner-application/button/ActionButton";
import { AddWhiteSVG } from "../../../assets/svg";
import { PartnerService } from '../../../services/api/partnerService';
import { Toaster } from '../../../utils/utilities/Toaster';
import { useNavigate } from "react-router-dom";
import { Padding, Style } from "@mui/icons-material";

const breadCrumbData = [{ name: "Invoice / Create Invoice", path: "" }];


interface DescriptionItem {
  description: string;
  quantity: any;
  rate: any;
  taxPercentage: any;
  identifier: number;
  amount: any;
  igst: any;
  sgst: any;
  cgst: any;
  gstId: any;
}

interface FeeDetail {
  id: number;
  feesName: string;
  feesAmount: number;
}

const DescriptionTable = ({
  descriptions,
  onDeleteItem,
  onUpdateDescription,
  taxType,
  gstType
}: {
  descriptions: DescriptionItem[];
  onDeleteItem: (e, identifier) => void;
  onUpdateDescription: (identifier, updatedDescription: DescriptionItem) => void;
  taxType
  gstType
}) => (
  <table className="description-table">
    <thead>
    <tr>
  <th>S. No.</th>
  <th>Description</th>
  <th>Quantity</th>
  <th>Rate</th>
  {(taxType !== 'None') && (
    <>
    {taxType === 'Gst' && (<th>HSN/SAC</th>)}
      <th>{taxType.toUpperCase()} (%)</th>
      {taxType === 'Gst' && (
        <>
          {gstType === 'igst' ? (
            <th>IGST (₹)</th>
          ) : (
            <>
              <th>SGST (₹)</th>
              <th>CGST (₹)</th>
            </>
          )}
        </>
      )}
    </>
  )}
  <th>Amount</th>
  <th>Actions</th>
</tr>
    </thead>
    <tbody>
      {descriptions.map((description, index) => (
        <tr key={index + 1}>
          <td>{index + 1}</td>
          <td>
            <input
              type="text"
              value={description.description}
              onChange={(e) =>
                onUpdateDescription((description.identifier || (index + 1)), {
                  ...description,
                  description: e.target.value,
                })
              }
            />
          </td>
          <td>
            <input
              type="number"
              value={description.quantity}
              onChange={(e) =>
                onUpdateDescription((description.identifier || (index + 1)), {
                  ...description,
                  quantity: parseFloat(e.target.value),
                })
              }
            />
          </td>
          <td>
            <input
              type="number"
              value={description.rate}
              onChange={(e) =>
                onUpdateDescription((description.identifier || (index + 1)), {
                  ...description,
                  rate: parseFloat(e.target.value),
                })
              }
            />
          </td>
          {(taxType === 'Gst') &&
              <td>
                <input
                  type="number"
                  value={description.gstId}
                  onChange={(e) =>
                    onUpdateDescription((description.identifier || (index + 1)), {
                      ...description,
                      gstId: parseFloat(e.target.value),
                    })
                  }
                />
              </td>
              }
          {(taxType !== 'None') &&
              <td>
                <input
                  type="number"
                  value={description.taxPercentage}
                  onChange={(e) =>
                    onUpdateDescription((description.identifier || (index + 1)), {
                      ...description,
                      taxPercentage: parseFloat(e.target.value),
                    })
                  }
                />
              </td>
              }
              {(gstType === 'igst') &&
              <td>
                <input
                  type="text"
                  value={(description.quantity * description.rate * (description.taxPercentage / 100)).toFixed(2)}
                  disabled={true}
                  onChange={(e) =>
                    onUpdateDescription((description.identifier || (index + 1)), {
                      ...description,
                      igst: parseFloat((description.quantity * description.rate * ( (description.taxPercentage / 100))).toFixed(2))
                    })
                  }
                    />
              </td>
              }
              {(gstType === 'cgst') && (
                <>
              <td>
              <input
                  type="text"
                  value={`₹${((description.quantity * description.rate * (description.taxPercentage / 100)/2).toFixed(2))}`}
                  disabled={true}
                  onChange={(e) =>
                    onUpdateDescription((description.identifier || (index + 1)), {
                      ...description,
                      sgst: parseFloat((description.quantity * description.rate * (description.taxPercentage / 100)/2).toFixed(2))
                    })
                  }
                    />
              </td>
              <td>
              <input
                  type="text"
                  value={`₹${(description.quantity * description.rate * ( description.taxPercentage / 100)/2).toFixed(2)}`}
                  disabled={true}
                  onChange={(e) =>
                    onUpdateDescription((description.identifier || (index + 1)), {
                      ...description,
                      cgst: parseFloat((description.quantity * description.rate * ((description.taxPercentage / 100))/2).toFixed(2))
                    })
                  }
                    />
            </td>
            </>
              )
              }
          <td>
          
            <input
              type="text"
              value={`₹${(description.quantity * description.rate * (1 + description.taxPercentage / 100)).toFixed(2)}`}
              disabled={true}
              onChange={(e) =>
                onUpdateDescription((description.identifier || (index + 1)), {
                  ...description,
                  amount: parseFloat((description.quantity * description.rate * (1 + (description.taxPercentage / 100))).toFixed(2))
                })
              }
            />
          </td>
          <td>
            <button onClick={(e) => onDeleteItem(e, (description.identifier || (index + 1)))}>⨯</button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const Invoice_form = () => {
  const partnerService = new PartnerService();
  const partnerId = localStorage.getItem('currentPartnerId');
  const toaster = new Toaster();
  const navigate = useNavigate();
  const [isPopUPVisible, setIsPopUPVisible] = useState(false);
  const [descriptions, setDescriptions] = useState<DescriptionItem[]>([
    {
      identifier: 1,
      description: "",
      quantity: 0,
      rate: 0,
      taxPercentage: 0,
      amount: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      gstId: 0,
    },
  ]);
  const [form, setForm] = useState<Object>({
    mobile: '',
    invoice_date: '',
    customer_name: '',
    pincode: '',
    business_name: '',
    address: ''
  });
  const [customerData, setCustomerData] = useState({})
  const [feesInputs, setFeesInputs] = useState([]);
  const [currentInput, setCurrentInput] = useState({});

  const handleAddClick = () => {
    if (isPopUPVisible == true) {
      setTaxType('None');
      setGstType('None');
      setIsPopUPVisible(false);
    } else {
      setIsPopUPVisible(true);
    }
  };

  const addDescriptionItem = () => {
    const newId = descriptions.length + 1;
    setDescriptions((prevDescriptions) => [
      ...prevDescriptions,
      {
        identifier: newId,
        description: "",
        quantity: 0,
        rate: 0,
        taxPercentage: 0,
        amount: 0,
        igst: 0,
        sgst: 0,
        cgst: 0,
        gstId: 0,
      }
    ]);
    setCurrentInput({});
  };

  const deleteDescriptionItem = (e, identifier) => {
    e.preventDefault();
    setDescriptions((prevDescriptions) =>
      prevDescriptions.filter((description) => description.identifier !== identifier)
    );
    setCurrentInput({});
  };

  const updateDescriptionItem = (identifier, updatedDescription: DescriptionItem) => {
    updatedDescription['amount'] = parseFloat((updatedDescription['quantity'] * updatedDescription['rate'] * (1 + updatedDescription['taxPercentage'] / 100)).toFixed(2))
    if (gstType === 'cgst') {
      updatedDescription['sgst'] = parseFloat(((updatedDescription['quantity'] * updatedDescription['rate'] * ( updatedDescription['taxPercentage'] / 100) / 2).toFixed(2)));
      updatedDescription['cgst'] = parseFloat(((updatedDescription['quantity'] * updatedDescription['rate'] * ( updatedDescription['taxPercentage'] / 100) / 2).toFixed(2)));
    } else if (gstType === 'igst') {
      updatedDescription['igst'] = parseFloat(((updatedDescription['quantity'] * updatedDescription['rate'] * ( updatedDescription['taxPercentage'] / 100)).toFixed(2)));
    }
    setDescriptions((prevDescriptions) =>
      prevDescriptions.map((description) =>
        (description.identifier === identifier) ? updatedDescription : description
      )
    );
    setCurrentInput({});
  };

  const [discount, setDiscount] = useState(0);

  const InvoiceSummary = () => {
    // const [feesInputs, setFeesInputs] = useState([]);




    const handleDiscountChange = (key, value) => {
      setForm((prevForm) => ({
        ...prevForm,
        [key]: value,
      }));
      setCurrentInput({ id: key });
      setDiscount(parseFloat(value));
    };

    const addFeesInput = () => {
      const newId = Math.random().toString(36).substr(2, 9);
      setFeesInputs(prevInputs => [...prevInputs, { id: newId, feesName: '', feesAmount: 0 }]);
      setCurrentInput({});
    };

    const handleFeesNameChange = (e, id) => {
      const updatedFeesInputs = feesInputs.map(input => {
        if (input.id === id) {
          return { ...input, feesName: e.target.value };
        }
        return input;
      });
      setCurrentInput({ id: id, name: 'feesName' })
      setFeesInputs(updatedFeesInputs);
    };

    const handleFeesAmountChange = (e, id) => {
      const updatedFeesInputs = feesInputs.map(input => {
        if (input.id === id) {
          return { ...input, feesAmount: parseFloat(e.target.value) };
        }
        return input;
      });
      setCurrentInput({ id: id, name: 'feesAmount' })
      setFeesInputs(updatedFeesInputs);
    };

    const handleDeleteFees = (id: string) => {
      setFeesInputs((prevInputs) => prevInputs.filter(input => input.id !== id));
      setCurrentInput({});
    };

    const totalFees = feesInputs.reduce((total, input) => total + (input.feesAmount || 0), 0);

    const itemsTotal = descriptions.reduce((total, item) => {
      // console.log(total);
      // console.log(item.amount);
      return (total + (item.quantity * item.rate));
    }, 0);

    const tax = descriptions.reduce((total, item) => {
      return (total + ((item.taxPercentage / 100) * item.quantity * item.rate));
    }, 0);

    const total = itemsTotal - (discount || 0) + totalFees + tax;

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>Items Total</td>
              <td>{itemsTotal.toFixed(2)}</td>
            </tr>
            <br></br>
            <tr>
              <td>Tax</td>
              <td>{tax.toFixed(2)}</td>
            </tr>
            <br></br>
            <tr>
              <button type="button" className="custom-action-button" onClick={addFeesInput}>Add Fees</button>
              <br></br>
              <tr>
                {feesInputs.map((input) => (
                  <div key={input.id}>
                    <br></br>
                    <td>
                      <input
                        type="text"
                        style={{ width: '120px', border: '1px solid #ddd'}}
                        className="input"
                        value={input.feesName}
                        onChange={e => handleFeesNameChange(e, input.id)}
                        placeholder="Fees Name"
                        autoFocus={(currentInput['id'] === input.id) && (currentInput['name'] === "feesName")}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <input
                        type="number"
                        style={{ width: '80px', border: '1px solid #ddd'}}
                        className="input"
                        value={input.feesAmount}
                        onChange={e => handleFeesAmountChange(e, input.id)}
                        placeholder="Fees Amount"
                        autoFocus={(currentInput['id'] === input.id) && (currentInput['name'] === "feesAmount")}
                      />
                    </td>
                    <td>
                      <button onClick={() => handleDeleteFees(input.id)}>⨯</button>
                    </td>
                  </div>
                ))}
              </tr>
            </tr>
            <br></br>
            <tr>
              <td>
                <div>
                  <label htmlFor="discount">Discount:</label>
                </div>
              </td>
              <td>
                <input type="number" id="discount" autoFocus={currentInput['id'] === 'discount'} value={form['discount']} onChange={(e) => handleDiscountChange('discount', e.target.value)} style={{ width: '100px', border: '0.5px solid #dee2e6', borderRadius: '0.375rem' }} />
              </td>
            </tr>
            <br></br>
            <tr>
              <td>Total</td>
              <td>{total.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const [taxType, setTaxType] = useState('None');
  const [gstType, setGstType] = useState('None');

  const handleTaxTypeChange = (event) => {
    // console.log(taxType);
    setTaxType(event.target.value);

  };
  const handleGstTypeChange = (event) => {
    setGstType(event.target.value);
  };

  const handleSaveChanges = (taxType: string, gstType: string) => {

    setTaxType(taxType);

    taxType === 'Gst' ? setGstType(gstType) : setGstType('None');

    setIsPopUPVisible(false);
  };

  const isGstTypeSelected = taxType !== 'Gst' ? true : (gstType !== 'None' ? true : false);



  const handleInput = (key: string, value: string) => {
    setForm((prevForm) => ({
      ...prevForm,
      [key]: value,
    }));
    setCurrentInput({});


    if ((key == "mobile") && (value.length == 10)) {
      // console.log("hiiiiiiiiiii")
      // console.log(partnerId);
      partnerService.fetchCustomerData(value, partnerId).then((response: any) => {
        var res = response?.data?.data
        res['pincode'] = (isNaN(parseInt(res['pincode'])) ? "" : res['pincode'])
        setCustomerData(res)
        setForm(res)
      }).catch((error: any) => {
        // toaster.show(true, "Customer's details could not be fetched successfully.");
      })
    }
  }

  const submitForm = () => {
    if ((form['mobile'].length == 10) &&
      form['invoice_date'].length > 0 &&
      (form['customer_name'].length > 0) &&
      form['pincode'].length == 6 &&
      (form['business_name'].length > 0) &&
      form['address'].length > 0) {
      var partner_items = []
      var fee_payload = []
      descriptions.map((description) => {
        const item = {
          item_name: description.description,
          units: description.quantity,
          price_per_unit: description.rate,
          amount: description.amount
        };
      
        if (taxType !== 'None') {
          item[taxType.toLowerCase()] = description.taxPercentage; 
        }
        if(taxType === 'Gst'){
          item['gst_id'] = description.gstId;
          if(gstType === 'igst'){
            item['igst'] = description.igst;
          }else if(gstType === 'cgst'){
            item['cgst'] = description.cgst;
            item['sgst'] = description.sgst;
          }
        }
      
        partner_items.push(item);
      });
      feesInputs.map((fee) => (
        fee_payload.push({
          name: fee.feesName,
          amount: fee.feesAmount
        })
      ));
      var payload = {
        mobile: form['mobile'],
        customer_name: form['customer_name'],
        customer_business_name: form['business_name'],
        address: form['address'],
        pincode: form['pincode'],
        gstin_number: form['gstin_number'],
        invoice_date: form['invoice_date'],
        discount: form['discount'],
        partner_items: partner_items,
        fee: fee_payload
      }

      partnerService.createPartnerInvoice(payload, partnerId).then((response: any) => {
        if (response?.data?.status === 'Success') {
          navigate('/invoice/' + response?.data?.invoice?.invoice_id)
        }
      }).catch((error: any) => {
        toaster.show(true, error?.data?.message);
      })
    } else {
      toaster.show(true, 'Please provide the required inputs');
    }
  }

  return (
    <div className="p-44">
      <div className="d-flex justify-content-between mb-2">
        <div>
          <BreadCrumb breadCrumbData={breadCrumbData} />
        </div>
      </div>
      <div className={`container1 ${isPopUPVisible ? 'blur-background' : ''}`}>
        <div className="mt-4 font-weight-bold">Create Invoice</div>
        <div className="row mt-4">
          <div className="col-md-4 col-sm-6 mb-4">
            <BoxInput
              heading="Mobile Number"
              value={form['mobile'] || ''}
              isCompulsoryField={true}
              inputMode="numeric"
              preInputText="+91"
              noOfBox={10}
              placeholder={0}
              handleInput={handleInput}
              identifier="mobile"
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading="Invoice Date"
              value={form['invoice_date']}
              isCompulsoryField={true}
              handleInput={handleInput}
              identifier="invoice_date"
              type="date"
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading="Name"
              value={form['customer_name'] || customerData['customer_name']}
              isCompulsoryField={true}
              placeholder="Name"
              handleInput={handleInput}
              identifier="customer_name"
            />
          </div>
          {/*</div>*/}
          {/*<div className="row mt-4">*/}
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading="Business Name"
              value={form['business_name'] || customerData['customer_business_name']}
              isCompulsoryField={true}
              handleInput={handleInput}
              identifier="business_name"
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading="Address (Shop No., Building, Street, Area)"
              value={form['address'] || customerData['address']}
              isCompulsoryField={true}
              handleInput={handleInput}
              identifier="address"
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <BoxInput
              heading="PIN Code"
              value={form['pincode'] || customerData['pincode'] || ''}
              isCompulsoryField={true}
              inputMode="numeric"
              noOfBox={6}
              placeholder={0}
              handleInput={handleInput}
              identifier="pincode"
            />
            <div className="small-text" style={{ color: 'green' }}>
              {customerData['city'] && customerData['state'] ? `${customerData['city']}, ${customerData['state']}` : ''}
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading="GST"
              value={form['gstin_number'] || customerData['gstin_number']}
              isCompulsoryField={false}
              handleInput={handleInput}
              identifier="gstin_number"
              maxLength="15"
            />
          </div>
        </div>
        <div className="mt-4 font-weight-bold">Description</div>
        <div className="mt-4" style={{paddingBottom: "10px"}}>
          <button type="button" className="custom-action-button" onClick={handleAddClick}>
            Add Tax
          </button>
        </div>
        <div className="table-responsive" style={{ border: "1px solid #ddd", borderRadius: "12px" }}>
          <form>
            <DescriptionTable
              descriptions={descriptions}
              onDeleteItem={deleteDescriptionItem}
              onUpdateDescription={updateDescriptionItem}
              taxType={taxType}
              gstType={gstType}
            />
          </form>
        </div>
        <div className="mt-4">
          <button type="button" className="custom-action-button" onClick={addDescriptionItem} style={{paddingLeft: "10px"}}>
          <AddWhiteSVG /> Add Item
          </button>
        </div>
        <div className="mt-4 font-weight-bold">Invoice Summary</div>
        <InvoiceSummary />
        <div className="mt-4" style={{ textAlign: "center" }}>
          <button type="button" className="custom-action-button generate-button" onClick={submitForm}>
            Generate
          </button>
        </div>
      </div>

      {isPopUPVisible && (
        <>
          <div className="card popup">
            <div>
              <span>Configure Tax</span>
              <button type="button" onClick={handleAddClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" color="#7f8fa4" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L5.35355 4.64645ZM18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L18.6464 19.3536ZM4.64645 5.35355L18.6464 19.3536L19.3536 18.6464L5.35355 4.64645L4.64645 5.35355Z"></path>
                  <path d="M19.3536 5.35355C19.5488 5.15829 19.5488 4.84171 19.3536 4.64645C19.1583 4.45118 18.8417 4.45118 18.6464 4.64645L19.3536 5.35355ZM4.64645 18.6464C4.45118 18.8417 4.45118 19.1583 4.64645 19.3536C4.84171 19.5488 5.15829 19.5488 5.35355 19.3536L4.64645 18.6464ZM18.6464 4.64645L4.64645 18.6464L5.35355 19.3536L19.3536 5.35355L18.6464 4.64645Z"></path>
                </svg>
              </button>
            </div>

            <div>
              <div>
                <label>1. Select Tax Type<span style={{ color: 'red' }}>*</span></label>

                <select value={taxType} onChange={handleTaxTypeChange} style={{marginLeft: "15px", marginTop: "8px"}}>
                  <option value="None">NONE</option>
                  <option value="Tax">TAX</option>
                  <option value="Gst">GST (India)</option>
                  <option value="Vat">VAT</option>
                  <option value="Ppn">PPN</option>
                  <option value="Sst">SST</option>
                </select>
              </div>
              {taxType == 'Gst' &&
                <div style={{marginLeft: "15px", marginTop: "8px"}}>
                  <label>2. GST Type <span style={{ color: 'red' }}>*</span></label>
                  <div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="igst"
                          checked={gstType === 'igst'}
                          onChange={handleGstTypeChange}
                        />
                        IGST
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="cgst"
                          checked={gstType === 'cgst'}
                          onChange={handleGstTypeChange}
                        />
                        CGST & SGST
                      </label>
                    </div>
                  </div>


                </div>


              }
            </div>

            <div>
              <button type="button" onClick={handleAddClick}>Cancel</button>
              <button type="button" disabled={!isGstTypeSelected} onClick={() => handleSaveChanges(taxType, gstType)} style={{padding: "8px"}}>Save Changes</button>
            </div>
          </div>

        </>
      )};
    </div>

  );
};

export default Invoice_form;