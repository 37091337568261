import React, { useState } from "react";
import "./help-support.scss";
import LoanSummaryHeaderPWA from "../credits/loansummaryHeader2";
import { PinkChevron } from "../../../../assets/svg";

const CreditHelpSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const knowMoreClicked = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const faqItems = [
    {
      question:
        "    How much does it take to disburse the loan after I complete my Loan Acceptance? ",
      answer:
        "    The loan shall be disbursed in your registered bank account within 2 to 3 business days. However, disbursement shall be subject to verification and approval. You can track the status of your loan at the home screen of the application. ",
    },
    {
      question: "    How is my Loan Amount calculated? ",
      answer: (
        <>
          Your eligibility for the loan depends on various factors some of which
          are:
          <ul style={{ marginTop: "10px", marginLeft: "-30px" }}>
            - Business Occupation and Shop
          </ul>
          <ul style={{ marginLeft: "-30px" }}>- Credit history and score </ul>
          <ul style={{ marginLeft: "-30px" }}>- Past payments of the loans</ul>
          <ul style={{ marginLeft: "-30px" }}>
            {" "}
            - Sales & Earnings of from the business{" "}
          </ul>
          The loan amount offered to you suits you the best, and don’t worry,
          you can get higher loan amounts after you pay the existing loan.
        </>
      ),
    },
    {
      question: "    What is the status or details of my Loan? ",
      answer: (
        <>
          You can track the status of your loan at the home screen of the
          application.
        </>
      ),
    },
    {
      question: "    I already have a loan, how can I apply for new Loan? ",
      answer: (
        <>
          <p>
            You can avail yourself of a new loan after closing your existing
            loan, however we will also reach out to you as soon as you are
            eligible for another loan. Please note that eligibility for new
            loans depends on various factors like on time repayments,
            maintaining good credit history and score.
          </p>
          <p>
            We hope that you will pay all your instalments on time to continue
            to be eligible for new loan and other financial services from us.{" "}
          </p>
          <p>
            Feel free to contact our Customer Support on 9044052255 for any help
            or information.{" "}
          </p>
        </>
      ),
    },
    {
      question: "    How can I pay my instalment? ",
      answer: (
        <>
          <p>
            {" "}
            Your instalment will be debited from registered bank account on 5th
            of every month.{" "}
          </p>
          <p>
            However, if you wish to pay your instalment early, go to the home
            page of the application and pay at your convenience.{" "}
          </p>
          <p>
            You can also set up daily instalments from Paymint – Audio Loanbox,
            if you are using one. Contact our Customer Support on 9044052255 to
            avail the daily instalment.{" "}
          </p>
        </>
      ),
    },
    {
      question: "    How can I change the tenure and EMI of my loan? ",
      answer: (
        <>
          <p>
            {" "}
            Sorry, but you cannot change the tenure and EMI if your loan once it
            is disbursed. Contact our Customer Support on 9044052255 for any
            help.{" "}
          </p>
        </>
      ),
    },
    {
      question: "    Can I change my Bank Account? ",
      answer: (
        <>
          <p>
            {" "}
            You can change your Bank Account anytime however to do so you must
            provide the eNACH Mandate again, contact our Customer Support on
            9044052255 or email us at{" "}
            <a href="support@arthimpact.in">support@arthimpact.in</a> for help.{" "}
          </p>
        </>
      ),
    },
    {
      question: "    How can I foreclose my loan? ",
      answer: (
        <>
          <p>
            {" "}
            You can close your loan any time paying 50% of the instalments as
            per the monthly schedule. For an example, if you have availed the
            loan with 12 instalments then you can foreclose the loan after
            paying 6 installments.{" "}
          </p>

          <p>
            Write to us at{" "}
            <a href="support@arthimpact.in">support@arthimpact.in</a> with your
            Loan Account Number and will get back to you with the details.
            Alternatively, you can contact us on 9044052255.{" "}
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <LoanSummaryHeaderPWA />
      <div className="payments-help-section">
        <div className="payments-help-section-content">
          {faqItems.map((item, index) => (
            <React.Fragment key={index}>
              <div className="payments-help-section-content-questions" style={{display:'grid', gridTemplateColumns:'1fr auto'}}>
                <div style={{ textAlign: "left", fontSize: "0.95rem" }}>
                  {item.question}
                </div>
                <div
                  style={
                    openIndex === index
                      ? { textAlign: "right" }
                      : {
                          transform: "rotate(180deg)",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => knowMoreClicked(index)}
                >
                  <PinkChevron />
                </div>
              </div>
              {openIndex === index && (
                <div className="payments-help-section-content-answers" style={{fontSize:'0.85rem', lineHeight: "18px"}}>
                  {item.answer}
                </div>
              )}
              <div className="fnQ-breakLine"></div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default CreditHelpSection;
