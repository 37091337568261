import { lazy, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '../../../services/api/apiService';
import { CustomerDetailsLOS } from '../../../store/actions/customer-application/enachAction';
import { useDispatch } from 'react-redux';
import { currentAction, journeySteps } from '../../../store/actions/customer-application/offerAction';
 
const ProfileUpdateLandingPage = lazy(()=> import('../../../pages/customer-application/profile-update/ProfileUpdateLandingPage'));
const MobileUpdate = lazy(()=> import('../../../pages/customer-application/profile-update/MobileUpdate'))
const BankUpdate = lazy(()=> import('../../../pages/customer-application/profile-update/BankUpdate'))
const ProfileUpdateEnach = lazy(()=> import('../../../pages/customer-application/profile-update/ProfileUpdateEnach'))
const AlternateMobileUpdate = lazy(()=> import('../../../pages/customer-application/profile-update/AlternateMobileUpdate'))
const BusinessAddressUpdate = lazy(()=> import('../../../pages/customer-application/profile-update/BusinessAddressUpdate'))
const HomeAddressUpdate =lazy(()=> import ('../../../pages/customer-application/profile-update/HomeAddressUpdate'))
const ProfileUpdateThankYou =lazy(()=> import ('../../../pages/customer-application/profile-update/ProfileUpdateThankYou'))

const ProfileRoutes = () => {

  const apiService = new ApiService();
  const dispatch = useDispatch();
  const [activateRoutes, setActivateRoutes] = useState<boolean>(false);
  const {loanUid,userMobile,id} = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    fetchCustomerDetails();
      apiService.fetchUpdateList(loanUid,id).then((response:any)=>{
      localStorage.setItem('journeySteps', JSON.stringify(response.data.response));
      dispatch(journeySteps(response.data.response));
      dispatch(currentAction(response.data.response[0]))

      let isAuth =localStorage.getItem('Authorized')
      let loan_uid=localStorage.getItem('loanUid')
      if(!isAuth || loanUid != loan_uid){
        navigate(`/customer_update/${loanUid}/${userMobile}/${id}`);
      }
      else{
        if(response.data.response[0]){
      navigate(`/customer_update/${loanUid}/${userMobile}/${id}/${response.data.response[0]}`);
        }
        else{
      navigate(`/customer_update/${loanUid}/${userMobile}/${id}/thank_you`);
        }
      }
    })
  }, [])

  const fetchCustomerDetails = async () => {
    try{
      // const customerData = await apiService.fetchLOSCustomerData(customerID);
      // dispatch(CustomerDetailsLOS(customerData));
      setActivateRoutes(true);
    } catch(error:any) {
      setActivateRoutes(true);
      console.log('Error in fetching Data: ', error);
    }
  }

  return (
    <>
      <Routes>
        {activateRoutes && <>
        <Route path='' element={<ProfileUpdateLandingPage />} />
        <Route path="mobile" element={<MobileUpdate />} />
        <Route path="alternate_mobile" element={<AlternateMobileUpdate />} />
        <Route path="home_address" element={<HomeAddressUpdate />} />
        <Route path="business_address" element={<BusinessAddressUpdate />} />
        <Route path="bank_details" element={<BankUpdate />} />
        <Route path="setup_enach" element={< ProfileUpdateEnach/>} />
        <Route path="thank_you" element={< ProfileUpdateThankYou/>} />

        {/* <Route path='claim' element={<ClaimOfferLos />} />
        <Route path='nach' element={<EnachPage />} />
        <Route path='nach-done' element={<><ThankYou /></>} /> */}
        </>}
      </Routes>    
    </>
  )
}

export default ProfileRoutes
