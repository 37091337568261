import { useSelector } from "react-redux";
import JourneyHeader from "../../../components/customer-application/headers/JourneyHeader";
import {
  BA,
  InfoIcon,
  Insurance,
  LB,
  MIA,
  QuestionSVG,
  Tick,
} from "../../../assets/svg";
import { Tooltip } from "react-bootstrap";
import ButtonFooter from "../../../components/customer-application/button-footer/ButtonFooter";
import "./kfs-second-screen.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ApiService } from "../../../services/api/apiService";
import { LoaderService } from "../../../utils/utilities/loaderService";
import { LocalStorage } from "../../../utils/constants/localStorage";

const KFSSecondScreen = () => {
  const apiService = new ApiService();
  const loaderService = new LoaderService();
  const displayText = useSelector((state: any) => state.language.languageText);
  const loanDetails = useSelector((state: any) => state.loan.loan);
  const [businessPrimeSelected, setBusinessPrimeSelected] = useState(true);
  const [creditScore, setCreditScore] = useState(true);
  const mobile = localStorage.getItem(LocalStorage.MOBILE);
  const partnerSlug = localStorage.getItem(LocalStorage.PARTNER_SLUG);
  const [emiSecurityAmount, setEMISecurityAmount] = useState(true);
  const [isPopUPVisible1, setIsPopUPVisible1] = useState(false);
  const [isPopUPVisible2, setIsPopUPVisible2] = useState(false);
  const [isPopUPVisible3, setIsPopUPVisible3] = useState(false);
  const [isPopUPVisible4, setIsPopUPVisible4] = useState(false);
  const [isPopUPVisible5, setIsPopUPVisible5] = useState(false);
  const [addOnList, setAddOnList] = useState<any>([]);
  // const [repaymentTable, setRepaymentTable] = useState([]);
  const [businessPrimeID, setBusiessPrimeID] = useState<any>();
  const [creditScoreID, setCreditScoreID] = useState<any>();
  const [solarDevice, setSolarDevice] = useState<boolean>(false);
  const [solarDeviceAmount, setSolarDeviceAmount] = useState<any>();
  const navigate = useNavigate();
  const businessPrimeAmount = "4,999";
  const credit_Score = "5";
  const loanAmount = Math.round(
    loanDetails?.loan_amount +
      (businessPrimeSelected === true
        ? Math.round((loanDetails?.loan_amount * 10) / 100)
        : 0)
  );

  const securePaymentAmount = emiSecurityAmount ? 5000 : 0;
  const tranferToBankAccount = Math.round(
    (businessPrimeSelected === true
      ? loanDetails?.disbursal_amount_with_bp
      : loanDetails?.net_disbursal_amount) - (creditScore ? 5 : 0)
  ).toLocaleString();

  const handleCreditScore = (event) => {
    const isChecked = event.target.checked;
    setCreditScore(isChecked);
  };

  const handleEMISecurityAmount = (event) => {
    const isChecked = event.target.checked;
    setEMISecurityAmount(isChecked);
  };

  useEffect(() => {
    getAddOns();
    localStorage.setItem("BP", "True");
    localStorage.setItem("EDI", "");
  }, []);

  const handleAddClick = () => {
    if (businessPrimeSelected) {
      setIsPopUPVisible1(true);
    }
  };

  const handleDematBox = () => {
    setIsPopUPVisible2(true);
  };

  const handleBA = () => {
    setIsPopUPVisible3(true);
  };

  const handlePayment = () => {
    setIsPopUPVisible4(true);
  };

  const handleInsurance = () => {
    setIsPopUPVisible5(true);
  };

  const handlePopClose = () => {
    setIsPopUPVisible1(false);
    setBusinessPrimeSelected(false);
  };

  const handleDematPopClose = () => {
    setIsPopUPVisible2(false);
  };

  const handleBAClose = () => {
    setIsPopUPVisible3(false);
  };

  const handlePaymentClose = () => {
    setIsPopUPVisible4(false);
  };

  const handleInsuranceClose = () => {
    setIsPopUPVisible5(false);
  };

  const handlePopCloseTrue = () => {
    setIsPopUPVisible1(false);
    setBusinessPrimeSelected(true);
  };

  const handleContinueClick = () => {
    let netDisbursalAmount =
      loanDetails?.net_disbursal_amount - loanDetails?.gap_interest;
    if (businessPrimeSelected && creditScore) {
      netDisbursalAmount += parseInt(businessPrimeAmount);
      localStorage.setItem(
        "selectedIds",
        JSON.stringify([businessPrimeID, creditScoreID])
      );
      navigate("/accecpt_tnc/accept/" + partnerSlug + "/" + mobile, {
        state: {
          businessPrimeSelected: businessPrimeSelected,
          loanDetails: loanDetails,
          loanAmount: loanAmount,
          secureAmount: securePaymentAmount,
          tranferToBankAccount: tranferToBankAccount,
        },
      });
    } else if (!businessPrimeSelected && creditScore) {
      localStorage.setItem("selectedIds", JSON.stringify([creditScoreID]));
      navigate("/accecpt_tnc/accept/" + partnerSlug + "/" + mobile, {
        state: {
          businessPrimeSelected: businessPrimeSelected,
          loanDetails: loanDetails,
          loanAmount: loanAmount,
          secureAmount: securePaymentAmount,
          tranferToBankAccount: tranferToBankAccount,
        },
      });
    } else if (businessPrimeSelected && !creditScore) {
      netDisbursalAmount += parseInt(businessPrimeAmount);
      localStorage.setItem("selectedIds", JSON.stringify([businessPrimeID]));
      navigate("/accecpt_tnc/accept/" + partnerSlug + "/" + mobile, {
        state: {
          businessPrimeSelected: businessPrimeSelected,
          loanDetails: loanDetails,
          loanAmount: loanAmount,
          secureAmount: securePaymentAmount,
          tranferToBankAccount: tranferToBankAccount,
        },
      });
    } else {
      localStorage.removeItem("selectedIds");
      navigate("/accecpt_tnc/accept/" + partnerSlug + "/" + mobile, {
        state: {
          businessPrimeSelected: businessPrimeSelected,
          loanDetails: loanDetails,
          loanAmount: loanAmount,
          secureAmount: securePaymentAmount,
          tranferToBankAccount: tranferToBankAccount,
        },
      });
    }
  };

  const getAddOns = () => {
    loaderService.showLoader();
    apiService.getAddOns(loanDetails?.loan_uid).then((response: any) => {
      setAddOnList(response.data);
    });
  };

  const handleBusinessPrimeChange = () => {
    setBusinessPrimeSelected(true);
  };

  useEffect(() => {
    handleProductID();
  }, [addOnList]);

  const handleProductID = () => {
    addOnList.map((value) => {
      if (value["code"] === "ARTH-BUSINESS-PRIME") {
        setBusiessPrimeID(value["id"]);
      }
      if (value["code"] === "Credit Score Report") {
        setCreditScoreID(value["id"]);
      }
      if (value["code"] === "r2v2-solar-2") {
        setSolarDevice(true);
        setSolarDeviceAmount(value["amount"]);
      }
    });
  };
  function formatNumberToIndianStyle(num) {
    const numString = num.toString();
    const lastThreeDigits = numString.slice(-3);
    const otherDigits = numString.slice(0, -3);
    
    if (otherDigits) {
        return `${otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",")},${lastThreeDigits}`;
    }
    
    return lastThreeDigits;
}
  return (
    <>
      <JourneyHeader heading={displayText?.loan_details} />
      <div
        className={`loan-page ${isPopUPVisible1 ? "blur-background" : ""} ${
          isPopUPVisible2 ? "blur-background" : ""
        } ${isPopUPVisible3 ? "blur-background" : ""} ${
          isPopUPVisible4 ? "blur-background" : ""
        } ${isPopUPVisible5 ? "blur-background" : ""}`}
      >
        <div
          className={`loan_amount ${
            businessPrimeSelected ? "animate-color" : ""
          }`}
        >
          <div className="amount-1">
            ₹
            {formatNumberToIndianStyle(Math.round(
              loanDetails?.loan_amount +
                (businessPrimeSelected === true
                  ? Math.round((loanDetails?.loan_amount * 10) / 100)
                  : 0)
            ))}
          </div>
          <div className="text-0">{displayText?.loan_amount}</div>
        </div>
        <div className="kfs-details-2">
          {solarDevice === true && (
            <>
              <div className="tc-4">
                <div>
                  <Tick />
                  {/* <input type='checkbox' className="check-box" checked={true} /> */}
                </div>
                <div className="heading1">
                  Solar Device
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Lorem Epsum"
                  >
                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id={"tooltip-right"}>Inclusive of GST</Tooltip>
                      }
                    >
                      <InfoIcon className="question-svg" />
                    </OverlayTrigger>
                  </a>
                </div>
                <div className="solaramount">
                  ₹ {parseInt(solarDeviceAmount).toLocaleString()}
                </div>
              </div>
              <div className="break-line"></div>
            </>
          )}
          <div className="tc1">
            <div>
              <input
                type="checkbox"
                className="check-box"
                checked={businessPrimeSelected}
                onChange={handleBusinessPrimeChange}
                onClick={() => {
                  handleAddClick()
                  localStorage.setItem("BP", "True");
                }}
              />
            </div>
            <div className="heading1">
              Business Prime Benefits
              <a data-tooltip-id="my-tooltip">
                <OverlayTrigger
                  key="right"
                  placement="right"
                  overlay={
                    <Tooltip id={"tooltip-right"}>Inclusive of GST</Tooltip>
                  }
                >
                  <InfoIcon className="question-svg" />
                </OverlayTrigger>
              </a>
            </div>
            <div className="bpbamount">₹ {businessPrimeAmount}</div>
          </div>
          {/* <span className="text-down1">Also get Increased loan amount</span> */}
          <div className="tc2">
            <span>
              <Tick />
              {/* <input type='checkbox' className="check-box2" checked={checkBox1} onChange={handleCheckBoxChange(1)} /> */}
            </span>
            <span className="heading2">
              Demat Account
              <a
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Lorem Epsum"
                onClick={handleDematBox}
              >
                <InfoIcon className="question-svg" />
              </a>
            </span>
          </div>
          <div className="tc2">
            <span>
              <Tick />
              {/* <input type='checkbox' className="check-box2" checked={checkBox2} onChange={handleCheckBoxChange(2)} /> */}
            </span>
            <span className="heading2">
              Business Account
              <a data-tooltip-id="my-tooltip" onClick={handleBA}>
                <InfoIcon className="question-svg" />
              </a>
            </span>
          </div>
          <div className="tc2">
            <span>
              <Tick />
              {/* <input type='checkbox' className="check-box2" checked={checkBox3} onChange={handleCheckBoxChange(3)} /> */}
            </span>
            <span className="heading2">
              Payment Device
              <a data-tooltip-id="my-tooltip" onClick={handlePayment}>
                <InfoIcon className="question-svg" />
              </a>
            </span>
          </div>
          <div className="tc2">
            <span>
              <Tick />
              {/* <input type='checkbox' className="check-box2" checked={checkBox4} onChange={handleCheckBoxChange(4)} /> */}
            </span>
            <span className="heading2">
              Insurance 360
              <a data-tooltip-id="my-tooltip">
                <InfoIcon className="question-svg" onClick={handleInsurance} />
              </a>
            </span>
          </div>
          {/* <span className="text-down2">Deduction from loan amount</span> */}
          <div className="break-line"></div>
          {false && (
            <>
              <div className="tc3">
                <div>
                  <input
                    type="checkbox"
                    className="check-box"
                    checked={emiSecurityAmount}
                    onChange={handleEMISecurityAmount}
                  />
                </div>
                <div className="heading1">
                  Secure Repayment
                  {/* <a data-tooltip-id="my-tooltip" >
                                <InfoIcon className='question-svg' />
                            </a> */}
                </div>
                <div className="emiamount">
                  {/* {businessPrimeSelected ? 
                            <>
                            ₹ {repaymentTable[0]?.total_due}
                            </> : <>
                            ₹ {loanDetails?.installment_amount}
                            </>} */}
                  ₹ 5,000
                </div>
              </div>
              <span className="text-down3">
                <li>
                  You can secure your emi repayment incase of any unforeseen
                  circumstances.
                </li>
              </span>
              {/* <span className="text-down3">
                        <li>
                            EMI Security/Chintamukt Loan option can be availed only once.
                        </li>
                    </span>
                    <span className="text-down3">
                        <li>
                            By default, EMI Security will be adjusted in the Last EMI.
                        </li>
                    </span> */}
              {/* <span className="text-down2">Deduction from loan amount</span> */}
              <div className="break-line"></div>
            </>
          )}
          <div className="tc4">
            <div>
              <input
                type="checkbox"
                className="check-box"
                checked={creditScore}
                onChange={handleCreditScore}
              />
            </div>
            <div className="heading1">
              Credit Score Report
              <a data-tooltip-id="my-tooltip">
                <OverlayTrigger
                  key="right"
                  placement="right"
                  overlay={
                    <Tooltip id={"tooltip-right"}>Inclusive of GST</Tooltip>
                  }
                >
                  <InfoIcon className="question-svg" />
                </OverlayTrigger>
              </a>
            </div>
            <div className="csramount">₹ {credit_Score}</div>
          </div>
        </div>
        <div className="tc5">
          <div className="heading1">{displayText?.transfer_to_bank}</div>
          <div style={{ fontWeight: "600", textAlign: "right" }}>
            {/* ₹{Math.round(loanDetails?.net_disbursal_amount - loanDetails?.gap_interest + (businessPrimeSelected ? 4999 : 0) - (creditScore ? 5 : 0) - (emiSecurityAmount ? 5000 : 0)).toLocaleString() || 0} */}
            ₹{" "}
            {Math.round(
              (businessPrimeSelected === true
                ? loanDetails?.disbursal_amount_with_bp
                : loanDetails?.net_disbursal_amount) - (creditScore ? 5 : 0)
            ).toLocaleString()}
          </div>
        </div>
        <div className="loan-details-button-kfs" onClick={handleContinueClick}>
          <ButtonFooter buttonContent={displayText?.next} />
        </div>
      </div>
      {isPopUPVisible1 && (
        <>
          <div className="bpbContainer">
            <div className="insideContainer">
              <div className="heading3">By Opting Busines Prime</div>
              <div className="text-down4">
                {/* Also get Increased loan amount */}
              </div>
              <div className="tc6">
                <div className="heading4">Business Prime Benefits</div>
                <div className="heading5">Total ₹ 4,999</div>
              </div>
              <div className="tc7">
                <div>
                  <MIA />
                </div>
                <div className="heading7">Demat Account</div>
              </div>
              <div className="tc7">
                <div>
                  <BA />
                </div>
                <div className="heading7">Business Account</div>
              </div>
              <div className="tc7">
                <div>
                  <LB />
                </div>
                <div className="heading7">Payment Device</div>
              </div>
              <span className="text-down5">
                One Month subscription charges ₹590 included
              </span>
              <div className="tc7">
                <div>
                  <Insurance />
                </div>
                <div className="heading7">Insurance 360</div>
              </div>
              <br></br>
              <span className="text-down6">
                {/* Deduction from loan amount */}
              </span>
              <div className="container-button" onClick={handlePopCloseTrue}>
                <ButtonFooter buttonContent={displayText?.continue} />
              </div>
              <div
                className="text-down7"
                onClick={() => {
                  handlePopClose();
                  localStorage.setItem("BP", "False");
                }}
              >
                No, I don't want Business Prime Benefits
              </div>
            </div>
          </div>
        </>
      )}
      {isPopUPVisible2 && (
        <>
          <div className="dematContainer">
            <div className="tc8">
              <div>
                <MIA />
              </div>
              <div className="heading8">Demat Account</div>
            </div>
            <span className="text-down9 tc9">
              Open Demat Account and start your Investing journey qucikly
            </span>
            <span className="text-down10 tc9">
              <li>Open Demat Account</li>
            </span>
            <span className="text-down10 tc9">
              <li>Option to Invest in Mutual Funds</li>
            </span>
            <button className="close-button" onClick={handleDematPopClose}>
              Close
            </button>
          </div>
        </>
      )}
      {isPopUPVisible3 && (
        <>
          <div className="baContainer">
            <div className="tc9">
              <div>
                <BA />
              </div>
              <div className="heading9">Business Account</div>
            </div>
            <span className="text-down10 tc10">
              Open Business Account to facilitate transaction and get a chance
              to earn cashback
            </span>
            <span className="text-down11 tc10">
              <li>Open Business Bank Account for Free</li>
            </span>
            <span className="text-down11 tc10">
              <li>Get NFC enabled Expense Card with zero charge</li>
            </span>
            <span className="text-down11 tc10">
              <li>Enjoy Cashback on transactions</li>
            </span>
            <button className="close-button" onClick={handleBAClose}>
              Close
            </button>
          </div>
        </>
      )}
      {isPopUPVisible4 && (
        <>
          <div className="paymentContainer">
            <div className="insideContainer">
              <div className="tc9">
                <div>
                  <LB />
                </div>
                <div className="heading9">Payment Device</div>
              </div>
              <span className="text-down12">
                One Month subscription charge ₹590 Included
              </span>
              <span className="text-down10 tc10">
                Payments Device facilities UPI Payments Acceptance and
                transaction tracking with monthly subscription of ₹590
              </span>
              <span className="text-down11 tc10">
                <li>UPI Payment Acceptance</li>
              </span>
              <span className="text-down11 tc10">
                <li>Easy apply for Loan from Payment Device</li>
              </span>
              <span className="text-down11 tc10">
                <li>Real-time Settlement</li>
              </span>
              <button className="close-button" onClick={handlePaymentClose}>
                Close
              </button>
            </div>
          </div>
        </>
      )}
      {isPopUPVisible5 && (
        <>
          <div className="insuranceContainer">
            <div className="tc9">
              <div>
                <Insurance />
              </div>
              <div className="heading9">Insurance 360</div>
            </div>
            <span className="text-down10 tc10">
              Holistic Insurance offering to cover you in case of any unforeseen
              circumstances
            </span>
            <span className="text-down11 tc10">
              <li>Shop Insurance</li>
            </span>
            <span className="text-down11 tc10">
              <li>Health Protection</li>
            </span>
            <span className="text-down11 tc10">
              <li>EMI Protection</li>
            </span>
            <span className="text-down11 tc10">
              <li>Hospicash</li>
            </span>
            <button className="close-button" onClick={handleInsuranceClose}>
              Close
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default KFSSecondScreen;
