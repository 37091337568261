import {useEffect, useState} from 'react';
import './text-input.scss';
import ActionButton from '../button/ActionButton';
import { GreenCheck2 } from '../../../assets/svg';

const TextInput = (props:any) => {

  const {
    heading,
    isCompulsoryField,
    preInputText, 
    placeholder, 
    value, 
    handleInput,
    handleActionButton,
    actionButtonStatus,
    identifier, 
    supportiveTextRed, 
    supportiveTextGreen,
    showActionButton=false,
    actionButtonContent,
    actionSuccess,
    disabled=false,
    maxLength,
    inputMode='text',
    type='text',
    reKycRemarks
  } = props;

  const [userInput, setUserInput] = useState('');

  const emmitValue = (e:any) => {
    setUserInput(e.target.value);
    handleInput(identifier, e.target.value);
  }

  const actionButtonClicked = () => {
    if(actionButtonStatus){
      handleActionButton();
    }
  }
  // useEffect(() => {
  //   console.log(actionSuccess, 'action success');
  // }, [])

  return (
    <div className='partner-text-input'>
      <div className='partner-heading'>{heading}<span>&nbsp;{isCompulsoryField && '*'}</span></div>
      <div className="input-group">
        {preInputText && <span className="input-group-text" id="basic-addon1">{preInputText}</span>}
        <input 
          type={type} 
          className={`form-control ${supportiveTextRed && 'red-border'}`}
          placeholder={placeholder} 
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={value}
          disabled={disabled}
          onChange={(e) => emmitValue(e)}
          maxLength={maxLength}
          inputMode={inputMode}
        />
        { (showActionButton || actionSuccess) && <div onClick={actionButtonClicked} className='action-button-style'>
          {actionSuccess ? <><GreenCheck2/>Verified</> : <ActionButton isActive={actionButtonStatus}  buttonContent={actionButtonContent}/>}
          </div>}
      </div>
      <div className='partner-supportive-text-red'>{supportiveTextRed}</div>
      <div className='partner-supportive-text-green'>{supportiveTextGreen}</div>
      {reKycRemarks?.map((remark:string, index:number) => {
        return (
          <div key={index} className='partner-supportive-text-red'>{remark}</div>
        )
      })}
    </div>
  )
}

export default TextInput;
