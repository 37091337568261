import { useEffect, useRef, useState } from "react";
import "./loan-screen.scss";
import {
  BackButton,
  DisabledCircle,
  GreenCheck,
  PinkChevron,
  RightArrow,
} from "../../../../assets/svg";
import { ApiService } from "../../../../services/api/apiService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LocalStorage } from "../../../../utils/constants/localStorage";
import InstallmentBar from "../../loan-summary/InstallmentBar";
import LoanSummaryHeaderPWA from "./loansummaryHeader2";
import { useSwipeable } from "react-swipeable";
import { Mixpanel } from "../../../../routing/MixPannelWrapper";
import EDIInstallmentBar from "../../loan-summary/EdiIindtallmentBar";

const LoanScreen = () => {
  const apiService = new ApiService();
  const [loanDetail, setLoanDetail] = useState<any>([]);
  const [previousLoanData, setPreviousLoanData] = useState();
  const [repaymentSchedule, setRepaymentSchedule] = useState();
  const [paidEmi, setPaidEmi] = useState<any>([]);
  const [dueEmi, setDueEmi] = useState<any>([]);
  const [upcomingEmi, setUpcomingEmi] = useState<any>([]);
  const [loanDocument, setLoanDocument] = useState<any>({});
  const [totalInstallment, setTotalInstallment] = useState(0);
  const [paidInstallment, setPAidInstallment] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [conversionFees, setConversionFees] = useState(0);
  const [ntlPaidAmount, setNTLPaidAmount] = useState(0);
  const [expandRepay, setExpandRepay] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [foreClosureAmount, setForeClosureAmount] = useState();
  const [foreClosure, setForeClosure] = useState<boolean>(false);
  const [documents, setDocuments] = useState([]);
  const [businessPrimeDocument, setBusinessPrimeDocument] = useState();
  const [insuranceDocument, setInsuranceDocument] = useState();
  const [startY, setStartY] = useState(null);
  const [startHeight, setStartHeight] = useState(100);
  const [isResizing, setIsResizing] = useState(false);
  const { loan_uid, paid_installment, name, mobile, loan_category } =
    location.state || {};

  const handleMouseDown = (e) => {
    setStartY(e.clientY);
    setStartHeight(e.currentTarget.parentElement.clientHeight);
    setIsResizing(true);
  };

  const handleMouseMove = (e) => {
    if (!isResizing) return;

    const newHeight = startHeight - (e.clientY - startY);
    if (newHeight > 0) {
      e.currentTarget.parentElement.style.height = `${newHeight}px`;
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const expandRepayment = () => {
    setExpandRepay(!expandRepay);
  };

  const handlers = useSwipeable({
    onSwipedUp: () => {
      setTimeout(expandRepayment, 500);
    },

    onSwipedDown: () => {
      setTimeout(expandRepayment, 500);
    },
  });

  const openLoanDocument = (key) => {
    const customer_ucic = loanDetail?.customer_code;
    const loanID = loan_uid;
    Mixpanel.identify(
      mobile +
        "-" +
        customer_ucic +
        "-" +
        loanID +
        "-" +
        localStorage.getItem("Device")
    );
    Mixpanel.track("Loan Summary Section-Loan Documents", "");
    Mixpanel.people.set({
      $first_name: name,
    });
    window.open(loanDocument[key], "_blank");
  };

  const fetchPaymentLink = (amount) => {
    const payload = {
      loan_uid: loan_uid,
      amount: Number(amount),
      customer_code: loanDetail?.customer_code,
      partner_code: (loanDetail?.partner_code).toUpperCase(),
    };
    const customer_ucic = loanDetail?.customer_code;
    const loanID = loan_uid;

    if (
      (sessionStorage.getItem("utm_source") &&
        sessionStorage.getItem("utm_medium") &&
        sessionStorage.getItem("utm_campaign") === "pre-due-stage") !== null
    ) {
      Mixpanel.identify(
        mobile +
          "-" +
          customer_ucic +
          "-" +
          loanID +
          "-" +
          sessionStorage.getItem("utm_source") +
          "-" +
          sessionStorage.getItem("utm_medium") +
          "-" +
          sessionStorage.getItem("utm_campaign") +
          "-" +
          localStorage.getItem("Device")
      );
      Mixpanel.track("Pre-Due-Repayment CTA", "");
      Mixpanel.people.set({
        $first_name: name,
      });
    } else {
      Mixpanel.identify(
        mobile +
          "-" +
          customer_ucic +
          "-" +
          loanID +
          "-" +
          localStorage.getItem("Device")
      );
      Mixpanel.track("Pre-Due-Repayment CTA", "");
      Mixpanel.people.set({
        $first_name: name,
      });
    }

    apiService.fetchPaymentLink(payload).then((res: any) => {
      window.open(res.data?.short_url, "_blank");
    });
  };

  useEffect(() => {
    apiService.fetchAllLoanDetails(loan_uid).then((res) => {
      setLoanDetail(res?.data?.loan);
      sessionStorage.setItem("customer_code", res?.data?.loan?.customer_code);
      setPreviousLoanData(res?.data?.previous_loan);
      setRepaymentSchedule(res?.data?.repayments);
      res.data?.loan_card?.map((item) => {
        setTotalInstallment((prev) => prev + 1);
        if (item.status === "paid") {
          setPaidEmi((prev) => [...prev, item]);
          setPAidInstallment((prev) => prev + 1);
          setPaidAmount(
            (prev) =>
              prev +
              parseFloat(item.interest_repaid) +
              parseFloat(item.principal_repaid) +
              parseFloat(item.penalty_repaid)
          );
          setNTLPaidAmount((prev) => prev + parseInt(item.total_due));
          setConversionFees(
            (prev) => prev + parseInt(item.conversion_fee_repaid)
          );
        } else {
          setUpcomingEmi((prev) => [...prev, item]);
          setDueAmount((prev) => prev + parseFloat(item.total_due));
        }
      });
    });
    apiService.fetchLoanDocuments(loan_uid).then((res) => {
      setLoanDocument(res.data);
    });
    checkForeClosureValidity();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("customer_code") !== null) {
      businessPrimeDisplay();
    }
  },[]);

  const businessPrimeDisplay = () => {
    apiService
      .getTnCDocuments(sessionStorage.getItem("customer_code"))
      .then((response) => {
        if (response?.data?.result) {
          setDocuments(response?.data?.result);
        }
      });
  };

  useEffect(() => {
    businessPrimeOpted();
  }, [documents]);

  const checkForeClosureValidity = () => {
    const payload = {
      loan_uid: loan_uid,
    };

    apiService.foreclosureValidity(payload).then((response) => {
      setForeClosureAmount(response?.data?.message);
      if (response?.data?.validity === true) {
        setForeClosure(true);
      }
    });
  };

  const handleDateFormat = () => {
    const convertedDate = new Date();
    const day = String(convertedDate.getDate()).padStart(2, "0");
    const month = String(convertedDate.getMonth() + 1).padStart(2, "0");
    const year = convertedDate.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const businessPrimeOpted = () => {
    if (documents) {
      documents.map((value) => {
        if (value["document_type"] === "business_prime") {
          setBusinessPrimeDocument(value["pdf_s3_url"]);
        }
        if (value["document_type"] === "insurance") {
          setInsuranceDocument(value["pdf_s3_url"]);
        }
      });
    }
  };

  return (
    <>
      <div style={{ overflow: "scroll !important" }}>
        <LoanSummaryHeaderPWA />
        <div className="container_pwa">
          <div className="loan_summary">
            <div className="loan_amount p-3">
              <label className="text">Loan Amount</label>
              <span className="value">
                ₹ {parseFloat(loanDetail?.amount_requested).toLocaleString()}
              </span>
            </div>
            <div className="installment p-3 mt-3">
              <label className="text">Installment Amount</label>
              <span className="mx-2 value">
                {paid_installment || 0} / {loanDetail?.number_of_installments}
              </span>
              <div className="d-flex justify-content-between">
                <div>
                  <span className="text">Paid</span>
                  <span className="mx-2 value">
                    ₹ {parseInt(loanDetail?.amount_paid).toLocaleString()}
                  </span>
                </div>
                <div>
                  <span className="text">Due</span>
                  <span className="mx-2 value">
                    ₹ {parseInt(loanDetail?.amount_due).toLocaleString()}
                  </span>
                </div>
              </div>
              <InstallmentBar due={dueAmount} paid={paidAmount} />
            </div>
            <div className="details-1 mt-4">
              <br></br>
              <label className="heading">Loan Details</label>
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <label className="text">Tenure</label>
                  <br></br>
                  <span className="value">{loanDetail?.tenure}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <label className="text">Installment Amount</label>
                  <br></br>
                  <span className="value">
                    ₹{" "}
                    {parseInt(loanDetail?.installment_amount).toLocaleString()}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <label className="text">Number Of Installment</label>
                  <br></br>
                  <span className="value">
                    {loanDetail?.number_of_installments}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <label className="text">Repayment Frequency</label>
                  <span className="value">
                    {loanDetail?.repayment_frequency}
                  </span>
                </div>
              </div>
              {loan_category === "NTL-converted" ? (
                <>
                  <div
                    className="text-center view-more"
                    style={{ borderRadius: "10px" }}
                    onClick={() =>
                      navigate(`/ntlLoanScreen`, {
                        state: {
                          loanDetails: loanDetail,
                          loan_uid: loan_uid,
                          previousLoanData: previousLoanData,
                          repaymentSchedule: repaymentSchedule,
                          conversionFees: conversionFees,
                          businessPrimeDocument: businessPrimeDocument,
                        },
                      })
                    }
                  >
                    View More
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="text-center view-more"
                    style={{ borderRadius: "10px" }}
                    onClick={() => {
                      const customer_ucic = loanDetail?.customer_code;
                      const loanID = loan_uid;
                      Mixpanel.identify(
                        mobile +
                          "-" +
                          customer_ucic +
                          "-" +
                          loanID +
                          "-" +
                          localStorage.getItem("Device")
                      );
                      Mixpanel.track("Loan Summary Section-Loan Details", "");
                      Mixpanel.people.set({
                        $first_name: name,
                      });
                      navigate(`/loandetailscreen`, {
                        state: {
                          loanDetails: loanDetail,
                          loan_uid: loan_uid,
                          businessPrimeDocument: businessPrimeDocument,
                        },
                      });
                    }}
                  >
                    View More
                  </div>
                </>
              )}
            </div>

            <div className="details-1 mt-4 document">
              <br></br>
              <label className="heading">Loan Documents</label>
              <div className="p-3" style={{ overflow: "scroll" }}>
                <div
                  className="value1"
                  onClick={() => openLoanDocument("soa_url")}
                >
                  Statement Of Account(SOA)
                  <RightArrow style={{ float: "right" }} />
                </div>
                <div
                  className="value1 pt-3"
                  onClick={() => openLoanDocument("loan_card_url")}
                >
                  Loan Card
                  <RightArrow style={{ float: "right" }} />
                </div>
                <div
                  className="value1 pt-3"
                  onClick={() => openLoanDocument("loan_tnc_url")}
                >
                  Terms & Conditions
                  <RightArrow style={{ float: "right" }} />
                </div>
                <div
                  className="value1 pt-3"
                  onClick={() => openLoanDocument("loan_agreement_url")}
                >
                  Loan Agreement
                  <RightArrow style={{ float: "right" }} />
                </div>
                {businessPrimeDocument && (
                  <div
                    className="value1 pt-3"
                    onClick={() => window.open(businessPrimeDocument, "_blank")}
                  >
                    Business Prime
                    <RightArrow style={{ float: "right" }} />
                  </div>
                )}
                {insuranceDocument && (
                  <div
                    className="value1 pt-3"
                    onClick={() => window.open(insuranceDocument, "_blank")}
                  >
                    Insurance
                    <RightArrow style={{ float: "right" }} />
                  </div>
                )}
              </div>
            </div>
            <br></br>
          </div>
          <div
            className={`draggable expand_summary ${expandRepay && "expanded"}`}
          >
            <div className="expand_suvmmary">
              <div>
                <div className="mx-3 mt-4">
                  <div
                    className="d-flex justify-content-center"
                    {...handlers}
                    onClick={() => {
                      const customer_ucic = loanDetail?.customer_code;
                      const loanID = loan_uid;
                      Mixpanel.identify(
                        mobile +
                          "-" +
                          customer_ucic +
                          "-" +
                          loanID +
                          "-" +
                          localStorage.getItem("Device")
                      );
                      Mixpanel.track("Loan Summary Section-Repayment", "");
                      Mixpanel.people.set({
                        $first_name: name,
                      });
                    }}
                  >
                    <PinkChevron />
                  </div>
                  <div style={{ fontSize: "1.1rem", marginLeft: "25%" }}>
                    Repayment Details
                  </div>
                  <br></br>
                </div>
                {expandRepay && (
                  <>
                    {loanDetail?.status !== "fully_repaid" &&
                      foreClosure === true && (
                        <>
                          <div>
                            <div
                              className="section-heading  mx-3 mt-2"
                              style={{ marginBottom: "10px" }}
                            >
                              Foreclose Loan
                              <div></div>
                              <br></br>
                              <div className="d-flex justify-content-between ps-3  align-items-center">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  // style={{ accentColor: "#ee3e80 !important" }}
                                />
                                <div>
                                  <div className="text-heading">
                                    {parseInt(
                                      foreClosureAmount
                                    ).toLocaleString()}
                                  </div>                                 
                                </div>                               
                                <div style={{ marginRight: "-10%" }}>
                                  <button
                                    className="pay-button"
                                    onClick={() =>
                                      fetchPaymentLink(foreClosureAmount)
                                    }
                                  >
                                    Pay
                                  </button>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                    {paidEmi && paidEmi.length > 0 && (
                      <div className="section-heading mx-3 mt-2">Paid EMI</div>
                    )}
                    {paidEmi?.map((item, index) => {
                      return (
                        <div className="section-heading mx-3 mt-2">
                          <div
                            key={index}
                            className="d-flex justify-content-between mt-3 px-4"
                          >
                            <div style={{ marginTop: "3%", flex: "none" }}>
                              <GreenCheck />
                            </div>
                            <div
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <div className="text-heading">
                                {loanDetail?.is_ntl_loan === true
                                  ? ntlPaidAmount.toLocaleString()
                                  : (
                                      parseInt(item.principal_repaid) +
                                      parseInt(item.penalty_repaid) +
                                      parseInt(item.interest_repaid)
                                    ).toLocaleString()}
                              </div>
                              <div className="sub-text">Paid</div>
                            </div>
                            <div
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <div className="text-heading">
                                {item.paid_date}
                              </div>
                              <div className="sub-text">Paid Date</div>
                            </div>
                            <br></br>
                          </div>
                        </div>
                      );
                    })}
                     {upcomingEmi && upcomingEmi.length > 0 && (
                      <div className="section-heading  mx-3 mt-2">
                        Upcoming EMI
                        <br></br>
                        <div className="d-flex justify-content-between ps-3  align-items-center">
                          <input type="checkbox" className="checkbox" />
                          <div>
                            <div className="text-heading">
                              {(
                                Math.round(parseInt(upcomingEmi[0]?.total_due) - parseInt(upcomingEmi[0]?.hold_amount))
                              ).toLocaleString()}
                            </div>
                            <div className="sub-text">Due Emi</div>
                          </div>
                          <div>
                            <div className="text-heading">
                              {upcomingEmi[0]?.due_date}
                            </div>
                            <div className="sub-text">Due Date</div>
                          </div>
                          <div>
                            <button
                              className="pay-button"
                              onClick={() =>
                                fetchPaymentLink(Math.round(parseInt(upcomingEmi[0]?.total_due) - (parseInt(upcomingEmi[0]?.hold_amount)) || 0))
                              }
                            >
                              Pay
                            </button>
                          </div>
                        </div>
                        {parseInt(upcomingEmi[0]?.hold_amount) !== 0 && (
                          <>
                            <div style={{ marginTop: "10px" }}>
                              <EDIInstallmentBar due={Math.round(upcomingEmi[0]?.total_due - upcomingEmi[0]?.hold_amount)} paid={upcomingEmi[0]?.hold_amount} />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {expandRepay && (
                      <>
                        <br></br>
                        {upcomingEmi?.map((item, index) => {
                          return (
                            index !== 0 && (
                              <div
                                key={index}
                                className="d-flex justify-content-between mt-3 px-4"
                              >
                                <div>
                                  <DisabledCircle />
                                </div>
                                <div>
                                  <div className="text-heading">
                                    {parseInt(item.total_due).toLocaleString()}
                                  </div>
                                  <div className="sub-text">Upcoming Emi</div>
                                </div>
                                <div>
                                  <div className="text-heading">
                                    {item.due_date}
                                  </div>
                                  <div className="sub-text">Due Date</div>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanScreen;