import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HuntingService } from '../../../services/api/huntingService';
import { ArthLogo } from '../../../assets/svg';
import { Toaster } from '../../../utils/utilities/Toaster';
import TextInput from '../../../components/partner-application/text-input/TextInput';
import Pagination from '@mui/material/Pagination';
import Dropdown from '../../../components/partner-application/dropdown/Dropdown';
import { LoaderService } from '../../../utils/utilities/loaderService';

const HuntingData = () => {

  const [huntingData, setHuntingData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const navigate = useNavigate();
  const huntingService = new HuntingService();
  const toaster = new Toaster();
  const id = localStorage.getItem('hunterId');
  const role = localStorage.getItem('hunterRole');
  const [allotmentDate, setAllotmentDate] = useState<any>('');
  const [selectedFilter, setSelectedFilter] = useState<string>('None');
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(1);
  const loader = new LoaderService();

  useEffect(() => {
    if(!localStorage.getItem('hunterId') || !localStorage.getItem('token')){
      localStorage.clear();
      navigate('/hunt/login');
    } else {
      fetchHuntedDataList(pageNo);
    }
  }, []);

  const fetchHuntedDataList = (number:number) => {
    loader.showLoader();
    huntingService.getHuntedDataList(id,'', number).then((response:any) => {
      setHuntingData(response?.data?.results);
      setFilteredData(response?.data?.results);
      setTotalRecords(response?.data?.count);
      loader.hideLoader();
    }).catch((error:any) => {
      if(error?.response?.data?.detail === 'Invalid token'){
        localStorage.clear();
        loader.hideLoader();
        navigate('/hunt/login');
        toaster.show(true, 'You Are Logged Out, Please Login To Continue');
      } else {
        toaster.show(true, 'Unable To Fetch Hunted Data');
        loader.hideLoader();
      }
    })
  }

  const handleCreateButtonClick = () => {
    navigate('/hunt/create');
  }

  const handleDetailsView = (formId:any) => {
    navigate(`/hunt/details/${formId}`);
  }

  const handleAllotment = () => {
    const payload = {
      "id": id,
      "assignment_date": allotmentDate,
    }
    toaster.confirmation('Approve').then((result) => {
      if(result.isConfirmed){
        huntingService.updateWhiteListing(payload).then((response:any) => {
          toaster.show(false, 'Succefully Alloted');
        }).catch((error:any) => {
          toaster.show(true, 'Unable To Trigger Allotment');
        })
      } else {
        toaster.show(true, 'Cancelled');
      }
    })
  }

  const logoutUser = () => {
    localStorage.clear();
    navigate('/hunt/login');
  }

  const handleFilterChange = (key:string, value:string) => {
    setSelectedFilter(value);
    filterData(value);
  }

  const filterMapping = {
    "Small Ticket Loan": "small_business_loan",
    "High Ticket Loan": "high_value_business_loan",
    "Affordable Housing Loan": "affordable_housing_loan",
    "Insurance": "insurance",
    "Investment (12% Return)": "micro_investment",
    "Account Opening (Tide)": "account_opening",
    "Arth P@ymint": "arth_payment",
    "Not Interested": "not_interested",
  }

  const filterData = (filter:string) => {
    if(filter === 'None'){
      setFilteredData(huntingData);
    } else {
      let arr = huntingData.filter((obj:any) => (obj[`${filterMapping[filter]}`]))
      setFilteredData(arr);
    }
  }

  const handlePaginationChange = (event: any, value: number) => {
    setPageNo(value);
    fetchHuntedDataList(value);
  }

  return (
    <div className='p-4' style={{backgroundColor: "#F9FAFB"}}>
      <div className="d-flex justify-content-between">
        <div style={{color: '#EE3E80'}}><ArthLogo /> Empower</div>
        {role === 'QC' && <>
        <div>
          <TextInput
            placeholder={'Allotment Date'}
            type={'date'}
            value={allotmentDate}
            handleInput={(k:any, v:any) => {setAllotmentDate(v)}}
          />
        </div>
        <div>
          <Button onClick={handleAllotment}>
            Trigger Allotment
          </Button>
        </div>
        <div>
          <Dropdown
            value={selectedFilter}
            list={['High Ticket Loan', 'Small Ticket Loan', 'Affordable Housing Loan', 'Arth P@ymint', 'Account Opening (Tide)', 'Investment (12% Return)', 'Insurance', 'Not Interested', 'None']}
            handleInput={handleFilterChange}
            identifier={'filter_value'}
          />
        </div>
        </>}
        {role !== 'QC' && <div>
          <Button onClick={handleCreateButtonClick}>
            + Create
          </Button>
        </div>}
        <div>
          <Button onClick={logoutUser}>Logout</Button>
        </div>
      </div>
      {filteredData?.map((data:any, index:number) => {
        return (
          <div className='card mt-2' key={index} onClick={() => handleDetailsView(data?.id)}>
            <div className="card-body p-2">
              <div className="listing-wrapper d-flex justify-content-between p-2">
                <div>{data?.shop_owner_name}</div>
                <div>{data?.shop_owner_mobile_number}</div>
                <a href={`https://www.google.com/maps/search/?api=1&query=${data?.latitude},${data?.longitude}`} target="_blank" rel="noopener noreferrer">Open in Map</a>
              </div>
              <div className='p-2'>
                Address: {data?.shop_address} {data?.district}
              </div>
            </div>
          </div>
        )
      })}
      <div className='mt-4 d-flex justify-content-center'>
        <Pagination count={Math.ceil(totalRecords / 5)} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
      </div>
    </div>
  )
}

export default HuntingData;
