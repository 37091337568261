import { LocalStorage } from "../utils/constants/localStorage";
import axios from "axios";

type Header = () => {
  "Content-Type": string;
  "x-auth-token"?: any;
};

export const headers: Header = () => {
  if (localStorage.getItem(LocalStorage.LOGGED_IN) === "true") {
    const auth_key: string = localStorage.getItem(LocalStorage.AUTH_KEY) || "";
    const auth: any = JSON.parse(auth_key);
    return {
      "Content-Type": "application/json",
      "x-auth-token": auth.token,
    };
  }
  return {
    "Content-Type": "application/json",
  };
};

type PWAHeaders = () => {
  "Content-Type": string;
  "x-auth-token"?: any;
};

export const pwaHeaders: PWAHeaders = () => {
  const auth_Key: string = localStorage.getItem("PWA-token");
  const auth: any = auth_Key;
  return {
    "Content-Type": "application/json",
    "x-auth-token": auth,
  };
};

type SupportHeaders = () => {
  "Content-Type": string;
  "x-auth-token"?: any;
};

export const supportHeaders: SupportHeaders = () => {
  const auth_Key: string = localStorage.getItem("PWA-token");
  const auth: any = auth_Key;
  return {
    "Content-Type": "multipart/form-data",
    "x-auth-token": auth,
  };
};

export const formDataHeaders: Header = () => {
  if (localStorage.getItem(LocalStorage.LOGGED_IN) === "true") {
    const auth_key: string = localStorage.getItem(LocalStorage.AUTH_KEY) || "";
    const auth: any = JSON.parse(auth_key);
    return {
      "Content-Type": "multipart/form-data",
      "x-auth-token": auth.token,
    };
  }
  return {
    "Content-Type": "multipart/form-data",
  };
};

export const ArthAppHeaders = () => {
  return {
    Accept: "application/json",
    role: "ADMIN",
  };
};

export const axiosInstance = axios.create({
  timeout: 1000, // Default timeout: 5000 milliseconds (5 seconds)
});

export const partnerHeader = (contentType = "application/json") => {
  return {
    "Content-Type": contentType,
    Authorization: `Bearer ${localStorage.getItem("partnerAccessToken")}`,
    role: localStorage.getItem("currentRole"),
  };
};
export const partnerHeaderFromdata = (contentType = "multipart/form-data") => {
  return {
    "Content-Type": contentType,
    Authorization: `Bearer ${localStorage.getItem("partnerAccessToken")}`,
    role: localStorage.getItem("currentRole"),
  };
};

export const huntingHeader = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};
