import "./details-box.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

function CustomerDemographics({
  demographicsData,
  equifax_match,
  heading,
  bigBox = false,
}) {
  let nf = new Intl.NumberFormat();
  let name = <>{demographicsData?.name}</>;
  if (equifax_match?.name === "No Match") {
    name = (
      <>
        {demographicsData?.name}

        <CancelIcon
          sx={{
            color: "#DC3545",
            height: "15px",
            width: "15px",
            marginLeft: "3px",
          }}
          fontSize="small"
        />
      </>
    );
  } else if (equifax_match?.name === "Match") {
    name = (
      <>
        {demographicsData?.name}
        <CheckCircleOutlineIcon
          color="success"
          sx={{ height: "15px", width: "15px", marginLeft: "3px" }}
          fontSize="small"
        />
      </>
    );
  }

  let age = <>{demographicsData?.age}</>;
  if (equifax_match?.age === "No Match") {
    age = (
      <>
        {demographicsData?.age}
        <CancelIcon
          sx={{
            color: "#DC3545",
            height: "15px",
            width: "15px",
            marginLeft: "3px",
          }}
          fontSize="small"
        />
      </>
    );
  } else if (equifax_match?.age === "Match") {
    age = (
      <>
        {demographicsData?.age}
        <CheckCircleOutlineIcon
          color="success"
          sx={{ height: "15px", width: "15px", marginLeft: "3px" }}
          fontSize="small"
        />
      </>
    );
  }

  let mobile = <>{demographicsData?.mobile_no}</>;
  if (equifax_match?.mobile === "No Match") {
    mobile = (
      <>
        {demographicsData?.mobile_no}
        <CancelIcon
          sx={{
            color: "#DC3545",
            height: "15px",
            width: "15px",
            marginLeft: "3px",
          }}
          fontSize="small"
        />
      </>
    );
  } else if (equifax_match?.mobile === "Match") {
    mobile = (
      <>
        {demographicsData?.mobile_no}
        <CheckCircleOutlineIcon
          color="success"
          sx={{ height: "15px", width: "15px", marginLeft: "3px" }}
          fontSize="small"
        />
      </>
    );
  }

  let address = <>{demographicsData?.home_address}</>;
  if (equifax_match?.address === "No Match") {
    address = (
      <>
        {demographicsData?.home_address}
        <CancelIcon
          sx={{
            color: "#DC3545",
            height: "15px",
            width: "15px",
            marginLeft: "3px",
          }}
          fontSize="small"
        />
      </>
    );
  } else if (equifax_match?.address === "Match") {
    address = (
      <>
        {demographicsData?.home_address}
        <CheckCircleOutlineIcon
          color="success"
          sx={{ height: "15px", width: "15px", marginLeft: "3px" }}
          fontSize="small"
        />
      </>
    );
  }

  let email = <>{demographicsData?.email_id}</>;
  if (equifax_match?.email === "No Match") {
    email = (
      <>
        {demographicsData?.email_id}
        <CancelIcon
          sx={{
            color: "#DC3545",
            height: "15px",
            width: "15px",
            marginLeft: "3px",
          }}
          fontSize="small"
        />
      </>
    );
  } else if (equifax_match?.email === "Match") {
    email = (
      <>
        {demographicsData?.email_id}
        <CheckCircleOutlineIcon
          color="success"
          sx={{ height: "15px", width: "15px", marginLeft: "3px" }}
          fontSize="small"
        />
      </>
    );
  }

  const demographicOne = {
    Name: name,
    Age: age,
    Gender: demographicsData?.gender,
  };

  const demographicTwo = {
    "Mobile Number": mobile,
    "Email ID": email,
  };

  const demographicThree = {
    "Marital Status": demographicsData?.marital_status,
    "No. of children": demographicsData?.no_of_children,
    "No Of Dependent": demographicsData?.no_of_dependents,
    Education: demographicsData?.education,
  };

  const demographicFour = {
    "Residence Type": demographicsData?.residence_type,
    "Home Address": address,
  };

  const demographicFive = {
    "Sole Earner": demographicsData?.sole_earner,
    "Monthly Family Income": nf.format(demographicsData?.monthly_family_income),
    "Annual Family Income": nf.format(
      demographicsData?.monthly_family_income * 12
    ),
  };
  return (
    <div className={`demographics-container ${bigBox && "big-box"}`}>
      <div class="row">
        <div class="col-md hdr-style">{heading}</div>
      </div>
      <div>
        {Object.keys(demographicOne).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicOne[key]}</div>
          </div>
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(demographicTwo).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicTwo[key]}</div>
          </div>
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(demographicThree).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicThree[key]}</div>
          </div>
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(demographicFour).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicFour[key]}</div>
          </div>
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(demographicFive).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicFive[key]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomerDemographics;
