const initialUserData = {};

export const UserAuth = (state = initialUserData, action) => {
  switch(action.type){
    case 'PARTNER_LOGIN_DETAIL':
      return{
        ...state,
        data: action.payload
      }
    default: 
      return state
  }
}

export const currentRole = (state= {}, action) => {
  switch(action.type){
    case 'CURRENT_USER_ROLE':
      return{
        ...state,
        data: action.payload
      }
    default: 
      return state
  }
}

export const dirtyFields = (state= {}, action) => {
  switch(action.type){
    case 'DIRTY_FIELDS':
      return{
        ...state,
        data: action.payload
      }
    default: 
      return state
  }
}