import { useEffect, useState } from "react";
import UdyamDashBoard from "../dashboard/DashBoard";
import PaymintScreen from "../paymint/Paymint";
import "./home.scss";
import Loan from "../credits/Loan";
import UdyamHelp from "../help/Help&Support";
import UdyamNavbar from "../components/navbar/Navbar";
import {
  BlueCircle,
  PinkCircle,
  ZigZagPinkCircle,
} from "../../../../assets/svg";
import { ApiService } from "../../../../services/api/apiService";

const UdyamHomeScreen = () => {
  const [screen, setScreen] = useState("UdyamDashBoard");

  return (
    <>
      <div>
        <div className="udyam-css">
          <div className="udyam-background">
            <BlueCircle className="udyam-blue-circle" />
            <PinkCircle className="udyam-pink-circle" />
            <ZigZagPinkCircle className="udyam-zig-zag" />
            <div className="udyam-purple-circle"></div>
          </div>
        </div>
        <div className="udyam-home-navbar">
          <UdyamNavbar setScreen={setScreen} screen={screen} />
        </div>
        <div>
          {screen === "UdyamDashBoard" && <UdyamDashBoard />}
          {screen === "Paymint" && <PaymintScreen />}
          {screen === "Credit" && <Loan />}
          {screen === "Help" && <UdyamHelp />}
        </div>
      </div>
    </>
  );
};

export default UdyamHomeScreen;
