import { useDispatch, useSelector } from "react-redux";
import {
  BlueCircle,
  DisabledCircle,
  GreenDot,
  MoneyBag,
  OrangeDot,
  PinkCircle,
  ZigZagPinkCircle,
} from "../../../../assets/svg";
import BreadCrumb from "../../../../components/partner-application/breadcrumb/BreadCrumb";
import { ApiService } from "../../../../services/api/apiService";
import InstallmentBar from "../../loan-summary/InstallmentBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { currentAction } from "../../../../store/actions/customer-application/offerAction";
import { Mixpanel } from "../../../../routing/MixPannelWrapper";

const breadCrumbData = [
  // { name: "Home", path: "/arth/home" },
  { name: "Credit", path: "" },
];

const Loan = () => {
  const apiService = new ApiService();
  const displayText = useSelector((state: any) => state.language.languageText);
  const navigate = useNavigate();
  const location = useLocation();
  const { loanData, mobile, name, email } = location.state || {};
  const [offered, setOffered] = useState<any>({});
  const dispatch = useDispatch();
  const { partnerSlug } = useParams();

  const handleTrackApplication = () => {
    dispatch(currentAction("track_application"));
    navigate(`/${partnerSlug}/session/${mobile}/track_application`);
  };

  const [loanMap, setLoanMap] = useState([]);

  useEffect(() => {
    if (loanData) {
      let map = [];
      Object.keys(loanData).forEach((key) => {
        let x = {};
        const loan = loanData[key];
        x[loan.loan_uid] = loan;
        map.push(loan);
      });
      setLoanMap(map);
    }
  }, []);

  useEffect(() => {
    loanMap.forEach((value, key) => {
      const cohort_uid = value.cohort_uid;
      const customerCode = value.customer_code;
      apiService
        .getNewOffer(customerCode, cohort_uid)
        .then((res) => {
          setOffered(res?.data);
        })
        .catch((error) => {
          console.error("Error fetching offer for cohort");
        });
    });
  }, []);

  // const convertToEMI = () => {
  //   if (loanData) {
  //     let map = [];
  //     Object.keys(loanData).forEach((key) => {
  //       let x = {};
  //       const loan = loanData[key];
  //       x[loan.loan_uid] = loan;
  //       map.push(loan);
  //     });
  //     map.map(
  //       (value, key) =>
  //         value["enable_emi"] === true &&
  //         apiService.convertToEMI(value["loan_uid"]).then((res) => {
  //           navigate(`/convertemi`, {
  //             state: {
  //               convertData: res?.data?.data,
  //               loan_uid: value["loan_uid"],
  //               dueAmount: value["amount_requested"],
  //               payment_status: value["payment_status"],
  //               partner_slug: value["partner_code"],
  //               customer_code: value["customer_code"],
  //             },
  //           });
  //         })
  //     );
  //   }
  // };

  const handleOfferLink = () => {
    dispatch(currentAction("journey_wizard"));
    navigate(`/${partnerSlug}/session/${mobile}/journey_wizard`);
  };

  const openJourneyLink = () => {
    if (
      (sessionStorage.getItem("utm_source") &&
        sessionStorage.getItem("utm_medium") &&
        sessionStorage.getItem("utm_campaign") === "repeat-closed") !== null
    ) {
      Mixpanel.identify(
        mobile +
          "-" +
          sessionStorage.getItem("utm_source") +
          "-" +
          sessionStorage.getItem("utm_medium") +
          "-" +
          sessionStorage.getItem("utm_campaign") +
          "-" +
          localStorage.getItem("Device")
      );
      Mixpanel.track("Repeat-Onboarding Fulfillment", "");
      Mixpanel.people.set({
        $first_name: name,
      });
    } else if (
      (sessionStorage.getItem("utm_source") &&
        sessionStorage.getItem("utm_medium") &&
        sessionStorage.getItem("utm_campaign") === "top-up") !== null
    ) {
      Mixpanel.identify(
        mobile +
          "-" +
          sessionStorage.getItem("utm_source") +
          "-" +
          sessionStorage.getItem("utm_medium") +
          "-" +
          sessionStorage.getItem("utm_campaign") +
          "-" +
          localStorage.getItem("Device")
      );
      Mixpanel.track("TopUp-Onboarding Fulfillment", "");
      Mixpanel.people.set({
        $first_name: name,
      });
    }
    window.open(offered?.journey_link, "_self");
  };

  return (
    <>
      <div>
        <div style={{ padding: "10px" }}>
          <b>
            <BreadCrumb breadCrumbData={breadCrumbData} />
          </b>
        </div>
        <div className="home_screen">
          <div className="scrollable_loan">
            {["offered"].includes(offered?.status) && (
              <div className="loan_detail">
                <div className="repeat">
                  <div className="sub-heading p-3">Congratulations</div>
                  <div className="label px-3">
                    You are eligible for a loan upto
                  </div>
                  <div className="amount p-3">₹ 50,000</div>
                  <div className="d-flex justify-content-between">
                    <div className="p-3">
                      <div className="label">
                        Complete Your Loan Application
                      </div>
                      <button
                        className="continue-button px-3 py-2 mt-3"
                        onClick={openJourneyLink}
                      >
                        Start
                      </button>
                    </div>
                    <div className="d-flex">
                      <MoneyBag />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loanMap.map((value, key) => (
              <div key={key}>
                {["requested"].includes(value["status"]) && (
                  <div className="loan_detail">
                    <div className="repeat">
                      <div className="sub-heading p-3">Congratulations</div>
                      <div className="label px-3">
                        You are eligible for a loan upto
                      </div>
                      <div className="amount p-3">
                        ₹{value["amount_requested"]}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="p-3">
                          <div className="label">
                            Complete Your Loan Application
                          </div>
                          <button
                            className="continue-button px-3 py-2 mt-3"
                            onClick={handleOfferLink}
                          >
                            Continue
                          </button>
                        </div>
                        <div className="d-flex">
                          <MoneyBag />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {["disbursed", "partially_repaid"].includes(value["status"]) &&
                  (["NTL-credit_new", "NTL-converted"].includes(
                    value["loan_category"]
                  ) ? (
                    <>
                      <div>
                        <div className="ntl-loan_detail">
                          <div className="ntl-inner-card ntl-active">
                            <div className="d-flex justify-content-between p-3">
                              <div>
                                <label className="ntl-sub-heading">
                                  Loan Id
                                </label>
                                <span className="ntl-loan-uid ms-2">
                                  {value["loan_uid"]}
                                </span>
                              </div>
                              <div className="ntl-status px-1 d-flex">
                                <div className="status-svg me-2">
                                  <GreenDot />
                                </div>
                                <div>Active</div>
                              </div>
                            </div>
                            <div className="px-3 ntl-amount">
                              ₹{" "}
                              {parseInt(
                                value["amount_requested"]
                              ).toLocaleString()}
                            </div>
                            <div className="ntl-installment p-3 pt-2">
                              <label className="ntl-text">
                                Installment Amount
                              </label>
                              <span className="mx-2 ntl-value">
                                {value["paid_installment"] || 0} /{" "}
                                {value["number_of_installments"]}
                              </span>
                              <div className="d-flex justify-content-between mt-3">
                                <div>
                                  <span className="ntl-text">Paid</span>
                                  <span className="mx-2 ntl-value">
                                    ₹{" "}
                                    {parseInt(
                                      value["amount_paid"]
                                    ).toLocaleString()}
                                  </span>
                                </div>
                                <div>
                                  <span className="ntl-text">Due</span>
                                  <span className="mx-2 ntl-value">
                                    ₹{" "}
                                    {parseInt(
                                      value["amount_due"]
                                    ).toLocaleString()}
                                  </span>
                                </div>
                              </div>
                              <InstallmentBar
                                paid={parseInt(value["amount_paid"])}
                                due={parseInt(value["amount_due"])}
                              />
                              {value["enable_emi"] === true ? (
                                <>
                                  <div className="ntl-conversion-detail">
                                    <div className="ntl-text-alignment">
                                      <div className="ntl-convert-text-1">
                                        Convert your due amount to Easy
                                        Installments
                                      </div>
                                      <div className="ntl-button">
                                        <button
                                          className="ntl-convert-button"
                                          type="button"
                                          // onClick={convertToEMI}
                                        >
                                          <p className="ntl-convert-button-text">
                                            Proceed
                                          </p>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div
                            className="view-button"
                            onClick={() =>
                              navigate(`/loanscreen`, {
                                state: {
                                  loan_uid: value["loan_uid"],
                                  paid_installment: value["paid_installment"],
                                  loan_category: value["loan_category"],
                                },
                              })
                            }
                          >
                            View
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="loan_detail">
                        <div className="inner-card active">
                          <div className="d-flex justify-content-between p-3">
                            <div>
                              <label className="sub-heading">Loan Id</label>
                              <span className="loan-uid ms-2">
                                {value["loan_uid"]}
                              </span>
                            </div>
                            <div className="status px-1 d-flex">
                              <div className="status-svg me-2">
                                <GreenDot />
                              </div>
                              <div>Active</div>
                            </div>
                          </div>
                          <div className="px-3 amount">
                            ₹{value["amount_requested"]}
                          </div>
                          <div className="installment p-3 pt-2">
                            <label className="text">Installment Amount</label>
                            <span className="mx-2 value">
                              {value["paid_installment"] || 0}/
                              {value["number_of_installments"]}
                            </span>
                            <div className="d-flex justify-content-between mt-3">
                              <div>
                                <span className="text">Paid</span>
                                <span className="mx-2 value">
                                  ₹{value["amount_paid"]}
                                </span>
                              </div>
                              <div>
                                <span className="text">Due</span>
                                <span className="mx-2 value">
                                  ₹{value["amount_due"]}
                                </span>
                              </div>
                            </div>
                            <InstallmentBar
                              paid={parseInt(value["amount_paid"])}
                              due={parseInt(value["amount_due"])}
                            />
                          </div>
                          <div
                            className="view-button"
                            onClick={() => {
                              const customer_ucic = value["customer_code"];
                              Mixpanel.identify(
                                mobile +
                                  "-" +
                                  customer_ucic +
                                  "-" +
                                  localStorage.getItem("Device")
                              );
                              Mixpanel.track("Visits-Loan Summary Section", "");
                              Mixpanel.people.set({
                                $first_name: name,
                              });
                              navigate(`/loanscreen`, {
                                state: {
                                  loan_uid: value["loan_uid"],
                                  paid_installment: value["paid_installment"],
                                },
                              });
                            }}
                          >
                            View
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                {[
                  "accepted",
                  "disbursal_put_on_hold",
                  "disbursal_approved",
                  "disbursal_approved",
                  "kyc_on_hold",
                  "lender_approved",
                  "kyc_verified",
                  "re_kyc",
                ].includes(value["status"]) && (
                  <div className="loan_detail">
                    <div className="inner-card processing">
                      <div className="d-flex justify-content-between p-3">
                        <div>
                          <label className="sub-heading">Loan Id</label>
                          <span className="loan-uid ms-2">
                            {value["loan_uid"]}
                          </span>
                        </div>
                        <div className="status px-1 d-flex">
                          <div className="status-svg me-2">
                            <OrangeDot />
                          </div>
                          Processing
                        </div>
                      </div>
                      <div className="px-3 amount">
                        ₹{value["amount_requested"]}
                      </div>
                    </div>
                    <div>
                      <div className="installment p-3">
                        <span className="text">
                          Your application is under process.
                        </span>
                      </div>
                      <div className="view-button">
                        <div
                          className="application-box px-3 py-2"
                          onClick={() =>
                            navigate(`/trackapplication`, {
                              state: { loan_uid: value["loan_uid"] },
                            })
                          }
                        >
                          Track Application
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="banner mt-3">
            <img
              src="https://arth-banner.s3.ap-south-1.amazonaws.com/Udhyam+Banner+(1).png"
              style={{ height: "9rem", borderRadius: "12px" }}
            ></img>
          </div>
          <br></br>
          {loanMap.map((value, key) => (
            <div key={key}>
              {["closed", "fully_repaid"].includes(value["status"]) && (
                <div className="main-heading my-3">
                  {" "}
                  Previous loan
                  <div className="loan_detail">
                    <div className="inner-card closed">
                      <div className="d-flex justify-content-between p-3">
                        <div>
                          <label className="sub-heading">Loan Id</label>
                          <span className="loan-uid ms-2">
                            {value["loan_uid"]}
                          </span>
                        </div>
                        <div className="status px-1 d-flex">
                          <div className="status-svg me-2">
                            <DisabledCircle />
                          </div>
                          <div>Closed</div>
                        </div>
                      </div>
                      <div className="px-3 amount">Amount</div>
                      <div className="installment p-3">
                        <label className="text">Installment Amount</label>
                        <span className="mx-2 value">
                          {value["paid_installment"]}/
                          {value["number_of_installments"]}
                        </span>
                        <div className="d-flex justify-content-between mt-3">
                          <div>
                            <span className="text">Paid</span>
                            <span className="mx-2 value">
                              {value["amount_paid"]}
                            </span>
                          </div>
                          <div>
                            <span className="text">Due</span>
                            <span className="mx-2 value">
                              {value["amount_due"]}
                            </span>
                          </div>
                        </div>
                        <InstallmentBar
                          paid={parseInt(value["amount_paid"])}
                          due={parseInt(value["amount_due"])}
                        />
                      </div>
                      <div
                        className="view-button"
                        onClick={() => {
                          const customer_ucic = value["customer_code"];
                          const loanID = value["loan_uid"];
                          Mixpanel.identify(
                            mobile + "-" + customer_ucic + "-" + loanID
                          );
                          Mixpanel.track("Visits-Loan Summary Section", "");
                          Mixpanel.people.set({
                            $first_name: name,
                          });
                          navigate(`/loanscreen`, {
                            state: {
                              loan_uid: value["loan_uid"],
                              paid_installment: value["paid_installment"],
                              name: name,
                              mobile: mobile,
                            },
                          });
                        }}
                      >
                        View
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Loan;
