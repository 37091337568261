import { useState, useRef } from "react";
import LoanSummaryHeaderPWA from "../credits/loansummaryHeader2";
import RecordRTC from "recordrtc";
import "./help-support.scss";
import { ApiService } from "../../../../services/api/apiService";
import { useLocation } from "react-router-dom";
import { Mixpanel } from "../../../../routing/MixPannelWrapper";
import { Toaster } from "../../../../utils/utilities/Toaster";

const QueriesHelpSection = () => {
  const [subject, setSubject] = useState("");
  const [query, setQuery] = useState("");
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const apiService = new ApiService();
  const location = useLocation();
  const { name, mobile } = location.state || {};
  const toaster = new Toaster();

  const mediaRecorderRef = useRef<RecordRTC | null>(null);

  const handleStartRecording = () => {
    Mixpanel.identify(mobile + "-" + localStorage.getItem("Device"));
    Mixpanel.track("Queries Sections-Adhoc Audio", "");
    Mixpanel.people.set({
      $first_name: name,
    });
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new RecordRTC(stream, { type: "audio" });
        mediaRecorderRef.current.startRecording();
        setRecording(true);
      })
      .catch((error) => console.error("Error accessing media devices.", error));
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stopRecording(() => {
        const audioFile = mediaRecorderRef.current?.getBlob();
        if (audioFile) {
          const audioURL = URL.createObjectURL(audioFile);
          setAudioURL(audioURL);
          setAudioBlob(audioFile);
        }
        setRecording(false);
      });
    }
  };

  const handleSubmit = async () => {
    Mixpanel.identify(mobile + "-" + localStorage.getItem("Device"));
    Mixpanel.track("Queries Sections- Adhoc Submit", "");
    Mixpanel.people.set({
      $first_name: name,
    });
    if (!audioBlob) {
      console.error("No audio recording found.");
      return;
    }

    let formData = new FormData();
    formData.append("subject", subject);
    formData.append("query", query);
    formData.append("voice", audioBlob, "recording.wav");
    formData.append("mobile", mobile);
    setIsClicked(true)

      
    apiService.support(formData).then((response) => {
      if(response?.data?.message === "Your query send successfully.") {
        toaster.show(false, response?.data?.message)
      }
    });
  };

  return (
    <>
      <LoanSummaryHeaderPWA />
      <div className="queries-help-section">
        <div className="queries-help-section-content">
          <div>
            <label htmlFor="subject">Subject</label>
            <input
              id="subject"
              type="text"
              placeholder="Enter the subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div
            onClick={() => {
              Mixpanel.identify(mobile + "-" + localStorage.getItem("Device"));
              Mixpanel.track("Queries Sections-Adhoc Text", "");
              Mixpanel.people.set({
                $first_name: name,
              });
            }}
          >
            <label htmlFor="query">Your Query</label>
            <textarea
              id="query"
              placeholder="Enter your query"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div>
            <label>Record Your Query</label>
            {recording ? (
              <button className="record-cta" onClick={handleStopRecording}>
                Stop Recording
              </button>
            ) : (
              <button className="record-cta" onClick={handleStartRecording}>
                Record
              </button>
            )}
            {audioURL && (
              <div style={{ marginTop: "20px" }}>
                <audio controls src={audioURL} />
              </div>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="submit-cta" onClick={handleSubmit} disabled={isClicked}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueriesHelpSection;
