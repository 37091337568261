import { useLocation, useNavigate } from "react-router-dom";
import * as svg from "../../../../assets/svg";
import "./help-support.scss";
import BreadCrumb from "../../../../components/partner-application/breadcrumb/BreadCrumb";
import { Mixpanel } from "../../../../routing/MixPannelWrapper";

const breadCrumbData = [{ name: "Help", path: "" }];

const UdyamHelp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, mobile } = location.state || {};

  return (
    <>
      <div className="help-support">
        <div>
          <b>
            <BreadCrumb breadCrumbData={breadCrumbData} />
          </b>
        </div>
        <div className="card payments-help">
          <div
            className="help-support-card-content"
            onClick={() => {
              Mixpanel.identify(mobile + "-" + localStorage.getItem("Device"));
              Mixpanel.track("Queries Sections- FAQs Business Prime", "");
              Mixpanel.people.set({
                $first_name: name,
              });
              navigate(`/arth/help/payment`);
            }}
          >
            <div style={{ textAlign: "left", fontWeight: "500" }}>
              Business Prime
            </div>
            <div style={{ textAlign: "right" }}>
              <svg.RightArrow />
            </div>
          </div>
        </div>
      </div>
      <div className="help-support">
        <div className="card payments-help">
          <div
            className="help-support-card-content"
            onClick={() => {
              Mixpanel.identify(mobile + "-" + localStorage.getItem("Device"));
              Mixpanel.track("Queries Sections- FAQs Business Loan", "");
              Mixpanel.people.set({
                $first_name: name,
              });
              navigate(`/arth/help/credit`);
            }}
          >
            <div style={{ textAlign: "left", fontWeight: "500" }}>
              Business Loan
            </div>
            <div style={{ textAlign: "right" }}>
              <svg.RightArrow />
            </div>
          </div>
        </div>
      </div>
      <div className="help-support">
        <div className="card payments-help">
          <div
            className="help-support-card-content"
            onClick={() => {
              Mixpanel.identify(mobile + "-" + localStorage.getItem("Device"));
              Mixpanel.track("Queries Sections- FAQs Profile Update", "");
              Mixpanel.people.set({
                $first_name: name,
              });
              navigate(`/arth/help/profilechange`);
            }}
          >
            <div style={{ textAlign: "left" }}>Profile Change</div>
            <div style={{ textAlign: "right" }}>
              <svg.RightArrow />
            </div>
          </div>
        </div>
      </div>
      <div className="help-support">
        <div className="card payments-help">
          <div
            className="help-support-card-content"
            onClick={() => {
              Mixpanel.identify(mobile + "-" + localStorage.getItem("Device"));
              Mixpanel.track("Queries Sections- FAQs Adhoc", "");
              Mixpanel.people.set({
                $first_name: name,
              });

              navigate(`/arth/help/queries`, {
                state: { mobile: mobile, name: name },
              });
            }}
          >
            <div style={{ textAlign: "left" }}>Any Other Queries?</div>
            <div style={{ textAlign: "right" }}>
              <svg.RightArrow />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UdyamHelp;
