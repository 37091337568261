import '../../../partner-application/text-input/text-input.scss';
import './dropdown.scss';

const DropDown = (props) => {

  const {
    list,
    value,
    heading,
    identifier,
    handleInput,
    isCompulsoryField,
    supportiveTextGreen,
    supportiveTextRed,
    disabled,
    reKycRemarks
  } = props;

  const handleDropdownSelect = (event) => {
    handleInput(identifier,event.target.value);
  }

  return (
    <div className="partner-dropdown">
      <div className='partner-heading'>{heading}<span>{isCompulsoryField && '*'}</span></div>
      <select style={{padding: '.200rem .200rem .200rem .200rem',fontSize: 'small'}} className={`form-select ${supportiveTextRed && 'red-border'}`} onChange={handleDropdownSelect} value={value} disabled={disabled} >
        <option className='partner-heading'>Select</option>
        {list?.map((key, index) => {
          return (
            <option key={((identifier == 'branch_name') ? key['id'] : index)} value={((identifier == 'branch_name') ? key['id'] : key)}>{((identifier == 'branch_name') ? key['bank_branches'] : key)}</option>
          )
        })}
      </select>
      <div className='partner-supportive-text-red'>{supportiveTextRed}</div>
      <div className='partner-supportive-text-green'>{supportiveTextGreen}</div>
      {reKycRemarks?.map((remark, index) => {
        return (
          <div key={index} className='partner-supportive-text-red'>{remark}</div>
        )
      })}
    </div>
  )
}

export default DropDown;