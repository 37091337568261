import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const monthNames = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const LineChartComponent = ({ title, chartData, type }) => {
  let color;
  let months = chartData.monthly_leads.map(({ month }) => monthNames[month-1]);
  let values = chartData.monthly_leads.map(({ count }) => count);
  if (type === "leads") {
    color = "#473192";
  }
  //This is for risk
  else {
  months = chartData.risk_spread_distribution.map(({ month }) => month);
  values = chartData.risk_spread_distribution.map(({ sum }) => sum);
    color = "#DC3545";
  }
  const data = {
    labels: months,
    datasets: [
      {
        
        label: null,
        data: values,
        fill: false,
        backgroundColor: color,
        borderColor: color,
        tension: 0.3, // This adds some "curve" to the line
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        // position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Sales Data",
      },
    },
  };

  return (
    <div>
      {title ? <span className="chartSubHeader">{title}</span>: <></>}
      <Line
        options={options}
        data={data}
        style={{ width: "65%", height: "65%" }}
      />
    </div>
  );
};

export default LineChartComponent;
