import React from 'react';
import Lottie from "lottie-react";
import ApiLoader from '../../../assets/images/api-loader.json';
import { useSelector } from 'react-redux';
import './loader.scss';

const GlobalLoader = () => {

  const showLoader = useSelector((state:any) => (state.loader));

  return (
    <>
      {showLoader ? <div className='model-background-2'>
        <div className="lottie-animaton">
          <Lottie animationData={ApiLoader} loop={true} />
        </div>
      </div> : <></>}
    </>
  )
}

export default GlobalLoader;
