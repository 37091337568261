import "./kyc.scss";

function CoBorrowerKYC(data) {
  const kycResponse = {
    "Aadhar Details": {
      "Aadhar No.": data?.data?.aadhar_number,
    },
    "Pan Details": {
      "PAN No.": data?.data?.pan_number,
    },
  };

  return (
    <div className="kyc-container">
      <span className="kyc-header">KYC DETAILS</span>
      <div
        style={{
          display: "flex",
          padding: "10px",
          gap: "10px",
        }}
      >
        <div class="col-md kyc-container">
          <span>Aadhar Details</span>
          <div>
            <div>
              <div class="col-md">
                {Object.keys(kycResponse["Aadhar Details"]).map((key) => (
                  <div class="row">
                    <div class="col-md basic-left-body">{key}</div>
                    <div class={`col-md basic-right-body`}>
                      {kycResponse["Aadhar Details"][key]}
                    </div>
                  </div>
                ))}
                {data?.pan_number ? (
                  <>
                    <div class="col-md-auto basic-left-body"></div>
                    <div class="col-md basic-right-body pan-name">Verified</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md kyc-container">
          <span>Pan Details</span>
          <div>
            <div>
              <div class="col-md">
                {Object.keys(kycResponse["Pan Details"]).map((key) => (
                  <div class="row">
                    <div class="col-md basic-left-body">{key}</div>
                    <div class={`col-md basic-right-body`}>
                      {kycResponse["Pan Details"][key]}
                    </div>
                  </div>
                ))}
                {data?.data?.pan_number ? (
                  <>
                    <div class="col-md-auto basic-left-body"></div>
                    <div class="col-md basic-right-body pan-name">Verified</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoBorrowerKYC;
