import React, { useEffect, useState } from "react";
import "./customers.scss";
import FilterButtons from "../../../components/ice-application/customers/FIlterButton/FilterButtons";
import TableComponent from "../../../components/ice-application/customers/TableComponent/TableComponent";
import PaginationComponent from "../../../components/ice-application/customers/PaginationComponent/PaginationComponent";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../../../components/ice-application/navbar/NavbarComponent";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { TextField } from "@mui/material";

function Customers() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("ICELOGGEDIN")) {
      navigate("/ice/");
    }
  }, []);

  const [tableData, setTableData] = useState();
  const [urlParams, setUrlParams] = useState({});
  const [paginationParams, setPaginationParams] = useState();

  const [statusCounts, setStatusCount] = useState();

  useEffect(() => {
    fetchData();
  }, [urlParams]);
  const fetchData = async () => {
    let url = new URL(
      process.env.REACT_APP_ICE_BASE_URL +
        "/customers-listing?status=&customer_ucic=&date=&center=&partner=&page=1"
    );
    const requestOptions = {
      headers: { Authorization: process.env.REACT_APP_ICE_AUTHORIZATION },
    };

    Object.entries(urlParams).map(([key, value], i) =>
      url.searchParams.set(key, value)
    );

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    setTableData(data.data); //Setting the response into state
    setStatusCount(data.status_counts);
    setPaginationParams({ total: data.total, current_page: data.current_page });
  };

  if (tableData && localStorage.getItem("ICELOGGEDIN")) {
    return (
      <>
        <NavbarComponent active={"Customers"} />

        <div className="customers-main-container">
          <div class="form-group has-search">
            <TextField
              placeholder="Search"
              id="outlined"
              variant="outlined"
              size="small"
              onChange={(e) =>
                setUrlParams({ ...urlParams, customer_ucic: e.target.value })
              }
              sx={{
                // Root class for the input field
                "& .MuiOutlinedInput-root": {
                  color: "#3E3E3E",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  fontSize: "14px",
                  // Class for the border around the input field
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "1px",
                    borderRadius: "14px",
                  },
                  "&:after": {
                    borderColor: "#EE3E80",
                  },

                  "&:hover:not(.Mui-focused)": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#EE3E80",
                    },
                  },

                  // Class for the label of the input field
                  "& .MuiInputLabel-outlined": {
                    color: "#2e2e2e",
                    fontWeight: 400,
                    fontFamily: "Montserrat",

                    borderColor: "#EE3E80",
                  },
                  "&.Mui-focused": {
                    borderColor: "#EE3E80",
                  },
                  "&:after": {
                    borderColor: "#EE3E80",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <SearchOutlinedIcon
                    sx={{ color: "#B0B0B0", marginRight: "15px" }}
                    fontSize="small"
                  />
                ),
              }}
            />
          </div>
          <FilterButtons setParams={setUrlParams} statusCounts={statusCounts}/>
          <TableComponent data={tableData} navigate={navigate} />
          <div className="pagination">
            <PaginationComponent
              params={paginationParams}
              uParams={urlParams}
              setUParams={setUrlParams}
            />
          </div>
        </div>
      </>
    );
  }
}
export default Customers;
