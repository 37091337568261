import React from 'react';
import { CameraSVG, CrossButtonSvg, DotSVG, LoactionSvg, MicrophoneSvg, SmsSvg } from '../../../assets/svg';
import './model.scss';
import { useSelector } from 'react-redux';
import { assistedPartnerList } from '../../../utils/helper-function/helper';
import { useParams } from 'react-router-dom';

const Model = (props:any) => {

  const {heading, isLendingPage, handleClick} = props;
  const displayText = useSelector((state:any) => (state.language.languageText));
  const {partnerSlug} = useParams();

  const modelButtonClicked = () => {
    handleClick();
  }

  return (
    <div className="model-background">
      <div className='model'>
        <div className="model-heading">
          <div>{heading}</div>
          <div onClick={modelButtonClicked}><CrossButtonSvg /></div>
        </div>
        {isLendingPage && <div className="model-content">
          {!assistedPartnerList.includes(partnerSlug) && <><div className='spec-model-info'>
            <div>
              <CameraSVG />
            </div>
            <div className='model-text-content'>
              <div>{displayText?.camera}</div>
              <div>{displayText?.camera_info}</div>
            </div>
          </div>
          <div className='spec-model-info'>
            <div>
              <MicrophoneSvg />
            </div>
            <div className='model-text-content'>
              <div>{displayText?.microphone}</div>
              <div>{displayText?.microphone_info}</div>
            </div>
          </div></>}
          <div className='spec-model-info'>
            <div>
              <LoactionSvg />
            </div>
            <div className='model-text-content'>
              <div>{displayText?.location}</div>
              <div>{displayText?.location_info}</div>
            </div>
          </div>
          <div className='spec-model-info'>
            <div>
              <SmsSvg />
            </div>
            <div className='model-text-content'>
              <div>{displayText?.sms}</div>
              <div>{displayText?.sms_info}</div>
            </div>
          </div>
        </div>}
        {!isLendingPage && <div className='model-content'>
              <div className="spec-model-info-2">
                <div><DotSVG /></div>
                <div>{displayText?.aadhar_number}</div>
              </div>
              <div className="spec-model-info-2">
                <div><DotSVG /></div>
                <div>{displayText?.pan_number}</div>
              </div>
              <div className="spec-model-info-2">
                <div><DotSVG /></div>
                <div>{displayText?.debit_card}</div>
              </div>
          </div>}
        <div className='model-button' onClick={modelButtonClicked}>
          {displayText?.proceed}
        </div>
      </div>
    </div>
  )
}

export default Model;
