import Swal from "sweetalert2";
import './toaster.scss';
import './utilities.scss';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success m-2",
    cancelButton: "btn btn-outline-secondary m-2"
  },
  buttonsStyling: false
});

const imageViewStyle = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger"
  },
  buttonsStyling: false
})

export class Toaster {

  show(error: boolean = true, text: string = 'Something went wrong!!') {
    if (error) {
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        // title: "Oops...",
        text: text,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      Swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Congratulations",
        text: text,
        showConfirmButton: false,
        timer: 1500
      })
    }
  }
  showhuntingform(error: boolean = true, text: string = 'Something went wrong!!') {
    if (error) {
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        // title: "Oops...",
        text: text,
        showConfirmButton: false,
        timer: 5000
      })
    } else {
      Swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Congratulations",
        text: text,
        showConfirmButton: false,
        timer: 3000
      })
    }
  }
  confirmation(confirmButtonText: string = 'Approve', text: string = "This sends an e-mail to the partner to update his/her profile!") {
    return swalWithBootstrapButtons.fire({
      title: confirmButtonText === 'ReKYC' ? 'Are you sure to send this for Re-KYC?': 'Are you sure?',
      text: confirmButtonText ==='ReKYC'? '':text,
      // icon: "warning",
      showCancelButton: true,
      // confirmButtonText: "Cancel",
      confirmButtonText: "Confirm", // Change to "Confirm" to match the logic
      cancelButtonText: "Cancel",
      // cancelButtonText: confirmButtonText,
      reverseButtons: true,
      customClass: {
        container: 'custom-modal-container',
        confirmButton: "btn btn-success m-2",
    cancelButton: "btn btn-outline-secondary m-2"
         // Add a custom class to the modal container
      }
    })
  }
  confirmationunnati(confirmButtonText: string = 'Approve', text: string = "This sends an e-mail to the partner to update his/her profile!") {
    return swalWithBootstrapButtons.fire({
      title: confirmButtonText === 'ReKYC' ? 'Are you sure to send this for Re-KYC?': 'Are you sure?',
      text: confirmButtonText ==='ReKYC'? '':'',
      // icon: "warning",
      showCancelButton: true,
      // confirmButtonText: "Cancel",
      confirmButtonText: "Confirm", // Change to "Confirm" to match the logic
      cancelButtonText: "Cancel",
      // cancelButtonText: confirmButtonText,
      reverseButtons: true,
      customClass: {
        container: 'custom-modal-container',
        confirmButton: "btn btn-success m-2",
    cancelButton: "btn btn-outline-secondary m-2"
         // Add a custom class to the modal container
      }
    })
  }

  viewImage(img: string, height: any = 400) {
    imageViewStyle.fire({
      imageUrl: img,
      imageHeight: height,
      imageWidth: 400,
      imageAlt: "Image",
      background: "transparent",
      showConfirmButton: true,
    });
  }

  inputConfirmation(confirmButtonText: string = 'Approve', partnerType: string, cancelButtonText: string = 'Cancel') {
    
    if (confirmButtonText === 'Approve') {
      if(partnerType === 'HLP'){
        const swalPromise = swalWithBootstrapButtons.fire({
          title: '<div class="hlp-type"><div class="hlp-type-title">Before approving, please tag partner to a category</div></div>',
          html: `
          <div class="hlp-type">
            <div class="select-hlp">
              <select id="partnerCategory" class="swal2-select">
                <option value="" data-color="#717ABC">Select</option>
                <option value="Small" data-color="#DC3545" text-color="#E3FFEF">Small</option>
                <option value="Medium" data-color="#F1B300" text-color="#E3FFEF">Medium</option>
                <option value="Large" text-color="#10B759" data-color="#E3FFEF">Large</option>
              </select>
            </div>
          </div>
        `,
          showCancelButton: true,
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          preConfirm: () => {
            const partnerCategoryInput = document.getElementById('partnerCategory') as HTMLSelectElement;
            const partnerCategory = partnerCategoryInput.value;
            if (!partnerCategory) {
              Swal.showValidationMessage('Please select a partner category.');
            }
            return { partnerCategory: partnerCategory };
          }
        })
        const partnerCategorySelect = document.getElementById('partnerCategory') as HTMLSelectElement;
        partnerCategorySelect.addEventListener('change', function () {
          const select = this as HTMLSelectElement;
          const selectedOption = select.options[select.selectedIndex];
          const color = selectedOption.getAttribute('data-color');
          const textColor = selectedOption.getAttribute('text-color');
          select.style.backgroundColor = color;
          select.style.color = textColor;
        });
        return swalPromise;
      }
      else{
        const swalPromise = swalWithBootstrapButtons.fire({ 
          title: '<div class="reason-to-reject"><div class="hlp-reject-title">Are you sure you want to Approve?</div></div>',
          showCancelButton: true,
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          // preConfirm: () => {
          //   const partnerCategoryInput = document.getElementById('partnerCategory') as HTMLSelectElement;
          //   const partnerCategory = partnerCategoryInput.value;
            
          //   return { partnerCategory: partnerCategory };
          // }
        })
        return swalPromise;
      }

    }
    else {
      const swalPromise = swalWithBootstrapButtons.fire({
        title: '<div class="reason-to-reject"><div class="hlp-reject-title">Are you sure you want to reject?</div></div>',
        html: `
        <div class="reason-to-reject">
          <div class="reject-reason">
          <input class="reason-to-reject" id="reason-to-reject-text" type="text"> </input>
          </div>
        </div>
      `,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        preConfirm: () => {
          const partnerRejectReasonTxt = document.getElementById('reason-to-reject-text') as HTMLSelectElement;
          const partnerRejectTxt = partnerRejectReasonTxt.value;
          if (!partnerRejectTxt) {
            Swal.showValidationMessage('Please give a reason to reject this partner.');
          }
          return { rejectPartnerReason: partnerRejectTxt };
        }
      })
      return swalPromise;
    }
  }


  showDocs(text: string = "You won't be able to revert this!") {
    return swalWithBootstrapButtons.fire({
      title: 'Documents Required',
      text: text,
      icon: "warning",
      // color: '#10B759',
      showCancelButton: false,
      confirmButtonText: 'OK',
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        container: 'custom-modal-container' // Add a custom class to the modal container
      }
    })
  }



}