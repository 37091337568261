import { ArthLogo, MobileOtp, LogoCoin } from "../../../assets/svg";
import Header from "./Header";

const LendingPageHeader = ({isJourneyStarted}:any) => {
  return (
    <>
      <Header />
      <div className="blurr-div">
        <div className={`${isJourneyStarted? 'arth-logo-mobile-otp' : 'arth-logo-circle'} `}>
          {isJourneyStarted ? 
          <>
            <ArthLogo className='arth-logo-otp'/>
            <MobileOtp className='mobile-otp-svg' />
          </>
          : <>
            <LogoCoin className='logo-coin' />
            <div className="arth-msg">
                Powering Micro Businesses
            </div>
            <div className="copyright">
              © 2023 ARTH
            </div>
          </>}
        </div>
      </div>
    </>
  )
}

export default LendingPageHeader;
