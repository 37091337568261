import React from 'react';
import './radio-input.scss';

const RadioInput = (props:any) => {

  const {heading, value, label1, label2, isCompulsoryField, handleInput, identifier, supportiveTextRed} = props;

  const handleRadioInput = (value:string) => {
    handleInput(identifier, value);
  }

  return (
    <div className="p-2">
      <div className='partner-heading'>{heading} <span>{isCompulsoryField && '*'}</span></div>
      <div className="form-check form-check-inline mr-2">
        <input className="form-check-input" checked={value === label1} type="radio" name={identifier+'1'} id={identifier+'1'} onChange={() => handleRadioInput(label1)}/>
        <label className="form-check-label">{label1}</label>
      </div>
      <div className="form-check form-check-inline mr-2">
        <input className="form-check-input" checked={value === label2} type="radio" name={identifier+'2'} id={identifier+'2'} onChange={() => handleRadioInput(label2)}/>
        <label className="form-check-label">{label2}</label>
      </div>
      <div className='partner-supportive-text-red'>{supportiveTextRed}</div>
    </div> 
  )
}

export default RadioInput;
