import { useState } from "react";
import "./profile-picture.scss";
import Modal from "react-bootstrap/Modal";
import  ProfileBG from "../../../../assets/svg/ice-svg/ProfileBG.svg";

function ProfilePicture({ imageUrl, userName, status }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let labelClass = "red-label-style";

  if (status === "Accepted") {
    labelClass = "green-label-style";
  }

  return (
    <div className="profile-picture-container" style={{backgroundImage: `url(${ProfileBG})`}}>
    
      <div
        style={{
          justifyContent: "center",
          padding: "20px"
        }}
      >
        
        <div style={{ display: "inline-flex"}}>
          <img
            src={imageUrl}
            style={{ width: "175px", height: "175px", borderRadius: "50%" }}
            alt=""
            onClick={() => handleShow()}
          />
          { status !== "In Progress" && 
          <div className={`label-style ${labelClass}`}>{status}</div> }
        </div>

        <div
          className="hdr-style"
          style={{
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          {userName}
        </div>

        <Modal show={show} onHide={handleClose} style={{ justifyContent: "center", alignItems: "center", flex: 1}}>
          <img src={imageUrl} alt="" style={{height: "100%", width: "100%"}} />
        </Modal>
      </div>
    </div>
  );
}

export default ProfilePicture;
