import { useState, useEffect } from "react";
import "./cta.scss";
import { ArthLogo, CrossButtonSvg, Install } from "../../../assets/svg";
import { Mixpanel } from "../../../routing/MixPannelWrapper";
import { useSearchParams } from "react-router-dom";

const CTA = (mobile, partnerSlug) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    setIsVisible(false);
  };

  const handlePopClose = () => {
    if (
      searchParams.get("utm_source") &&
      searchParams.get("utm_medium") &&
      searchParams.get("utm_campaign")
    ) {
      Mixpanel.identify(
        mobile +
          "-" +
          searchParams.get("utm_source") +
          "-" +
          searchParams.get("utm_medium") +
          "-" +
          searchParams.get("utm_campaign") +
          "-" +
          partnerSlug
      );
      Mixpanel.track("Pre Install CTA PopUp- LAJ", "");
      Mixpanel.people.set({
        $first_name: mobile,
      });
    } else if (mobile != null && partnerSlug != null) {
      Mixpanel.identify(mobile + "-" + partnerSlug);
      Mixpanel.track("Pre Install CTA PopUp- LAJ", "");
      Mixpanel.people.set({
        $first_name: mobile,
      });
    }
    setIsPopUpVisible(false);
  };

  const handleAddClick = () => {

    if (
      searchParams.get("utm_source") &&
      searchParams.get("utm_medium") &&
      searchParams.get("utm_campaign")
    ) {
      Mixpanel.identify(
        mobile +
          "-" +
          searchParams.get("utm_source") +
          "-" +
          searchParams.get("utm_medium") +
          "-" +
          searchParams.get("utm_campaign") +
          "-" +
          partnerSlug
      );
      Mixpanel.track("Pre-Install CTA- LAJ", "");
      Mixpanel.people.set({
        $first_name: mobile,
      });
    }
    if (mobile != null && partnerSlug != null) {
      Mixpanel.identify(mobile + "-" + partnerSlug);
      Mixpanel.track("Pre-Install CTA- LAJ", "");
      Mixpanel.people.set({
        $first_name: mobile,
      });
    }
    setIsVisible(false);
    setIsPopUpVisible(true);
  };

  return (
    <>
      {isVisible && (
        <div className="cta-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <ArthLogo
                style={{
                  background: "white",
                  borderRadius: "50%",
                  height: "40px",
                  width: "40px",
                }}
              />
            </div>
            &nbsp;
            <div>Add ARTH App to Homescreen</div>
            &nbsp;
            <div>
              <button
                onClick={handleAddClick}
                style={{
                  background: "transparent",
                  color: "#EE3E80",
                  borderColor: "#EE3E80",
                  borderRadius: "12px",
                }}
              >
                Add
              </button>
            </div>
            &nbsp;
            <div onClick={handleClose}>
              <CrossButtonSvg />
            </div>
          </div>
        </div>
      )}
      {isPopUpVisible && (
        <div className="install-container">
          <div style={{ padding: "1rem" }}>
            <div>
              <h6>Steps to Install ARTH App</h6>
            </div>
            <br></br>
            <div style={{ fontSize: "0.9rem" }}>
              Step 1: Click on More option on top right side of the browser
            </div>
            <div>
              <img
                src="https://arth-banner.s3.ap-south-1.amazonaws.com/MicrosoftTeams-image+(11).png"
                style={{ width: "100%", height: "50%" }}
                alt="install-step-1"
              ></img>
            </div>
            <br></br>
            <div style={{ fontSize: "0.9rem" }}>
              Step 2: Click on 'Add to Home Screen'
            </div>
            <div style={{ marginLeft: "45%" }}>
              <Install />
            </div>
            <br></br>
            <br></br>
            <div style={{ fontSize: "0.9rem" }}>
              Step 3: Click on 'Add' button to install the app
            </div>
          </div>
          <div onClick={handlePopClose}>
            <button
              className="continue-button1"
              style={{
                background: "#EFEFEF",
                color: "#EE3E80",
                borderRadius: "12px",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CTA;
