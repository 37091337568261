import English from '../../../assets/language/english.json';
import Hindi from '../../../assets/language/hindi.json';

const initialState = {
  language: localStorage.getItem('lang') || 'English',
  languageText: localStorage.getItem('lang') === 'Hindi'? Hindi : English
}

export const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LANGUAGE_CHANGE':
      return {
        ...state,
        language: action.payload,
        languageText: action.payload === 'English' ? English : Hindi,
      };
    default:
      return state;
  }
};