import { useSelector } from "react-redux";
import { useState, useRef } from "react";
import "./mobile-input.scss";
import ButtonFooter from "../../../../components/customer-application/button-footer/ButtonFooter";

const OtpHandler = ({
  mobile,
  otp,
  setOtp,
  isInputWrong,
  inputAccepted,
  inputMsg,
  verifyMobileOtp
}: {
  mobile: string;
  otp: string;
  setOtp: (otp: string) => void;
  isInputWrong: boolean;
  inputAccepted: boolean;
  inputMsg: string;
  verifyMobileOtp: () => void;
}) => {
  const displayText = useSelector((state: any) => state.language.languageText);
  
  const [otpArray, setOtpArray] = useState(new Array(6).fill(""));
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newOtpArray = [...otpArray];
      newOtpArray[index] = value;
      setOtpArray(newOtpArray);
      setOtp(newOtpArray.join(""));

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === "") {
      const newOtpArray = [...otpArray];
      newOtpArray[index] = "";
      setOtpArray(newOtpArray);
      setOtp(newOtpArray.join(""));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && otpArray[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div>
      <div className="otp_verification">
        {displayText?.otp_verification}
      </div>
      <div className="mobile">
        <div className="mobile-text-4">            
        </div>
        <div className="mobile-text-3 otp-input">
          {otpArray.map((digit, index) => (
            <input
              key={index}
              style={{ border: '2px solid #999', fontSize: '16px', width: '10%', height: '55px', borderRadius: '10px', boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)', textAlign: 'center', marginRight: '5px' }}
              type="number"
              pattern='[0-9]'
              className="otp-input"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el!)}
              maxLength={1}
            />
          ))}
        </div>
      </div>
      {inputMsg && <div className={`input-msg ${isInputWrong ? 'error' : 'success'}`}>
        {inputMsg}
      </div>}
      <div className='lending-button-1' onClick={verifyMobileOtp}>
        <ButtonFooter buttonContent={'Verify OTP'} />
      </div>
    </div>
  );
}

export default OtpHandler;
