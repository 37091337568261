import { Routes, Route } from 'react-router-dom';
import { lazy } from 'react';

const Support = lazy(() => import('../../pages/customer-support/support/Support'));
const Form = lazy(() => import('../../pages/customer-support/forms/Form'));

const SupportRoute = () => {
  return (
    <Routes>
      <Route path='' element={<Support />} />
      <Route path='form' element={<Form />} />
    </Routes>
  )
}

export default SupportRoute;
