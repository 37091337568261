
import React, { useState, useEffect } from "react";
import BusinessKYC from "../KYC/BusinessKYC";
import BusinessSlider from "../BusinessSlider/BusinessSlider";
import "./business-details-tab.scss";
import TransactionSummary from "../TransactionSummary/TransactionSummary";
import CashflowSummary from "../CashflowSummary/CashflowSummary";
import { Modal, Row } from "react-bootstrap";
import BusinessDetails from "../DetailsBox/BusinessDetails";
import BusinessVideo from "../BusinessVideo/BusinessVideo";
import RiskProfile from "../RiskProfile/RiskProfile";

import {
  GoogleMap,
  useLoadScript,
  Marker,
  Circle,
  InfoWindow,
} from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "260px",
};

function BusinessDetailsTab({ userData, makerData, riskDetails }) {
  const [activeTab, setActiveTab] = useState("photo");
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //const [makerData, setMakerData] = useState([]);
  const initialCenter = {
    lat: userData?.branch_location?.latitude ?? 0.0,
    lng: userData?.branch_location?.longitude ?? 0.0,
  };
  const [center, setCenter] = useState(initialCenter);
  const [sliderImages, setSliderImages] = useState([]);
  let activeSummary = null;
  const [additionalData, setAdditionalData] = useState({});

  useEffect(() => {
    const additional = userData?.additional_documents;
    const data = {};
    if (additional && Object.keys(additional).length > 0) {
      if (additional?.itr) data["ITR"] = additional?.itr;
      if (additional?.bank_statement)
        data["Bank Statement"] = additional?.bank_statement;
      if (additional?.electricity_bill)
        data["Electricity Bill"] = additional?.electricity_bill;
      if (additional?.other_documents)
        data["Other Documents"] = additional?.other_documents;
    }
    setAdditionalData(data);
    const items = [
      userData?.business_details?.business_photos?.shop_front_photo,
      userData?.business_details?.business_photos?.shop_photo_with_owner,
      userData?.co_borrower_details?.demographics
        ?.co_borrower_with_borrower_image,
    ];
    const result = [];
    items.map((item) => {
      if (item) result.push(item);
    });
    setSliderImages(result);
  }, []);

  if (
    userData?.customer_details?.transaction_summary !== null &&
    Object.keys(userData?.customer_details?.transaction_summary ?? {}).length !=
      0
  ) {
    activeSummary = "transaction";
  } else if (Object.keys(userData?.cashflow_details ?? {}).length != 0) {
    activeSummary = "cashflow";
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAFYHAEWUauYGusPSnWuRWCczjXbhseTPg",
  });

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <div class="row" style={{ paddingTop: "20px" }}>
        <div class="col-md">
          <BusinessDetails
            heading={"Business Details"}
            businessDetails={userData?.business_details?.basic_details}
            bigBox={true}
          />
        </div>
        <div class="col-md">
          <div class="switch-btn-business">
            <div>
              <div style={{ display: "flex" }}>
                <div
                  class={`col-md switch-buttons ${
                    activeTab === "photo" && "switch-selected"
                  }`}
                  onClick={() => setActiveTab("photo")}
                >
                  Business Photo
                </div>
                <div
                  class={`col-md switch-buttons ${
                    activeTab === "video" && "switch-selected"
                  }`}
                  onClick={() => setActiveTab("video")}
                >
                  Business Video
                </div>
                <div
                  class={`col-md switch-buttons ${
                    activeTab === "docs" && "switch-selected"
                  }`}
                  onClick={() => setActiveTab("docs")}
                >
                  Additional Documents
                </div>
              </div>

              <div className="sub-switch-container">
                {(() => {
                  if (activeTab === "photo") {
                    return <BusinessSlider sliderImages={sliderImages} />;
                  } else if (activeTab === "video") {
                    return (
                      <BusinessVideo
                        videoUrl={
                          userData?.business_details?.business_photos
                            ?.shop_environment_video
                        }
                      />
                    );
                  } else {
                    if (
                      userData?.business_details?.business_photos
                        ?.other_documents
                    ) {
                      return (
                        <img
                          alt="No Data"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShow()}
                          src={
                            userData?.business_details?.business_photos
                              ?.other_documents
                          }
                        />
                      );
                    }
                    return (
                      <div style={{ marginBottom: "10px" }}>
                        {Object.keys(additionalData).map((key) => (
                          <div class={`row basic-details-body`}>
                            <div class="col-md-auto basic-left-body">{key}</div>
                            <div class={`col-md basic-right-body`}>
                              <a
                                href={additionalData[key]}
                                target="_blank"
                                style={{ color: "#EE3E80" }}
                              >
                                Click Here
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" className="common-row-spc">
        <BusinessKYC businessData={userData?.business_details?.kyc_details} />
      </div>
      {activeSummary && (
        <div class="row" className="common-row-spc sub-outer-box click-prop">
          {activeSummary === "transaction" ? (
            <TransactionSummary
              transaction_data={userData?.customer_details?.transaction_summary}
            />
          ) : (
            <CashflowSummary cashflow_data={userData?.cashflow_details} />
          )}
        </div>
      )}

      <div className="row" style={{ paddingBottom: "10px" }}>
        <div className="col-md-8">
          <div className="common-row-spc">
            <RiskProfile riskDetails={riskDetails} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="common-row-spc">
            <div className="mainDiv">
              <div
                style={{
                  //styleName: H4/14PX/600;
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "17.07px",
                  letterSpacing: "0.01em",
                  textAlign: "left",
                  color: "#707070",
                }}
              >
                Business Address
              </div>
              {makerData && (
                <>
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      zoom={8}
                      center={center}
                    >
                      {makerData &&
                        makerData?.map((marker) => (
                          <Marker
                            key={marker.id}
                            position={marker?.position}
                            icon={{
                              url: marker.icons,
                              scaledSize: new window.google.maps.Size(40, 40),
                            }}
                            onMouseOver={() => setSelectedMarker(marker)}
                          >
                            {selectedMarker?.id === marker.id && (
                              <InfoWindow
                                className="custom-info-window"
                                position={marker.position}
                                onCloseClick={() => setSelectedMarker(null)} // Close InfoWindow on close button click
                              >
                                <div>
                                  <p>
                                    <strong>{marker?.title}: </strong>{" "}
                                    {marker.address}
                                  </p>
                                  {/* //<button onClick={() => handleInfoWindowClick(marker)}>More Details</button> */}
                                </div>
                              </InfoWindow>
                            )}
                          </Marker>
                        ))}
                      {center && (
                        <>
                          <Circle
                            radius={25000}
                            center={center}
                            options={{
                              strokeColor: "#2a61ff",
                              strokeOpacity: 1,
                              strokeWeight: 1,
                              fillOpacity: 0.3,
                            }}
                          />
                        </>
                      )}
                    </GoogleMap>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
      >
        <img
          src={userData?.business_details?.business_photos?.other_documents}
          alt=""
          style={{ height: "100%", width: "100%" }}
          onClick={() => handleShow()}
        />
      </Modal>
    </div>
  );
}

export default BusinessDetailsTab;
