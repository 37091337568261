import React, { useEffect, useState } from "react";
// import TenureButton from "../TenureButton/TenureButton";
import "./tenure.scss";
import axios from "axios";
import { Toaster } from "../../../../utils/utilities/Toaster";


function Tenure({ tenure, setTenure, userData, tenurelist, offerStatus }) {

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px",
        marginTop: "5px",
        height: "100%",
        width: "100%",
      }}
    >
   
        <div class="row">
          <div class="col-md-auto">Tenure(Month)</div>
          <div class="col-md-auto" style={{ marginLeft: "auto" }}>
            {offerStatus != "not_offered" ? tenure : ""}
          </div>
       
      </div>
      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
          {tenurelist?.map((ten, index) => (
            <div style={{width: "100%"}}>
              <div
                className={`tenure-btn ${ten === tenure && offerStatus != "not_offered" && "tenure-selected"}`}
                onClick={() => {
                  if ((userData?.ice_status === "In Progress" || userData?.ice_status === "On Hold") && offerStatus != "not_offered") {
                  setTenure(ten);
                  }
                }}
              >
                {ten}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tenure;