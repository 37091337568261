import './installment-bar.scss'

const InstallmentBar = (props:any) =>{

    const { due, paid}=props

    const progress = () => {
    let done = paid/(due+paid);
    return `${done*100}%`;
  }

    return(
        <>
            <span className="custom-installment-bar mt-1">
              <div className="progress-top">
              <div className="progress-done" style={{width: progress(), opacity: 1}} >
              </div>
              </div>
            </span>
        </>
    )


}

export default InstallmentBar