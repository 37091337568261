import { ApiService } from "../../services/api/apiService";
const apiService = new ApiService();

export const validatePan = (pan:string):boolean => {
  var panPattern = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
  return panPattern.test(pan);
}

export const sendCustomerLocation = () => {
  navigator.geolocation.getCurrentPosition(function(position) {
    apiService.sendCustomerLocation(position.coords.latitude.toString(), position.coords.longitude.toString()).then((response:any) => {

    }).catch((error:any) => {
      console.log('Error in sending location: ', error);
    })
  }, function(error){
    console.log('User Denied Permission of Location: ', error);
  });
}

export const assistedPartnerList = ['arth-sales-direct','arth-hlp','arth-rp-r2v2','arth-np-tide','arth-rp-aphelion','arth-cp-cms','arth-cp-cashgro'];

export const validateEmail = (email:string): boolean => {
   const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
   return pattern.test(email);
}

export const validateAadhar = (aadhar:string): boolean => {
  const regex = /^\d{12}$/;
  return regex.test(aadhar);
}

