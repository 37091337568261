import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartComponent = ({ title, chartData, type }) => {
  let names;
  let values;

  function getRandomColor(length) {
    let letters = "0123456789ABCDEF".split("");
    let result = [];
    for (let i = 0; i < length; i++) {
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      result.push(color);
    }
    return result;
  }

  if (type === "center") {
    names = chartData.center_leads_count.map(
      ({ lead_location }) => lead_location
    );
    values = chartData.center_leads_count.map(({ count }) => count);
  } else if (type === "occupation") {
    names = chartData.occupation.map(({ occupation }) => occupation);
    values = chartData.occupation.map(({ count }) => count);
  } else if (type === "customer") {
    names = Object.keys(chartData.lead_category);
    values = Object.values(chartData.lead_category);
  } else if (type === "center-approved") {
    names = Object.keys(chartData.approved_leads_center);
    values = Object.values(chartData.approved_leads_center);
  } else if (type === "gender") {
    names = Object.keys(chartData.approved_leads_gender);
    values = Object.values(chartData.approved_leads_gender);
  }
  //This is for age
  else {
    names = Object.keys(chartData.approved_leads_age);
    values = Object.values(chartData.approved_leads_age);
  }

  const colors = getRandomColor(names.length);

  const data = {
    labels: names,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      {title ? <span className="chartSubHeader">{title}</span> : <></>}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "50%", height: "50%" }}>
          <Pie data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default PieChartComponent;
