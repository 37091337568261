import { useSelector } from "react-redux";
import "./customer-profile-screen.scss";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageCard from "../../../../components/customer-application/language/LanguageCard";
import { Mixpanel } from "../../../../routing/MixPannelWrapper";
import LoanSummaryHeaderPWA from "../credits/loansummaryHeader2";

const CustomerProfile = () => {

    const displayText = useSelector((state: any) => (state.language.languageText));
    const navigate = useNavigate();
    const location = useLocation();
    const { mobile, name, email, loanData } = location.state || {};

    return (
        <>
            <LoanSummaryHeaderPWA />
            <div className="loan_details_page">
                <h6>
                    Profile
                </h6>
                <br></br>
                <div className="customer_detail">
                    <h6 style={{ margin: "4%" }}>
                        Basic Details
                    </h6>
                    <div style={{ margin: "4%", color: "#9f9d9d", fontSize: '0.95rem' }}>
                        {displayText?.name}
                        <span style={{ color: '#3e3e3e', float: 'right' }}>
                            {name || ''}
                        </span>
                    </div>
                    <div style={{ margin: "4%", color: "#9f9d9d", fontSize: '0.95rem' }}>
                        {displayText?.mobile_number}
                        <span style={{ color: '#3e3e3e', float: 'right' }}>
                            {mobile || ''}
                        </span>
                    </div>
                    <div style={{ margin: "4%", color: "#9f9d9d", fontSize: '0.95rem' }}>
                        {displayText?.email}
                        <span style={{ color: '#3e3e3e', float: 'right' }}>
                            {email || ''}
                        </span>
                    </div>
                </div>
                <br></br>
                <div className="language_detail">
                    <div>
                        <LanguageCard />
                    </div>
                </div>
                <div className="button-footer-1">
                    <button style={{ height: '3rem', width: '22rem', borderRadius: '15px', background: "transparent", border: '1px solid #FEF0E4' }} type="button" ><p style={{ marginTop: "8px", color: "#ee3e80", fontSize: "1.2rem" }} onClick={() => {
                        const customer_ucic = loanData?.loan1?.customer_code
                        Mixpanel.identify(mobile + "-" + customer_ucic);
                        Mixpanel.track('Logout', '');
                        Mixpanel.people.set({
                        $first_name: name,
                        });
                        navigate(`/arth/login`)
                    }} >Logout</p></button>
                </div>
            </div>
        </>
    )


}

export default CustomerProfile;