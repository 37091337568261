import axios, { AxiosResponse } from "axios";
import { partnerHeader } from "../base";

export class PartnerService {

  private ARTH_APP_URL: string = process.env.REACT_APP_ARTH_APP_BASE_URL;

  authenticateUserCredentials = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/auth/user/login`, payload);
  }

  logoutUser = (): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/auth/user/logout`, {}, { headers: partnerHeader() });
  }

  fetchRoleOfUser = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/auth/get_user_roles/`, { headers: partnerHeader() });
  }

  fetchUserProfile = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/auth/fetch_user_profile/`, { headers: partnerHeader() });
  }

  requestMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/mobile_otp_request/`, payload, { headers: partnerHeader() })
  }

  verifyMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/mobile_otp_verify/`, payload, { headers: partnerHeader() });
  }

  requestEmailOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/email_otp_request/`, payload, { headers: partnerHeader() });
  }

  verifyEmailOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/email_otp_verify/`, payload, { headers: partnerHeader() });
  }

  requestSignUpEmailOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/signup_email_otp_request/`, payload);
  }

  verifySignupEmailOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/signup_email_otp_verify/`, payload);
  }

  requestSignupMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/signup_mobile_otp_request/`, payload);
  }

  verifySignupMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/signup_mobile_otp_verify/`, payload);
  }

  fetchBankName = (ifscCode: string): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/get_bank_name?bank_ifs_code=${ifscCode}`, { headers: partnerHeader() });
  }

  fetchAccountHolderName = (ifscCode: string, accountNumber: string): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/get_bank_account_holder_name?bank_ifs_code=${ifscCode}&bank_account_number=${accountNumber}`, { headers: partnerHeader() });
  }

  submitBankDetails = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/bank_details/`, payload, { headers: partnerHeader() });
  }

  fetchBankDetails = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/bank_details/?partner_fk=${id}`, { headers: partnerHeader() });
  }

  submitSpocDetails = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/spoc_details/`, payload, { headers: partnerHeader() });
  }

  fetchDisbursalDetails = (id: Number): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/disbursal_limits/?partner_fk=${id}`, { headers: partnerHeader() });
  }

  submitDisbursalDetails = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/disbursal_limits/`, payload, { headers: partnerHeader() });
  }

  requestSurepassOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/request-surepass-otp/E-Aadhar/`, payload, { headers: partnerHeader() });
  }

  verifySurepassOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/verify-surepass-otp/E-Aadhar/`, payload, { headers: partnerHeader() })
  }

  fetchDigilockerUrl = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/get-redirect-digilocker/`, payload, { headers: partnerHeader() });
  }

  updateDigilockerBrowserEvent = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/browserevent/`, payload, { headers: partnerHeader() });
  }

  fetchDigilockerStatus = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/verifydigilocker/`, payload, { headers: partnerHeader() })
  }

  validateKycDoc = (payload: any, type: string): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/kyc-doc-verify/${type}`, payload, { headers: partnerHeader() });
  }

  validateUdyamNumber = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/kyc-number-verify/U-Aadhar`, payload, { headers: partnerHeader() });
  }

  validateGSTINumber = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/kyc-number-verify/GSTIN`, payload, { headers: partnerHeader() });
  }

  validatePanNumber = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/kyc-number-verify/PAN`, payload, { headers: partnerHeader() });
  }

  fetchPartnerSpocDetails = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/spoc_details/?partner_fk=${id}`, { headers: partnerHeader() });
  }

  fetchPincodeDetails = (pincode: string): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/fetch_pincode_details/?pincode=${pincode}`, { headers: partnerHeader() })
  }

  fetchPartnerList = (search: any, filter: string, pageNo: number): Promise<AxiosResponse<any>> => {
    const payload = {
      page_num: pageNo,
      records_per_page: 20,
      filters: filter,
      search: search
    }
    return axios.post(`${this.ARTH_APP_URL}/partners/info/all/`, payload, { headers: partnerHeader() });
  }

  updatePartnerInfo = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/info/`, payload, { headers: partnerHeader() });
  }

 
  getPartnerInfo = (id:number): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/info/?partner_fk=${id}`, {headers: partnerHeader()});
  }

  sendSignUpLinkToPartner = (id: number): Promise<AxiosResponse<any>> => {
    const payload = {
      'partner_id': id
    }
    return axios.post(`${this.ARTH_APP_URL}/partners/send_invite_link/`, payload, { headers: partnerHeader() });
  }

  fetchEmailIdOnSignup = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/fetch_email_id/?partner_id=${id}`);
  }

  sendSignUpOtpRequest = (email: string): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/signup_otp_request/`, { email: email });
  }

  fetchDirtyField = (id: number): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ARTH_APP_URL}/partners/qc_remarks/?partner_fk=${id}`, { headers: partnerHeader() });
  }

  fetchHlpLeads=(searchTerm:string, isComplete:boolean, partnerId:string, pageNo:number, startDate: string, endDate: string, filter_status: string): Promise<AxiosResponse<any>> =>{
    if(localStorage.getItem('currentRole') === "CentralOps" || localStorage.getItem('currentRole') === "Partnership Manager"){
      return axios.get(`${this.ARTH_APP_URL}/partners/get-partner-leads/?is_complete=${isComplete}&search=${searchTerm}&page=${pageNo}&filter_status=${filter_status}&start_date=${startDate}&end_date=${endDate}`, {headers: partnerHeader()})
    }
    else{
      return axios.get(`${this.ARTH_APP_URL}/partners/get-partner-leads/${partnerId}/?is_complete=${isComplete}&search=${searchTerm}&page=${pageNo}&filter_status=${filter_status}&start_date=${startDate}&end_date=${endDate}`, {headers: partnerHeader()})
    }
 }

 hlpCafDisposition = (payload): Promise<AxiosResponse<any>> => {
  return axios.post(`${this.ARTH_APP_URL}/sales_app/v1/bdo_hlp_tasks/`, payload, {headers: partnerHeader()})
 }  

 fetchHlpLeadsnew=( searchTerm, isComplete,  partnerId, pageNo, startDate, endDate, filter_status, centerList,productTypeValue, selectedOptions, totalperpage, searchtype, taskName, loanAmount, dpdBucket, disFromDate, disToDate, partnerlistData
 ): Promise<AxiosResponse<any>> =>{
  //console.log("gg", taskName, loanAmount, dpdBucket, disFromDate, disToDate)
  let url1 = `${this.ARTH_APP_URL}/partners/get-partner-leads/`;
   let url = {}
// Check if search is not null or undefined
if (searchTerm && searchtype === "Search") {
  url['search']=searchTerm;
}

// Check if active is true
if (dpdBucket) {
  url['dpd_days']=dpdBucket;
 
}
if (loanAmount) {
  url['loan_amount']=loanAmount;
 
}
if (partnerlistData) {
  url['partner_name']=partnerlistData;
 
}
if (disToDate) {
  url['dis_end_date']=disToDate;
}
if (disFromDate) {
  url['dis_start_date']=disFromDate;
}
if (centerList) {
  url['branch_id']=centerList;
}
if (searchTerm && searchtype === "Employee Name") {
  url['employee_name']=searchTerm;
}
// if(isComplete) {
//   url['is_complete'] =isComplete;
// }
// if (selectedOptions) {
//   url['status']=selectedOptions;
// }
if (taskName) {
  url['filter_status']=taskName;
}
if (endDate) {
  url['end_date']=endDate;
}
if (startDate) {
  url['start_date']=startDate;
}
if(pageNo){
  url['page']=pageNo;
  url['is_complete'] =isComplete;
  //url['per_page'] = 20
}
if(localStorage.getItem('currentRole') === "CentralOps" || localStorage.getItem('currentRole') === "Partnership Manager"){
  return axios.get(`${url1}`, { params: url, headers: partnerHeader()});}
else{
  return axios.get(`${url1}${partnerId}`, { params: url, headers: partnerHeader()});
}
}

  updatePartnerProfileRequests = (payload: {}): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/partners/profile_update_requests/`, payload, { headers: partnerHeader() });
  }

  fetchCustomerData=(mobile:string,partnerId:string): Promise<AxiosResponse<any>> =>{
    return axios.get(`${this.ARTH_APP_URL}/partners/customer-invoice/${partnerId}?mobile=${mobile}`, {headers: partnerHeader()})
  }

  createPartnerInvoice = (payload: {},partnerId:string): Promise<AxiosResponse<any>> => {
      return axios.post(`${this.ARTH_APP_URL}/partners/customer-invoice/${partnerId}/`, payload, { headers: partnerHeader() });
  }

  fetchInvoiceData=(invoiceId:string,partnerId:string): Promise<AxiosResponse<any>> =>{
      return axios.get(`${this.ARTH_APP_URL}/partners/get-invoice-details/${partnerId}/?search=${invoiceId}`, {headers: partnerHeader()})
  }

  fetchInvoices=(partnerId:string,search:string,pageNo:number): Promise<AxiosResponse<any>> =>{
      return axios.get(`${this.ARTH_APP_URL}/partners/get-invoice-details/${partnerId}/?search=${search}&page=${pageNo}`, {headers: partnerHeader()})
  }

  generateInvoicePdf=(invoiceId:string): Promise<AxiosResponse<any>> =>{
      return axios.get(`${this.ARTH_APP_URL}/partners/generate-invoice-pdf/${invoiceId}`, {headers: partnerHeader()})
  }
  getPartnerNameList=(): Promise<AxiosResponse<any>> =>{
    return axios.get(`${this.ARTH_APP_URL}/partners/partners-listing/`, {headers: partnerHeader()})
}



}