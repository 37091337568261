import "./installment-bar.scss";

const EDIInstallmentBar = (props: any) => {
  const { due, paid } = props;
  const progress = () => {
    let done = parseInt(paid) / (parseInt(due) + parseInt(paid));
    return `${done * 100}%`;
  };

  const percentagePaid = () => {
    let percent = Math.round((parseInt(paid) / (parseInt(due) + parseInt(paid))) * 100);
    return `${percent}%`;
  };

  return (
    <>
      <span className="custom-installment-bar mt-1">
        <div className="progress-top2">
          <div
            className="progress-done2"
            style={{ width: progress(), opacity: 1 }}
          >
            <div style={{padding:'8px', color:'white'}}>{percentagePaid()}</div>
          </div>
        </div>
      </span>
    </>
  );
};

export default EDIInstallmentBar;
