
function Status({ status }) {

    let button_style = { backgroundColor: "#FBEBEC", color: "#DC3545", width: "120px"}
    
  if (status === "In Progress") {
    button_style = { backgroundColor: "#FEF7E6", color: "#F1B300", width: "120px", }
  } else if (status === "Accepted") {
    status = "Approved"
    button_style={ backgroundColor: "#E7F8EE", color: "#10B759", width: "120px" }
  }

  return (
    <>
      <button
        type="button"
        disabled
        className="btn btn-sm"
        style={button_style}
      >
        {status}
      </button>
    </>
  );
}

export default Status;
