import { huntingHeader } from "../base";
import axios, {AxiosResponse} from "axios";

export class HuntingService{
  private LMS_URL:string = process.env.REACT_APP_LMS_URL;

  authentuicateHunter = (payload:any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.LMS_URL}/hotleadapi/hunting/v1/login/`, payload);
  }

  getHuntedDataList = (id:any, form_id:any, pageNumber: number): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.LMS_URL}/hotleadapi/hunting/v1/list?id=${id}&form_id=${form_id}&page_number=${pageNumber}&per_page=5`, {headers: huntingHeader()})
  }

  submitHuntingForm = (payload:any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.LMS_URL}/hotleadapi/hunting/v1/create_form/`, payload, {headers: huntingHeader()});
  }

  updateWhiteListing = (payload:any): Promise<AxiosResponse<any>> => {
    return axios.patch(`${this.LMS_URL}/hotleadapi/hunting/v1/update/`, payload, {headers: huntingHeader()})
  }

  sendShopVideo = (formData:any): Promise<AxiosResponse<any>> => {
    const headers = {
      'Content-Type': 'multipart/form-data' // Adjust content type if needed
      // Add any other headers as needed
    };
    return axios.patch(`${this.LMS_URL}/hotleadapi/hunting/v1/upload_video/`, formData, {headers});
  }
 
}


