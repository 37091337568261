import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./charts.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartComponent = ({ title, chartData }) => {

  const names = ["Approved", "On Hold", "Rejected", "Auto-Rejected"]
  const obj = chartData.decision_taken
  const values = [obj["Accepted"], obj["On Hold"], obj["Rejected"], obj["Auto Rejected"]]

  

  const colors = ["#10B759", "#F1B300", "#DC3545", "#DC3545"];

  const data = {
    labels: names,
    datasets: [
      {
        label: "Net Profit",
        data: values,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <h1>{title}</h1>
      <Bar options={options} data={data} className="chartPosn" />
    </>
  );
};

export default BarChartComponent;
