import { combineReducers } from "redux";
import { languageReducer } from "./customer-application/languageReducer";
import { cameraReducer } from "./customer-application/cameraReducer";
import { OfferReducer, AllUserProfileReducer, LoanDetailsReducer, UserProfileReducer, JourneyStepsReducer, CurrentActionReducer, LenderTermsAndCondition, LoaderState } from "./customer-application/offerReducer";
import { CustomerDetailsLOSReducer } from "./customer-application/enachReducer";
import { UserAuth, currentRole, dirtyFields } from "./partner/userReducer";
import { PartnerInfo } from "./partner/onboardingReducer";

const rootReducer = combineReducers({
  language: languageReducer,
  camera: cameraReducer,
  offer: OfferReducer,
  profile: UserProfileReducer,
  allProfile: AllUserProfileReducer,
  loan: LoanDetailsReducer,
  journey_list: JourneyStepsReducer,
  current_action: CurrentActionReducer,
  los_details: CustomerDetailsLOSReducer,
  lender_tnc: LenderTermsAndCondition,
  loader: LoaderState,
  userRoles: UserAuth,
  currentRole: currentRole,
  partnerInfo: PartnerInfo,
  dirtyFields: dirtyFields
})

export default rootReducer;