import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './hunting-qc.scss';
import { ArthLogo } from '../../../assets/svg';
import { Toaster } from '../../../utils/utilities/Toaster';
import { HuntingService } from '../../../services/api/huntingService';
import Dropdown from '../../../components/partner-application/dropdown/Dropdown';
import TextInput from '../../../components/partner-application/text-input/TextInput';

const HuntingQC = () => {

  const navigate = useNavigate();
  const toaster = new Toaster();
  const huntingService = new HuntingService();
  const {formId} = useParams();
  const id = localStorage.getItem('hunterId');
  const role = localStorage.getItem('hunterRole');
  const [data, setData] = useState<any>('');
  const [tag, setTag] = useState<string>('');
  const [tagReason, setTagReason] = useState<string>('');
  const [showEror, setShowError] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const productMapping = {
    "small_business_loan": "Small Ticket Loan",
    "high_value_business_loan": "High Ticket Loan",
    "affordable_housing_loan": "Affordable Housing Loan",
    "insurance":"Insurance",
    "micro_investment":"Investment (12% Return)",
    "account_opening": "Account Opening (Tide)",
    "arth_payment": "Arth P@ymint",
    "not_interested": "Not Interested",
  }

  useEffect(() => {
    if(!localStorage.getItem('hunterRole') || !localStorage.getItem('token')){
      localStorage.clear();
      navigate('/hunt/login');
    } else {
      fetchCurrentData();
    }
  }, [])

  const fetchCurrentData = () => {
    huntingService.getHuntedDataList(id,formId, 1).then((response:any) => {
      setData(response?.data?.results[0]);
      searchSelectedProduct(response?.data?.results[0]);
    }).catch((error:any) => {
      toaster.show(true, 'Unable To Fetch Details');
    })
  }

  const searchSelectedProduct = (obj:any) => {
    const arr = Object.keys(productMapping).forEach((key) => {
      if(obj[`${key}`]){
        setSelectedProducts((prev:any) => ([...prev, productMapping[`${key}`]]))
      }
    })
  }

  const handleWhiteListing = (action:string) => {
    return () => {
      const text = action === 'A' ? 'Approve' : 'Reject';
      if(tag){
        setShowError(false);
        toaster.confirmation(text).then((result:any) => {
          if(result.isConfirmed){
            const payload = {
              "id": id,
              "form_id": formId,
              "status": action === 'A' ? "ACCEPTED" : "REJECTED_BY_ARTH",
              "qc_status": tag,
              "qc_remark": tagReason
            }
  
            huntingService.updateWhiteListing(payload).then((response:any) => {
              navigate('/hunt/list');
            }).catch((error:any) => {
              toaster.show(true, 'Unable To Update');
            })
          } else {
            toaster.show(true, 'Cancelled');
          }
        })
      } else {
        setShowError(true);
      }
    }
  }

  return (
    <div className='hunting-qc'>
      <div className="d-flex justify-content-center sticky-style">
        <div className='form-logo'>
          <ArthLogo />
          <span>Empower</span>
        </div>
      </div>
      <div style={{marginTop: "65px"}} className='p-4'>
      <div className="row mb-4">
        <div className="col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className='hunting-data'>
                <div className='key'>Center Name</div>
                <div>{data?.center_name}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Owner Name</div>
                <div>{data?.shop_owner_name}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Owner Mobile Number</div>
                <div>{data?.shop_owner_mobile_number}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Owner DOB</div>
                <div>{data?.shop_owner_dob}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Owner Gender</div>
                <div>{data?.gender}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>WhatsApp Number</div>
                <div>{data?.whatsapp_number}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Owner Email</div>
                <div>{data?.shop_owner_email_id}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Owner PAN Number</div>
                <div>{data?.high_value_business_loan_details?.panNumber || data?.small_business_loan_details?.panNumber}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div>Shop Outside Photo</div>
              <div>
                <img src={data?.shop_outside_photo_presigned} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className='hunting-data'>
                <div className='key'>Payment Wallet Name</div>
                <div>{data?.payment_wallet_name}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Bank Name</div>
                <div>{data?.bank_name}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Selected Products</div>
                <div>{selectedProducts.join(", ")}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Not Interested Remarks</div>
                <div>{data?.not_interested_remarks}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Occupation Category</div>
                <div>{data?.occupation_category}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Distributor Name</div>
                <div>{data?.distibutor_name}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Distributor Mobile Number</div>
                <div>{data?.distibutor_mobile}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Distributor Occupation Category</div>
                <div>{data?.distributor_occupation_category}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Distributor Occupation</div>
                <div>{data?.distibutor_occupation}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Distributor Bussiness Name</div>
                <div>{data?.distributor_business_name}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Distributor Location</div>
                <div>{data?.distributor_location}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div>Shop Inside Photo</div>
              <div>
                <img src={data?.shop_photo_presigned} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
            <div className='hunting-data'>
                <div className='key'>Shop Address</div>
                <div>{data?.shop_address}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>District</div>
                <div>{data?.district}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Pincode</div>
                <div>{data?.pincode}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Name</div>
                <div>{data?.shop_name}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Type</div>
                <div>{data?.shop_type}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Bussiness Started Month/Year</div>
                <div>{data?.business_started_date}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Shop Size</div>
                <div>{data?.shop_size}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>House Type</div>
                <div>{data?.house_type}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div>Shop Photo With Owner</div>
              <div>
                <img src={data?.selfie_with_owner_presigned} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
            <div className='hunting-data'>
                <div className='key'>Type Of Residence</div>
                <div>{data?.high_value_business_loan_details?.type_of_residance}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Mother Name</div>
                <div>{data?.insurance_details?.mother_name}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Residance Address</div>
                <div>{data?.smalll_business_loan?.residence_address}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Residance City</div>
                <div></div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Residence Pincode</div>
                <div></div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Others</div>
                <div></div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Type Of Loan</div>
                <div>{data?.affordable_housing_loan?.type_of_loan}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Required Loan Amount</div>
                <div>{data?.small_business_loan_details?.required_loan_amount || data?.high_value_business_loan_details?.required_loan_amount || data?.affordable_housing_loan_details?.required_loan_amount}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>GSTIN Number</div>
                <div>{data?.arth_payment_details?.gstinNumber || data?.high_value_business_loan_details?.gstinNumber}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>Is Payment Done</div>
                <div>{data?.payment_status}</div>
              </div>
              <div className='hunting-data'>
                <div className='key'>ITR Year</div>
                <div>{data?.high_value_business_loan_details?.itr_year}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div>Shop Video</div>
              <div>
                <video className='video-container' src={data?.shop_video_presigned} controls></video>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {role === 'QC' && <div className='d-flex justify-content-around mb-4'>
        <Dropdown
          heading={'Tag'}
          list={['good', 'average', 'bad']}
          value={tag}
          handleInput={(key:string, value:string) => {setTag(value)}}
          supportiveTextRed={showEror && 'Please Select one'}
        />
        <TextInput
          heading={'Tag Reason'}
          value={tagReason}
          handleInput={(key:string, value:string) => {setTagReason(value)}}
        />
      </div>}
      {role === 'QC' && <div className="hunting-qc-button">
        <button className="btn btn-success" onClick={handleWhiteListing('A')}>Approve</button>
        <button className="btn btn-warning" onClick={handleWhiteListing('R')}>Reject</button>
      </div>}
    </div>
  )
}

export default HuntingQC;
