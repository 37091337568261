import "./filter-component.scss";

function FilterComponent({ type, setApplied, data, setData, setState }) {

  const changeState = (value) => {
    const val = data[value] ? false : true;
    setData({ ...data, [value]: val });
  };


  const perChunk = 2; // items per chunk

  const newData = Object.keys(data);
  const result = newData.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <div>
      <div className="popover-head">Select {type} Type</div>
      <div>
        {result.map((row, index) => (
          <div className="new-row">
            {row.map((newRow, index) => (
              <div
                className={`pill ${data[newRow] && "pill-select"}`}
                onClick={() => changeState(newRow)}
              >
                {newRow}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="popover-foot">*You can choose multiple {type} type</div>
      <div className="center-container">
        <div
          className="apply-button"
          onClick={() => {
            
            setApplied(true);
            setState(data)

          }}
        >
          APPLY
        </div>
      </div>
    </div>
  );
}

export default FilterComponent;
