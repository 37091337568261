import React from 'react';
import { PrevButtonSVG } from '../../../assets/svg';
import './button.scss';

const PrevButton = ({onPrevClick}) => {

  return (
    <div className='prev-button' onClick={onPrevClick}>
      <PrevButtonSVG />
    </div>
  )
}

export default PrevButton;
