import { useEffect, useState } from 'react';
import { WhiteCheck } from '../../../assets/svg';
import './radio-input.scss';
import { useDispatch } from 'react-redux';
import { changeLanguage } from '../../../store/actions/customer-application/languageAction';

const RadioInput = ({value, selectedValue, setSelectedValue}:any) => {

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleChange = () => {
    localStorage.setItem('lang', value);
    setSelectedValue(value);
    dispatch(changeLanguage(value));
  }

  useEffect(() => {
    setIsSelected(selectedValue === value);
  },[selectedValue])

  return (
    <div className='radio-input'>
      <input type="radio" value={value} onChange={handleChange} checked={selectedValue === value}/>
      {isSelected ? <div className="checked-color">
        <WhiteCheck className='checked-img'/>
      </div> : <></>}
    </div>
  )
}

export default RadioInput;
