const initialState = {
  aadharFrontUrl: null,
  aadharBackUrl: null,
  aadharFrontBase64: null,
  aadharBackBase64: null,
  selfie: null
}

export const cameraReducer = (state = initialState, action) => {
  switch(action.type){
    case 'UPLOAD_AADHAR_FRONT':
      return {
        ...state,
        aadharFrontUrl: action.payload.imageUri,
        aadharFrontBase64: action.payload.base64Image
      }
    case 'UPLOAD_AADHAR_BACK':
      return {
        ...state,
        aadharBackUrl: action.payload.imageUri,
        aadharBackBase64: action.payload.base64Image
      }
    default:
      return state
  }
}