import "./offer-decision.scss";

import OfferSlider from "../Slider/OfferSlider";
import Tenure from "../../customers/Tenure/Tenure";
import { useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import { InfoIcon } from "../../../../assets/svg";

function OfferDecision({
  tenure,
  amount,
  setAmount,
  setTenure,
  riskScore,
  setProductCode,
  userData,
  emi,
  setEmi,
  interestRate,
  setInterestRate,
  processingFee,
  setProcessingFee,
  tenurelist,
  offerStatus,
}) {
  const [initialRender, setRender] = useState(true);

  useEffect(() => {
    if (!amount) {
      return;
    }
    if (initialRender) {
      setRender(false);
      return;
    }
    fetchUpdatedData();
  }, [amount, tenure]);

  const fetchUpdatedData = async () => {
    const requestOptions = {
      headers: { Authorization: process.env.REACT_APP_ICE_AUTHORIZATION },
    };

    let url = new URL(
      process.env.REACT_APP_ICE_BASE_URL +
        "/offer-update/" +
        userData?.basic_details?.id +
        "/" +
        amount +
        "/" +
        tenure
    );

    const response = await fetch(url, requestOptions);

    const data = await response.json();

    const fetchedData = data;
    setInterestRate(fetchedData?.["monthly_interest"]);
    setProcessingFee(fetchedData?.["processing_fees"]);
    setEmi(fetchedData?.["equated_installment"]);
    setProductCode(fetchedData?.["product_code"]);
  };

  let nf = new Intl.NumberFormat();

  const rows = [
    {
      name: "Processing Fee",
      value:
        processingFee && offerStatus != "not_offered" ? processingFee + "%" : 0,
    },
    {
      name: "Interest Rate",
      value:
        interestRate && offerStatus != "not_offered" ? interestRate + "%" : 0,
    },
    {
      name: "EMI",
      value: emi && offerStatus != "not_offered" ? "₹" + nf.format(emi) : 0,
    },
  ];

  function getMargin() {
    if (riskScore === 500) {
      return {};
    }
    const halfWidth = 250;
    if (riskScore <= 500) {
      const calc = halfWidth - Math.floor(halfWidth / (500 / riskScore));
      return { marginRight: calc };
    }
    const calc = Math.floor(halfWidth / (500 / (riskScore / 2)));
    return { marginLeft: calc };
  }

  function getDot() {
    const styling = {
      padding: 2,
      position: "absolute",
      backgroundColor: "black",
      borderRadius: "50%",
      width: 1,
      height: 1,
      marginTop: "10px",
    };

    const margin = getMargin();
    if (Object.keys(margin).length === 0) {
      return styling;
    }
    styling[Object.keys(margin)[0]] = Object.values(margin)[0];
    return styling;
  }

  return (
    <div>
      <div style={{ display: "flex", gap: "10px" }}>
        <div class="col-9">
          <div className="common-top-margin hdr-style">Offer Decision</div>
          <div class="row">
            <div class="col-6">
              <OfferSlider
                amount={amount}
                setAmount={setAmount}
                defaultAmount={
                  userData?.offered_details?.offered_amount ??
                  userData?.basic_details?.default_amount
                }
                userData={userData}
                offerStatus={offerStatus}
              />
            </div>
            <div class="col-6">
              <Tenure
                tenure={tenure}
                setTenure={setTenure}
                tenurelist={tenurelist}
                setProductCode={setProductCode}
                userData={userData}
                amount={amount}
                setAmount={setAmount}
                offerStatus={offerStatus}
              />
            </div>
            <div
              class="row"
              style={{
                marginTop: "20px",
                gap: "15px",
                marginLeft: "2px",
              }}
            >
              {rows.map((key) => (
                <div className="col-md box-format">
                  <span className="box-top-text">{key.value}</span>
                  <span className="box-bottom-text">{key.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div class="col-3">
          <div className="risk-score-container">
            <span className="hdr-style">Risk Score</span>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <ReactSpeedometer
                height={130}
                width={200}
                segments={3}
                segmentColors={["red", "orange", "green"]}
                value={riskScore}
                maxValue={1000}
                customSegmentStops={[0, 333, 666, 1000]}
                ringWidth={10}
                needleColor="black"
                labelFontSize={0}
                valueTextFontSize={"24px"}
                textColor={"black"}
                valueTextFontWeight={200}
              />
            </div>

            <div
              style={{
                marginTop: "10px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {(() => {
                if (riskScore <= 333) {
                  return <div style={{ color: "red" }}>Bad</div>;
                } else if (riskScore > 333 && riskScore <= 666) {
                  return <div style={{ color: "orange" }}>Average</div>;
                } else {
                  return <div style={{ color: "green" }}>Good</div>;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferDecision;
