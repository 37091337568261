import {
  BlueCircle,
  PinkCircle,
  ZigZagPinkCircle,
  ArthLogo,
  LanguageChange,
  BackButton,
} from "../../../../assets/svg";
import "./loan-summary-header.scss";
import * as reactRouterDom from "react-router-dom";

const LoanSummaryHeaderPWA = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div>
      <div className="blurrimages" style={{ overflow: "hidden" }}>
        <BlueCircle className="bluecircle" />
        <PinkCircle className="pinkcircle" />
        <ZigZagPinkCircle className="zigzag" />
        <span className="purplecircle"></span>
      </div>
      <div className="blurrdiv">
        <div className="containers">
          <div className="back" onClick={goBack}>
            <BackButton />
          </div>
          <div className="arthlogo">
            <ArthLogo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanSummaryHeaderPWA;
