import { useEffect, useState } from 'react';
import TextInput from '../../../components/partner-application/text-input/TextInput';
import MultiSelect from '../../../components/partner-application/dropdown/MultiSelect';
import Dropdown from '../../../components/partner-application/dropdown/Dropdown';
import RadioInput from '../../../components/partner-application/radio-input/RadioInput';
import './hunting-form.scss';
import FilePicker from '../../../components/partner-application/file-picker/FilePicker';
import { useNavigate } from 'react-router-dom';
import { HuntingService } from '../../../services/api/huntingService';
import { ArthLogo } from '../../../assets/svg';
import { Toaster } from '../../../utils/utilities/Toaster';
import AudioRecorder from '../../../components/partner-application/audio-recorder/AudioRecorder';
import QRCode from '../../../assets/images/qr-code.jpg';
import { LoaderService } from '../../../utils/utilities/loaderService';
import VideoRecorder from '../../../components/partner-application/video-recorder/VideoRecorder';
import { Button } from '@mui/material';

const HuntingForm = () => {

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [isUserInterested, setIsUserInterested] = useState<boolean>(true);
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(true);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [gstinNumber, setGstinNumber] = useState<any>('');
  const [panNumber, setPanNumber] = useState<any>('');
  const navigate = useNavigate();
  const huntingService = new HuntingService();
  const toaster = new Toaster();
  const loader = new LoaderService();
  const [videoFormData, setVideoFormData] = useState<any>('');
  const [centerName, setCenterName]=useState<any>('');
  const [form, setForm] = useState<any>({
    center_name: '',
    shop_owner_name: '',
    owner_mobile_number: '',
    active_atm_card: '',
    owner_dob: '',
    gender: '',
    whatsapp_number: '',
    owner_email_id: '',
    shop_address: '',
    shop_district: '',
    shop_pincode: '',
    shop_name: '',
    Shop_type: '',
    house_type: '',
    bussiness_start_date: '',
    shop_size: '',
    payment_wallet_name: '',
    bank_name: '',
    occupation_catagory: '',
    shop_outside_photo: '',
    shop_inside_photo: '',
    shop_photo_with_owner: '',
    is_payment_done: '',
    tide_product_completion: '',
    rupee_product_completion: '',
    lat: '',
    long: ''
  });
  const [housingLoanForm, setHousingLoanForm] = useState<any>({
    type_of_loan: '',
    required_loan_amount: '',
    property_value: '',
    property_pincode: ''
  })

  const [stlForm, setStlForm] = useState<any>({
    required_loan_amount: '',
  })

  const [htlForm, setHtlForm] = useState<any>({
    itr_year: '',
    type_of_residance: '',
    required_loan_amount: '',
    bank_statement: ''
  })

  const [insuranceForm, setInsuranceForm] = useState<any>({
    mother_name: ''
  })

  const [residenceForm, setResidanceForm] = useState<any>({
    residence_address: '',
    residence_city: '',
    residance_pincode: ''
  })

  const productType = ['High Ticket Loan', 'Small Ticket Loan', 'Affordable Housing Loan', 'Arth P@ymint', 'Account Opening (Tide)', 'Investment (12% Return)', 'Insurance', 'Not Interested']
  const housingLoan = ['Type Of Loan', 'Property value', 'Property pincode'];
  const investment = [];
  const arthPayment = ['GSTIN Number'];
  const stl = ['PAN'];
  const htl = ['GSTIN Number', '2 Year ITR', 'PAN', '1 Year Bank Statement'];
  const insurance = ['Mother Name', 'Residence Details'];
  const centerNames = [
    "Ayodhya-Manas Nagar",
    "Ayodhya-Hansapur",
    "Ayodhya-Maushiwala",
    "Ayodhya-Paigapur",
    "Ayodhya-Rikabganj",
    "Ayodhya-Sahadatganj",
    "Ayodhya-Abboo Sarai",
    "Ayodhya-Fatehganj",
    "Ayodhya-RTO Office",
    "Ayodhya-Rampur",
    "Ayodhya-Darshan Nagar",
    "Ayodhya-Chowk Ayodhaya Road",
    "Ayodhya-Tedhi Bazar",
    "Ayodhya-Achari Ka Sagra",
    "Ayodhya-Ayodhya",
    "Ayodhya-Banbirpur",
    "Ayodhya-Ghatam Pur",
    "Ayodhya-Jagdishpur",
    "Ayodhya-Ballipur",
    "Ayodhya-Tajpur Kodara",
    "Ayodhya-Devkali",
    "Ayodhya-Katra",
    "Ayodhya-Kot Saray",
    "Ayodhya-Faizabad Cant",
    "Ayodhya-Gopalpur",
    "Ayodhya-Bichhiya",
    "Ayodhya-Rani Bajar",
    "Ayodhya-Ramghat",
    "Ayodhya-Wazeerganj", 
    "Ayodhya-Paliagoa", 
    "Ayodhya-Bhadarsa", 
    "Ayodhya-Bharatkund, Bhadarsha",
    "Ayodhya-Pura Baazar", 
    "Ayodhya-Bikapur", 
    "Ayodhya-Sohawal", 
    "Ayodhya-Sanjayganj Bazar", 
    "Ayodhya-Vikramjot", 
    "Ayodhya-Rampur Bhagan",
    "Ayodhya-Maya Baazar", 
    "Ayodhya-Nawabganj", 
    "Ayodhya-Chhawani", 
    "Ayodhya-Tarabganj",
    "Sultanpur-Vallipur Bazar",
    "Sultanpur-Kasba Sultanpur",
    "Sultanpur-Majar Ganj",
    "Sultanpur-Ratan Pur",
    "Sultanpur-Ghabadiya",
    "Sultanpur-Vinowapuri",
    "Sultanpur-Lohramau",
    "Sultanpur-Pyarepatti",
    "Sultanpur-Shastri Nagar",
    "Sultanpur-Vivek Nagar",
    "Sultanpur-Gorabarik",
    "Sultanpur-Amhat",
    "Sultanpur-Saifulla Ganj",
    "Sultanpur-Payagipur",
    "Sultanpur-Murlinagar",
    "Sultanpur-Tedhui",
    "Sultanpur-Amhat Chauraha",
    "Sultanpur-Denva",
    "Sultanpur-Bankepur",
    "Sultanpur-Ahimane",
    "Sultanpur-Katka Khan Pur",
    "Sultanpur-Rampur Hanuman Ganj",
    "Sultanpur-Dwarikaganj",
    "Sultanpur-Bandhuakala",
    "Sultanpur-Dhammaur",
    "Bhiwadi-Nagliya",
    "Bhiwadi-Central Market",
    "Bhiwadi-Vikash Nagar",
    "Bhiwadi-Karan Kunj",
    "Bhiwadi-Sector 4, UIT",
    "Bhiwadi-Mansa Chowk, UIT",
    "Bhiwadi-Phool Bhag",
    "Bhiwadi-Alampur, UIT",
    "Bhiwadi-Block B, UIT",
    "Bhiwadi-Sector 2A, UIT",
    "Bhiwadi-Sector 3, UIT",
    "Bhiwadi-Saidpur",
    "Bhiwadi-Sector 5, UIT",
    "Bhiwadi-Sector 6, UIT",
    "Bhiwadi-Akera",
    "Bhiwadi-Santhalka",
    "Bhiwadi-Harchandpur",
    "Bhiwadi-Sector 9, UIT",
    "Bhiwadi-Honda Chowk",
    "Bhiwadi-Dharuhera",
    "Bhiwadi-Garhi Alawalpur",
    "Bhiwadi-Suraj Cinema",
    "Bhiwadi-Alwar By Pass Market",
    "Bhiwadi-Khori Kalan",
    "Bhiwadi-Phase 3, RIICO",
    "Bhiwadi-Khijuriwas",
    "Bhiwadi-Kapriwas",
    "Bhiwadi-Bilaheri",
    "Bhiwadi-Matila",
    "Bhiwadi-Malpura",
    "Bhiwadi-Tauru",
    "Bhiwadi-Chaupanki",
    "Bhiwadi-Tapukara",
    "Bhiwadi-Bilaspur Market",
    "Pataudi-Haileymandi",
    "Pataudi-Ward Number 8, Haileymandi",
    "Pataudi-Mirzapur",
    "Pataudi-Bus Stand, Pataudi",
    "Pataudi-Nohata Chowk",
    "Pataudi-Chhota Bazar, Pataudi",
    "Pataudi-Rampur",
    "Pataudi-Milakpur",
    "Pataudi-Luhari",
    "Pataudi-Janaula",
    "Pataudi-Uncha Majra",
    "Pataudi-Narhera",
    "Pataudi-Jori", 
    "Pataudi-Khandewla", 
    "Pataudi-Jatola", 
    "Pataudi-Mehchana", 
    "Pataudi-Sanpka", 
    "Pataudi-Basunda", 
    "Pataudi-Bhora Kalan", 
    "Pataudi-Farukh Nagar", 
    "Pataudi-Kulana", 
    "Pataudi-Panchgaon",
    "Delhi-Patel Nagar",
    "Delhi-Delhi Cant",
    "Delhi-Inderpuri",
    "Delhi-Kirti Nagar",
    "Delhi-Tilak Nagar",
    "Delhi-Moti Bagh",
    "Delhi-RK Puram",
    "Delhi-Sarojini Nagar",
    "Delhi-Punjabi Bagh",
    "Delhi-Tagore Garden",
    "Delhi-Naraina",
    "Delhi-Safdarjung Enclave",
    "Delhi-Pitampura",
    "Delhi-Keshav Puram",
    "Delhi-Mahipalpur",
    "Delhi-Mahavir Enclave",
    "Delhi-Dabri",
    "Delhi-Ashok Vihar",
    "Delhi-Vasant Vihar",
    "Delhi-Janak Puri",
    "Delhi-Uttam Nagar",
    "Delhi-Rajinder Nagar",
    "Delhi-Paschim Vihar",
    "Delhi-Hari Nagar",
    "Delhi-RK Puram",
    "Delhi-Qutub Vihar",
    "Delhi-Dwarka",
    "Delhi-Dwarka, Sec-8",
    "Delhi-Dwarka, Sec-14",
    "Delhi-Dwarka, Sec-4",
    "Delhi-Paschim Vihar",
    "Delhi-Shalimar Bagh",
    "Delhi-Sadar Bazaar, Delhi Cant", 
    "Delhi-Vikas Puri",
    "Delhi-Fateh Nagar",
    "Delhi-Vishnu Garden", 
    "Delhi-Khayala", 
    "Delhi-Chander Vihar", 
    "Delhi-Raghubir Nagar", 
    "Delhi-Subhash Nagar", 
    "Delhi-Rajouri Garden", 
    "Delhi-Delhi Gate, Najafgarh", 
    "Delhi-Vijay Enclave", 
    "Delhi-Sadh Nagar", 
    "Delhi-Mangla Puri", 
    "Delhi-Kailash Puri",
    "Delhi-Sagar Pur", 
    "Delhi-Nangal Raya", 
    "Delhi-Possangipur, Janak Puri", 
    "Delhi-Shiv Vihar, Janak Puri", 
    "Delhi-Lajwanti Garden", 
    "Delhi-Bindapur, Uttam Nagar", 
    "Delhi-Milap Nagar, Uttam Nagar", 
    "Delhi-Madhu Vihar, Uttam Nagar", 
    "Delhi-Om Vihar, Uttam Nagar", 
    "Delhi-Mohan Garden, Uttam Nagar", 
    "Delhi-Rama Park, Uttam Nagar", 
    "Delhi-Nawada, Uttam Nagar", 
    "Delhi-Sewak Park, Uttam Nagar", 
    "Delhi-Vikas Nagar, Uttam Nagar", 
    "Delhi-Madipur", 
    "Delhi-Jwala Heri, Paschim Vihar", 
    "Delhi-Maya Puri", 
    "Delhi-Palam", 
    "Delhi-Dwarka, Sec-15", 
    "Delhi-Peera Garhi",
    "Madiyaon-Yadav Market",
    "Madiyaon-Raitha Road",
    "Madiyaon-Jankipuram Ext",
    "Madiyaon-Bakshi Ka Talab",
    "Madiyaon-Engineering College Road",
    "Madiyaon-Jankipuram 60Ft Road",
    "Madiyaon-Aliganj",
    "Madiyaon-Tedhi Pulia",
    "Madiyaon-Adil Nagar",
    "Madiyaon-Faizullaganj",
    "Madiyaon-Puraniya",
    "Madiyaon-Hayat Nagar",
    "Madiyaon-Kalyanpur",
    "Madiyaon-Kapurthala",
    "Madiyaon-Vikas Nagar",
    "Madiyaon-Adarsh Nagar",
    "Madiyaon-Kalyanpur West",
    "Madiyaon-Kalyanpur East",
    "Madiyaon-Gaurabhagh",
    "Madiyaon-Dubagga",
    "Madiyaon-Mahanagar",
    "Madiyaon-Hari Nagar",
    "Madiyaon-Daliganj",
    "Madiyaon-Picnic Spot Road",
    "Madiyaon-Assi Road, Bakshi Ka Talab",
    "Madiyaon-Badshahnagar",
    "Madiyaon-Balaganj",
    "Madiyaon-Daulataganj",
    "Madiyaon-Nishatganj",
    "Madiyaon-Kursi Road",
    "Madiyaon-Panna Lal Road", 
    "Madiyaon-Faridi Nagar", 
    "Madiyaon-Indira Nagar", 
    "Madiyaon-Munshi Pulia", 
    "Madiyaon-Bhootnath Market", 
    "Madiyaon-Takrohi", 
    "Madiyaon-Aminabad", 
    "Madiyaon-Hazratganj", 
    "Madiyaon-Amrai Gaon", 
    "Madiyaon-Charbagh", 
    "Madiyaon-Chinhat", 
    "Madiyaon-Gomti Nagar",
    "Madiyaon-Matiyari", 
    "Madiyaon-Gomtinagar Extension", 
    "Madiyaon-Bangla Bazar", 
    "Madiyaon-Sadar Bazaar", 
    "Madiyaon-Telibagh", 
    "Madiyaon-Nilmatha", 
    "Madiyaon-Alambagh", 
    "Madiyaon-Ashiyana", 
    "Madiyaon-Vrindavan Colony", 
    "Madiyaon-Mati",
    "Bachhrawan-Bachrawan",
    "Bachhrawan-Kundanganj",
    "Bachhrawan-Tilenda",
    "Raebareli-Bharat Ganj",
    "Raebareli-Feroz Gandhi Market",
    "Raebareli-Gora Bazar",
    "Raebareli-Bargad Chauraha",
    "Raebareli-Krishna Nagar",
    "Raebareli-Nahar Kothi",
    "Raebareli-Munshiganj",
    "Raebareli-Rana Nagar",
    "Raebareli-Jagdishpur",
    "Raebareli-Habibullanagar",
    "Raebareli-Ratapur",
    "Raebareli-Ratapur-Khaspari",
    "Raebareli-Alimiya Market (Sabji Mandi)",
    "Raebareli-Bus Stop",
    "Raebareli-Degree College Chauraha",
    "Raebareli-Ghanta Ghar",
    "Raebareli-Kaiparganj",
    "Raebareli-Madhuban",
    "Raebareli-Police Line",
    "Raebareli-Prashad Market",
    "Raebareli-Shri Shyam Market",
    "Raebareli-Super Market",
    "Raebareli-Rahi",
    "Raebareli-PNT Colony",
    "Raebareli-Dariyapur",
    "Raebareli-Dareeba",
    "Raebareli-Ahiya Raipur",
    "Raebareli-Kuchariya",
    "Raebareli-Bhadokhar-Suchi",
    "Raebareli-Hardaspur",
    "Raebareli-Ganga Ganj",
    "Raebareli-Kanpur Road ",
    "Raebareli-Kachaunda _Nanakari",
    "Raebareli-Kahron ka Adda",
    "Raebareli-Malikmau",
    "Raebareli-ITI Balapur",
    "Raebareli-Pragtipuram",
    "Raebareli-Kila bazar",
    "Raebareli-Harchandpur",
    "Raebareli-Gangaganj",
    "Raebareli-Odaree",
    "Raebareli-Jais",
    "Raebareli-Thulwasa",
    "Raebareli-Maharajganj",
    "Raebareli-Amawan",
    "Raebareli-Ratapur-Khaspari",
    "Raebareli-Bhadokhar-Suchi",
    "Raebareli-Kanpur Road", 
    "Raebareli-Bhadokhar", 
    "Raebareli-Gangaganj", 
    "Raebareli-Fursatganj", 
    "Raebareli-Mejorganj", 
    "Raebareli-Amanwa", 
    "Raebareli-Maharajganj", 
    "Raebareli-Ataura Buzurg", 
    "Raebareli-Akelwa Chauraha", 
    "Raebareli-Sundarganj Chauraha", 
    "Raebareli-Deeh Market", 
    "Raebareli-Ghorwara", 
    "Raebareli-Alawalpur", 
    "Raebareli-Suchi", 
    "Raebareli-Odaree", 
    "Raebareli-Aihar", 
    "Raebareli-Jais", 
    "Raebareli-Uttarpara",
    "Raebareli-Shankarganj", 
    "Raebareli-Murai Bagh",
    "Unchahar-Babuganj",
    "Unchahar-Babuganj Road",
    "Unchahar-Unchahar Market",
    "Unchahar-Unchahar Bus Stand",
    "Unchahar-NTPC",
    "Unchahar-Unchahar Chowk",
    "Unchahar-Kharauli",
    "Unchahar-Sawaya Tiraha",
    "Unchahar-Madari Ganj",
    "Unchahar-Arkha",
    "Unchahar-Kand Rawan",
    "Unchahar-Bikar Garh",
    "Unchahar-Umran",
    "Unchahar-Daulatpur",
    "Unchahar-Jamunapur",
    "Unchahar-Mateen Ganj",
    "Unchahar-Laxmi Ganj",
    "Unchahar-Gokana Ghat",
    "Unchahar-Itaura Buzurg",
    "Bhiwadi-Rewari",
    "Bhiwadi-Tijara",
    "Bachhrawan-Hardoi",
    "Bachhrawan-Karanpur",
    "Bachhrawan-Bahuda Kala",
    "Bachhrawan-Bhawani Garh",
    "Bachhrawan-Johwa Sarki",
    "Bachhrawan-Radhopur",
    "Bachhrawan-Bhagwan Pur",
    "Bachhrawan-Bairisal Pur",
    "Bachhrawan-Hilauli",
    "Bachhrawan-Krishnapur",
    "Bachhrawan-Nigoha",
    "Bachhrawan-Karan Pur",
    "Bachhrawan-Shiv Garh",
    "Bachhrawan-Rampur Garhi",
    "Bachhrawan-Sandana",
    "Bachhrawan-Jabarauli",
    "Bachhrawan-Gurbaxganj",
    "Bachhrawan-Gambhipur",
    "Bachhrawan-Sataon",
    "Bachhrawan-Bindaua",
    "Bachhrawan-Mohanlal Ganj",
    "Bachhrawan-Bachrawan Main Market", 
    "Bachhrawan-Raja Mau", 
    "Bachhrawan-Chhotkwa Khera", 
    "Bachhrawan-Johwa Sharki", 
    "Bachhrawan-Pachmim Gaon", 
    "Bachhrawan-Samaspur Halor", 
    "Bachhrawan-Mohanlalganj",
    "Unchahar-Sabeespur",
    "Unchahar-Pampapur",
    "Unchahar-Usraina",
    "Unchahar-Binda Ganj",
    "Unchahar-Pariyawan",
    "Unchahar-Janwamau",
    "Unchahar-Jhokwara",
    "Unchahar-Salon",
    "Unchahar-Jagatpur",
    "Unchahar-Jagatpur",
    "Barabanki-Nehru Nagar",
    "Barabanki-Ghosiyana",
    "Barabanki-Satyapremi Nagar",
    "Barabanki-Ayodhya Nagar",
    "Barabanki-Nawabganj",
    "Barabanki-Ramna",
    "Barabanki-Shivaji Puram",
    "Barabanki-Palhari",
    "Barabanki-Banki",
    "Barabanki-Lakhpedabagh",
    "Barabanki-Obaree",
    "Barabanki-Gandhi Nagar",
    "Barabanki-Jaswant Nagar",
    "Barabanki-Faizullaganj",
    "Barabanki-Peerbatawan",
    "Barabanki-Dhakauli",
    "Barabanki-Sultanpur",
    "Barabanki-Bahadur Pur",
    "Barabanki-Barail",
    "Barabanki-Ganga Vihar Colony",
    "Barabanki-Nehru Nagar, Barabanki",
    "Barabanki-Ghosiyana, Barabanki",
    "Barabanki-Satyapremi Nagar, Barabanki",
    "Barabanki-Ayodhya Nagar, Barabanki",
    "Barabanki-Nawabganj, Barabanki",
    "Barabanki-Ramna, Barabanki",
    "Barabanki-Shivaji Puram, Barabanki",
    "Barabanki-Palhari, Barabanki",
    "Barabanki-Banki, Barabanki",
    "Barabanki-Lakhpedabagh, Barabanki",
    "Barabanki-Obaree, Barabanki",
    "Barabanki-Gandhi Nagar, Barabanki",
    "Barabanki-Jaswant Nagar, Barabanki",
    "Barabanki-Faizullaganj, Barabanki",
    "Barabanki-Peerbatawan, Barabanki",
    "Barabanki-Dhakauli, Barabanki",
    "Barabanki-Sultanpur, Barabanki",
    "Barabanki-Bahadur Pur, Barabanki",
    "Barabanki-Barail, Barabanki",
    "Barabanki-Ganga Vihar Colony, Barabanki",
    "Jaipur-sanganer",
    "Jaipur-Mahima nagar",
    "Jaipur-Gapatupra",
    "Jaipur-Mansarovar",
    "Jaipur-SFS Mansarovar",
    "Ayodhya-Other",
    "Bacchrawan-Other",
    "Bhiwadi-Other",
    "Delhi-Other",
    "Jaipur-Other",
    "Lucknow-Other",
    "Pataudi-Other",
    "Raebareli-Other",
    "Sultanpur-Other",
    "Unchahar-Other"
  ]

  const bankNames = [
    "Axis Bank",
    "Bandhan Bank",
    "Bank of Baroda",
    "Bank of India",
    "Bank of Maharashtra",
    "Canara Bank",
    "Central Bank of India",
    "City Union Bank",
    "Federal Bank",
    "HDFC Bank",
    "ICICI Bank",
    "IDBI Bank",
    "IDFC First Bank",
    "Indian Bank",
    "Indian Overseas Bank",
    "IndusInd Bank",
    "Kotak Mahindra Bank",
    "Punjab and Sind Bank",
    "Punjab National Bank",
    "RBL Bank",
    "State Bank of India",
    "UCO Bank",
    "Union Bank of India",
    "Yes Bank",
    "Others"
  ]


  const mappiing = {
    'Affordable Housing Loan': housingLoan,
    'Investment (12% Return)': investment,
    'Arth P@ymint': arthPayment,
    'Small Ticket Loan': stl,
    'High Ticket Loan': htl,
    'Insurance': insurance
  }

  useEffect(() => {
    if(!localStorage.getItem('hunterId') || !localStorage.getItem('token')){
      localStorage.clear();
      navigate('/hunt/login');
    } else {
      getLatLong();
    }
  }, [])

  const getLatLong = () => {
    navigator.permissions.query({
      name: 'geolocation'
    }).then(function (result) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setForm((prev:any) => ({
          ...prev,
          lat: position.coords.latitude.toString(),
          long: position.coords.longitude.toString()
        }))
      },function (error) {
          alert('Please allow location');
        });
      if (result.state === 'granted') {
      } else if (result.state === 'prompt') {
      } else if (result.state === 'denied') {
      }
    });
  }

  

  const handleInput = (key:string, value:any) => {
    if(key === 'offered_product'){
      if(selectedProducts.length > value.length){
        setSelectedProducts(value);
        if(value?.includes('Not Interested')){
          setIsUserInterested(false);
        } else {
          setIsUserInterested(true);
        }
      } else {
        if(value?.includes('Not Interested')){
          setIsUserInterested(false);
          setSelectedProducts(value);
        } else {
          setIsUserInterested(true);
          let arr = [];
          value.forEach((val:any) =>{
            if(mappiing[val]){
              arr = [...arr, ...mappiing[val]];
            }
          })
          setSelectedProducts(value);
          toaster.showDocs(`${arr}`).then((result:any) => {
            if(result.isConfirmed){

            }
          })
        }
      }
    } else if (key === 'other_center_name') {
      setIsOtherSelected(false);
      var x = {centername: form['center_name'],
      other: value}
      setCenterName(x)
      // Set other center name value to center name
      // setForm((prev: any) => ({
      //     ...prev,
      //     key: value
      // }));
    }
    else {
      setForm((prev:any) => ({
        ...prev,
        [key]: value
      }))
    }
  }

  const handleLoanAmount = (key:string, value:string) => {
    setStlForm((prev:any) => ({
      ...prev,
      [key]: value
    }));

    setHtlForm((prev:any) => ({
      ...prev,
      [key]: value
    }))

    setHousingLoanForm((prev:any) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleHousingLoanInput = (key:string, value:string) => {
    setHousingLoanForm((prev:any) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleHtlForm = (key:string, value:string) => {
    setHtlForm((prev:any) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleStlForm = (key:string, value:string) => {
    setStlForm((prev:any) => ({
      ...prev,
      [key]: value
    }));
  }

  const handleInsuranceForm = (key:string, value:string) => {
    setInsuranceForm((prev:any) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleResidanceForm = (key:string, value:string) => {
    setResidanceForm((prev:any) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleVideoFormData = (key:string, value:any) => {

    setVideoFormData(value);
  }

  const submitForm = () => {
    setIsFormSubmitted(true);
    if ((validateForm() || (!isUserInterested && form?.not_interested_remark && validateNotInterestedData())) && validateOtherInput()){
      
      loader.showLoader();

      var center = form?.center_name;

if((centerName['centername'] == form?.center_name) && (centerName['centername'].length > 0) && (centerName['other'].length > 0)){
  center = centerName['centername']+" ("+centerName['other']+")"
}


      const payload = {
        "hunter_id": localStorage.getItem('hunterId'),
        "pincode": form?.shop_pincode,
        // "referal_code": "REF123",
        "center_name": center,
        "shop_owner_name": form?.shop_owner_name,
        "shop_owner_dob": form?.owner_dob,
        "shop_owner_mobile_number": form?.owner_mobile_number,
        "whatsapp_number": form?.whatsapp_number,
        "shop_owner_email_id": form?.owner_email_id,
        "shop_address": form?.shop_address,
        "district": form?.shop_district,
        "shop_name": form?.shop_name,
        "shop_type": form?.Shop_type,
        "house_type": form?.house_type,
        "business_started_date": form?.bussiness_start_date,
        "shop_size": form?.shop_size,
        "payment_wallet_name": form?.payment_wallet_name,
        "bank_name": form?.bank_name,
        "active_atm_card": form?.active_atm_card,
        "small_business_loan": selectedProducts?.includes('Small Ticket Loan'),
        "high_value_business_loan": selectedProducts?.includes('High Ticket Loan'),
        "affordable_housing_loan": selectedProducts?.includes('Affordable Housing Loan'),
        "insurance": selectedProducts?.includes('Insurance'),
        "micro_investment": selectedProducts?.includes('Investment (12% Return)'),
        "account_opening": selectedProducts?.includes('Account Opening (Tide)'),
        "arth_payment": selectedProducts?.includes('Arth P@ymint'),
        "not_interested": selectedProducts?.includes('Not Interested'),
        "small_business_loan_details": {...stlForm, panNumber},
        "high_value_business_loan_details": {...htlForm, gstinNumber, panNumber},
        "affordable_housing_loan_details": {...housingLoanForm},
        "insurance_details": {...insuranceForm, ...residenceForm},
        "arth_payment_details": {gstinNumber},
        "micro_investment_details": {},
        "account_opening_details": {},
        "not_interested_remarks": form?.not_interested_remark,
        "occupation_category": form?.occupation_catagory,
        "distributor_name": form?.distributor_name,
        "distributor_mobile": form?.distributor_mobile_number,
        "distributor_occupation_category": form?.distributor_occupation_category,
        "distributor_occupation": form?.distributor_occupation,
        "distributor_business_name": form?.distributor_bussiness_name,
        "distributor_location": form?.distributor_location,
        "shop_outside_photo_base64": form?.shop_outside_photo,
        "shop_photo_base64": form?.shop_inside_photo,
        "selfie_with_owner_base64": form?.shop_photo_with_owner,
        "shop_video_base64": form?.shop_video,
        "payment_status": form?.is_payment_done,
        "tide_product_completion": form?.tide_product_completion === 'Yes'? true: false,
        "rupee_product_completion": form?.rupee_product_completion === 'Yes'? true: false,
        "latitude": form?.lat,
        "longitude": form?.long,
        "additional_data": {
          "custom_field1": "value1",
          "custom_field2": "value2"
        }
      }

      setDisableButton(true);
  
      huntingService.submitHuntingForm(payload).then((response:any) => {
        if(response?.data?.form_id){
          toaster.show(false, 'Form Submitted....');
          // sendVideo(response?.data?.form_id);
          setTimeout(() => {
            setDisableButton(false);
            navigate('/hunt/list');
          }, 2000);
        } else {
          toaster.show(true, 'Mobile Number Already Exists');
          setTimeout(() => {
            setDisableButton(false);
            navigate('/hunt/list');
          }, 2000);
        }
      }).catch((error:any) => {
        if(error?.response?.data?.response){
          toaster.show(true, error?.response?.data?.response);
        } else {
          toaster.show(true, 'Unable To Submit Form');
        }
        loader.hideLoader();
        setDisableButton(false);
      })
    } else {
      toaster.show(true, 'Please Fill all Details');
    }
  }

  const validateOtherInput = () => {
    var check = true;
    // const result = Object.entries(form).every(([key, value]:any) => {
    if(form['center_name'].includes('Other')) {
      if((centerName === null) || (centerName === "") || (centerName['other'] === null) || (centerName['other'] === "")) {
        check = false
      }
    }
  return check;
  }

  const validateForm = () => {
    const result = Object.entries(form).every(([key, value]:any) => {
      if (key.includes('mobile_number') || key.includes('whatsapp_number')) {
        const regex = /^\d{10}$/;
        if (regex.test(value)) {
          return value.length === 10;
        } else {
          setForm((prev: any) => ({
            ...prev,
            [key]: ''
          }))
          return false;
        }
      } else if(key.includes('pincode')){
        const regex = /^\d{6}$/;
        if (regex.test(value)) {
          return value.length === 6;
        } else {
          setForm((prev: any) => ({
            ...prev,
            [key]: ''
          }))
          return false;
        }
      }
      //  else if(key.includes('center_name') && form['center_name'].includes('Other')) {
      //   return validateOtherInput(key)
      // }

      return value.trim() !== '';
    })

    let result2 = true;

    if(selectedProducts.includes('Insurance')){
        result2 = Object.entries(residenceForm).every(([key, value]:any) => {
          if (key.includes('pincode')) {
            const regex = /^\d{6}$/;
            if (regex.test(value)) {
              return value.length === 6;
            } else {
              setResidanceForm((prev: any) => ({
                ...prev,
                [key]: ''
              }))
              return false;
            }
          }
          return value.trim() !== '';
        })
    }

    let result3 = true;

    if(selectedProducts.includes('Affordable Housing Loan')){
      result3 = Object.entries(housingLoanForm).every(([key, value]:any) => {
        return value.trim() !== '';
      })
    }

    let result4 = true;
    if(selectedProducts.includes('Small Ticket Loan')){
      result4 = Object.entries(stlForm).every(([key, value]:any) => {
        return value.trim() !== '' && panNumber.length === 10;
      })
    }

    let result5 = true;
    if(selectedProducts.includes('Arth P@ymint')){
      result5 = gstinNumber.length === 15;
    }

    let result6 = true;
    if(selectedProducts.includes('High Ticket Loan')){
      result6 = Object.entries(htlForm).every(([key, value]:any) => {
        return value.trim() !== '' && gstinNumber.length === 15 && panNumber.length === 10;
      })
    }

    let result7 = true;
    if(selectedProducts.includes('Insurance')){
      result7 = Object.entries(insuranceForm).every(([key, value]:any) => {
        return value.trim() !== '';
      })
    }

    const result8 = selectedProducts.length > 0;

    return result && result2 && result3 && result4 && result5 && result6 && result7 && result8;
  }

  const validateNotInterestedData = () => {
    const skipOnNotInterested = [
      'center_name',
      'shop_owner_name',
      'owner_mobile_number',
      'active_atm_card',
      'owner_dob',
      'gender',
      'whatsapp_number',
      'owner_email_id',
      'shop_address',
      'shop_district',
      'shop_pincode',
      'shop_name',
      'Shop_type',
      'house_type',
      'bussiness_start_date',
      'shop_size',
      'payment_wallet_name',
      'bank_name',
      'occupation_catagory',
      'shop_outside_photo',
      'shop_inside_photo',
      'shop_photo_with_owner'
    ];
    const result = Object.entries(form).every(([key, value]:any) => {
      if(skipOnNotInterested.includes(key)){
        if (key.includes('mobile_number') || key.includes('whatsapp_number')) {
          const regex = /^\d{10}$/;
          if (regex.test(value)) {
            return value.length === 10;
          } else {
            setForm((prev: any) => ({
              ...prev,
              [key]: ''
            }))
            return false;
          }
        } else if(key.includes('pincode')){
          const regex = /^\d{6}$/;
          if (regex.test(value)) {
            return value.length === 6;
          } else {
            setForm((prev: any) => ({
              ...prev,
              [key]: ''
            }))
            return false;
          }
        }
        return value.trim() !== '';
      } else {
        return true;
      }
    })

    return result;
  }

  const sendVideo = (currentFormId:any) => {
    const payload = videoFormData;
    // const payload = {
    //   shop_video_base64: videoFormData,
    //   form_id: currentFormId
    // };
    payload.append('form_id', currentFormId);
    huntingService.sendShopVideo(payload).then((response:any) => {
      loader.hideLoader();
      setDisableButton(false);
      toaster.show(false, 'Video Submitted Succesfully');
      setTimeout(() => {
        navigate('/hunt/list');
      }, 1000);
    }).catch((error:any) => {
      toaster.show(true, 'Unable to Send Video');
      setDisableButton(false);
      loader.hideLoader();
    })
  }
 
   
   


  return (
    <div className='p-2' style={{backgroundColor: "#F9FAFB"}}>
      <div className="d-flex justify-content-center sticky-style">
        <div className='form-logo'>
          <ArthLogo />
          <span>Empower</span>
        </div>
      </div>
    <div className='hunting-form card'>
      <div className="card-body">
        <div className="row mt-4">
          <div className="col-md-4 col-sm-6 mb-4">
            <Dropdown
              heading={'Center Name - Market Name'}
              value={form?.center_name}
              isCompulsoryField={true}
              list={centerNames}
              handleInput={handleInput}
              identifier={'center_name'}
              supportiveTextRed={(form?.center_name === '' && isFormSubmitted) && 'Please Select Center Name'}
            />
            {(form.center_name.includes('Other')) && 
               <div className="col-md-12 col-sm-6 mb-4">
               <TextInput
                    type='text'
                    heading='Market Name'
                    name='other_center_name'                    
                    isCompulsoryField= {true}
                    value={form?.other_center_name}
                    handleInput={handleInput}
                    placeholder="Enter Other Center Name"
                    identifier={'other_center_name'}
                    supportiveTextRed={(isOtherSelected && isFormSubmitted) && 'Please Share Center Name' }
                />
              </div>
            }
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Shop Owner Name'}
              value={form?.shop_owner_name}
              isCompulsoryField={true}
              placeholder={'Name'}
              handleInput={handleInput}
              identifier={'shop_owner_name'}
              supportiveTextRed={(form?.shop_owner_name === '' && isFormSubmitted) && 'Please Fill Name'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Shop Owner Mobile Number'}
              value={form?.owner_mobile_number}
              type={'text'}
              isCompulsoryField={true}
              placeholder={'Mobile Number'}
              maxLength={10}
              handleInput={handleInput}
              identifier={'owner_mobile_number'}
              supportiveTextRed={(form?.owner_mobile_number === '' && isFormSubmitted) && 'Please Fill Mobile Number'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Shop Owner DOB'}
              type={'date'}
              value={form?.owner_dob}
              isCompulsoryField={true}
              placeholder={'owner_dob Code'}
              handleInput={handleInput}
              identifier={'owner_dob'}
              supportiveTextRed={(form?.owner_dob === '' && isFormSubmitted) && 'Please Fill DOB'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className='d-flex'>
              <RadioInput 
                value={form?.gender}
                heading={'Gender'} 
                label1={'Male'} 
                label2={'Female'}
                identifier={'gender'}
                isCompulsoryField={true}
                handleInput={handleInput}
                supportiveTextRed={(form?.gender === '' && isFormSubmitted) && 'Please Select Gender'}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'WhatsApp Number'}
              value={form?.whatsapp_number}
              isCompulsoryField={true}
              handleInput={handleInput}
              identifier={'whatsapp_number'}
              maxLength={10}
              supportiveTextRed={(form?.whatsapp_number === '' && isFormSubmitted) && 'Please Fill Whatsapp Number'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Shop Owner Email ID'}
              value={form?.owner_email_id}
              isCompulsoryField={true}
              type={'text'}
              placeholder={'Email'}
              handleInput={handleInput}
              identifier={'owner_email_id'}
              supportiveTextRed={(form?.owner_email_id === '' && isFormSubmitted) && 'Please Fill Email ID'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Shop Address'}
              value={form?.shop_address}
              isCompulsoryField={true}
              type={'text'}
              placeholder={'Address'}
              handleInput={handleInput}
              identifier={'shop_address'}
              supportiveTextRed={(form?.shop_address === '' && isFormSubmitted) && 'Please Fill Shop Address'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'City'}
              value={form?.shop_district}
              isCompulsoryField={true}
              type={'text'}
              placeholder={'City'}
              handleInput={handleInput}
              identifier={'shop_district'}
              supportiveTextRed={(form?.shop_district === '' && isFormSubmitted) && 'Please Fill Shop District'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Pin Code'}
              value={form?.shop_pincode}
              isCompulsoryField={true}
              type={'text'}
              maxLength={6}
              inputMode={'numeric'}
              placeholder={'Pincode'}
              handleInput={handleInput}
              identifier={'shop_pincode'}
              supportiveTextRed={(form?.shop_pincode === '' && isFormSubmitted) && 'Please Fill Shop Pincode'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Shop Name'}
              value={form?.shop_name}
              isCompulsoryField={true}
              type={'text'}
              placeholder={'Shop Name'}
              handleInput={handleInput}
              identifier={'shop_name'}
              supportiveTextRed={(form?.shop_name === '' && isFormSubmitted) && 'Please Fill Shop Name'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className='d-flex'>
              <RadioInput 
                value={form?.Shop_type}
                heading={'Shop Type'} 
                label1={'Owned'} 
                label2={'Rented'}
                isCompulsoryField={'true'}
                identifier={'Shop_type'}
                handleInput={handleInput}
                supportiveTextRed={(form?.Shop_type === '' && isFormSubmitted) && 'Please Select Shop Type'}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className='d-flex'>
              <RadioInput 
                value={form?.house_type}
                heading={'House Type'} 
                label1={'Owned'} 
                label2={'Rented'} 
                isCompulsoryField={true}
                identifier={'house_type'}
                handleInput={handleInput}
                supportiveTextRed={(form?.house_type === '' && isFormSubmitted) && 'Please Select Home Type'}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Business Started Month/Year'}
              value={form?.bussiness_start_date}
              isCompulsoryField={true}
              type={'date'}
              handleInput={handleInput}
              identifier={'bussiness_start_date'}
              supportiveTextRed={(form?.bussiness_start_date === '' && isFormSubmitted) && 'Please Fill Start Date'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className='d-flex'>
              <RadioInput 
                value={form?.active_atm_card}
                heading={'Active ATM Card'} 
                label1={'Yes'} 
                label2={'No'} 
                isCompulsoryField={true}
                identifier={'active_atm_card'}
                handleInput={handleInput}
                supportiveTextRed={(form?.active_atm_card === '' && isFormSubmitted) && 'Please Select Card Status'}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <Dropdown
              heading={'Shop Size (Sq. Feet)'}
              value={form?.shop_size}
              isCompulsoryField={true}
              list={['<100 Sq. Ft.', '100-250 Sq. Ft.', '250-500 Sq. Ft.', '500-1000 Sq. Ft.', '1000-2000 Sq. Ft.', '>200 Sq. Ft.']}
              handleInput={handleInput}
              identifier={'shop_size'}
              supportiveTextRed={(form?.shop_size === '' && isFormSubmitted) && 'Please Select Shop Size'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <Dropdown
              heading={'Payment Wallet Name'}
              value={form?.payment_wallet_name}
              isCompulsoryField={true}
              list={['Phone Pe', 'Google Pay', 'Bharat Pay', 'PayTm', 'Other', 'None']}
              handleInput={handleInput}
              identifier={'payment_wallet_name'}
              supportiveTextRed={(form?.payment_wallet_name === '' && isFormSubmitted) && 'Please Select Payment Wallet Name'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <Dropdown
              heading={'Bank Name'}
              value={form?.bank_name}
              isCompulsoryField={true}
              list={bankNames}
              handleInput={handleInput}
              identifier={'bank_name'}
              supportiveTextRed={(form?.bank_name === '' && isFormSubmitted) && 'Please Select Bank Name'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <MultiSelect
              heading={'Select Products'}
              value={selectedProducts}
              isCompulsoryField={true}
              list={productType}
              handleInput={handleInput}
              identifier={'offered_product'}
              supportiveTextRed={(selectedProducts.length < 1 && isFormSubmitted) && 'Please Select Product'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'If Not Interested please share remarks'}
              value={form?.not_interested_remark}
              type={'text'}
              placeholder={'Remark'}
              handleInput={handleInput}
              identifier={'not_interested_remark'}
              supportiveTextRed={!isUserInterested && isFormSubmitted && !form?.not_interested_remark && 'Please Mention Remarks'}
            />
          </div>
          {selectedProducts.includes('Affordable Housing Loan') && <><div className="col-md-4 col-sm-6 mb-4">
            <div className='d-flex'>
              <RadioInput 
                value={housingLoanForm?.type_of_loan}
                heading={'Which Type Of Loan Do You Need?'} 
                label1={'Housing Loan'} 
                label2={'Loan Against Property'}
                identifier={'type_of_loan'}
                isCompulsoryField={true}
                handleInput={handleHousingLoanInput}
                supportiveTextRed={(housingLoanForm?.type_of_loan === '' && isFormSubmitted) && 'Please Select Type Of Loan'}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
              <TextInput
                heading={'Property Value'}
                value={form?.property_value}
                isCompulsoryField={true}
                type={'number'}
                inputMode={'numeric'}
                placeholder={'Value'}
                handleInput={handleHousingLoanInput}
                identifier={'property_value'}
                supportiveTextRed={(housingLoanForm?.property_value === '' && isFormSubmitted) && 'Please Fill Property Value'}
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <TextInput
                heading={'Property Pincode'}
                value={form?.property_pincode}
                inputMode={'numeric'}
                type={'text'}
                maxLength={6}
                isCompulsoryField={true}
                placeholder={'pincode'}
                handleInput={handleHousingLoanInput}
                identifier={'property_pincode'}
                supportiveTextRed={(housingLoanForm?.property_pincode === '' && isFormSubmitted) && 'Please Fill Property Pincode'}
              />
            </div>
          </>}
          {
            (selectedProducts.includes('Small Ticket Loan') || selectedProducts.includes('High Ticket Loan')) && <>
              <div className="col-md-4 col-sm-6 mb-4">
                <TextInput
                  heading={'Shop Owner PAN Number'}
                  value={panNumber}
                  type={'text'}
                  maxLength={10}
                  isCompulsoryField={true}
                  placeholder={'PAN Number'}
                  handleInput={(key:string, value:string) => {setPanNumber(value)}}
                  identifier={'owner_pan_number'}
                  supportiveTextRed={(panNumber.length !== 10 && isFormSubmitted) && 'Please Fill PAN Number'}
                />
              </div>
            </>
          }
          {(selectedProducts?.includes('Affordable Housing Loan') || 
            selectedProducts?.includes('High Ticket Loan') || 
            selectedProducts?.includes('Small Ticket Loan')
            ) && <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Required Loan Amount'}
              value={form?.required_loan_amount}
              isCompulsoryField={true}
              type={'number'}
              inputMode={'numeric'}
              placeholder={'Loan Amount'}
              handleInput={handleLoanAmount}
              identifier={'required_loan_amount'}
              supportiveTextRed={(form?.required_loan_amount === '' && isFormSubmitted) && 'Please Fill Loan Amount'}
            />
          </div>}
          <div className="col-md-4 col-sm-6 mb-4">
            <Dropdown
              heading={'Occupation Category'}
              value={form?.occupation_catagory}
              isCompulsoryField={true}
              list={['Kirana/Groceries/General Store', 'Electronics/E-commerce/Mobile Accessories/Telecom', 'Pharmacy/Medical', 'Salon/Cosmetics/Beauty Parlor/Fitness', 'Apparels/Clothing/Boutique/Garments/Tailoring/Footwear', 'Restaurant/Food/Hotel', 'Others']}
              handleInput={handleInput}
              identifier={'occupation_catagory'}
              supportiveTextRed={(form?.occupation_catagory === '' && isFormSubmitted) && 'Please Select Bussiness Category'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Distributor Name'}
              value={form?.distributor_name}
              type={'text'}
              placeholder={'Name'}
              handleInput={handleInput}
              identifier={'distributor_name'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Distributor Mobile Number'}
              value={form?.distributor_mobile_number}
              type={'text'}
              maxLength={10}
              placeholder={'Mobile No.'}
              handleInput={handleInput}
              identifier={'distributor_mobile_number'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <Dropdown
              heading={'Distributor Occupation Category'}
              value={form?.distributor_occupation_category}
              list={['Kirana/Groceries/General Store', 'Electronics/E-commerce/Mobile Accessories/Telecom', 'Pharmacy/Medical', 'Salon/Cosmetics/Beauty Parlor/Fitness', 'Apparels/Clothing/Boutique/Garments/Tailoring/Footwear', 'Restaurant/Food/Hotel', 'Others']}
              handleInput={handleInput}
              identifier={'distributor_occupation_category'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <Dropdown
              heading={'Distributor Occupation'}
              value={form?.distributor_occupation}
              list={['Distributor', 'WholeSeller', 'Manufacturer']}
              handleInput={handleInput}
              identifier={'distributor_occupation'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Distributor Business Name'}
              value={form?.distributor_bussiness_name}
              type={'text'}
              placeholder={'Bussiness Name'}
              handleInput={handleInput}
              identifier={'distributor_bussiness_name'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <TextInput
              heading={'Distributor Location'}
              value={form?.distributor_location}
              type={'text'}
              placeholder={'Location'}
              handleInput={handleInput}
              identifier={'distributor_location'}
            />
          </div>
          {
            (selectedProducts.includes('Arth P@ymint') || selectedProducts.includes('High Ticket Loan')) && 
            <>
              <div className="col-md-4 col-sm-6 mb-4">
                <TextInput
                  heading={'GSTIN Number'}
                  value={gstinNumber}
                  isCompulsoryField={true}
                  type={'text'}
                  maxLength={15}
                  placeholder={'GSTIN Number'}
                  handleInput={(key:string, value:string) => {setGstinNumber(value)}}
                  identifier={'gstin_number'}
                  supportiveTextRed={(gstinNumber.length !== 15 && isFormSubmitted) && 'Please Fill GSTIN Number'}
                />
              </div>
            </>
          }
          {
            (
            selectedProducts.includes('Insurance')
            ) && 
            <>
              <div className="col-md-4 col-sm-6 mb-4">
                <TextInput
                  heading={'Residence Address'}
                  value={residenceForm?.residence_address}
                  isCompulsoryField={true}
                  type={'text'}
                  placeholder={'Address'}
                  handleInput={handleResidanceForm}
                  identifier={'residence_address'}
                  supportiveTextRed={(residenceForm?.residence_address === '' && isFormSubmitted) && 'Please Fill Address'}
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <TextInput
                  heading={'Residence City'}
                  value={residenceForm?.residence_city}
                  isCompulsoryField={true}
                  type={'text'}
                  placeholder={'City'}
                  handleInput={handleResidanceForm}
                  identifier={'residence_city'}
                  supportiveTextRed={(residenceForm?.residence_city === '' && isFormSubmitted) && 'Please Fill City'}
                />
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <TextInput
                  heading={'Residence Pincode'}
                  value={residenceForm?.residance_pincode}
                  isCompulsoryField={true}
                  type={'text'}
                  inputMode={'numeric'}
                  placeholder={'Pincode'}
                  maxLength={6}
                  handleInput={handleResidanceForm}
                  identifier={'residance_pincode'}
                  supportiveTextRed={(residenceForm?.residance_pincode === '' && isFormSubmitted) && 'Please Fill Pincode'}
                />
              </div>
            </>
          }
          {
            selectedProducts.includes('High Ticket Loan') &&
            <>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className='d-flex'>
                  <RadioInput
                    value={htlForm?.itr_year}
                    heading={'2 Year ITR'} 
                    label1={'Yes'} 
                    label2={'No'}
                    identifier={'itr_year'}
                    isCompulsoryField={true}
                    handleInput={handleHtlForm}
                    supportiveTextRed={(htlForm?.itr_year === '' && isFormSubmitted) && 'Please Select ITR year'}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className='d-flex'>
                  <RadioInput
                    value={htlForm?.type_of_residance}
                    heading={'Type Of Residence'} 
                    label1={'Owned'} 
                    label2={'Rented'}
                    identifier={'type_of_residance'}
                    isCompulsoryField={true}
                    handleInput={handleHtlForm}
                    supportiveTextRed={(htlForm?.type_of_residance === '' && isFormSubmitted) && 'Please Select Residance Type'}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-4">
                <div className='d-flex'>
                  <RadioInput
                    value={htlForm?.bank_statement}
                    heading={'12 Month Bank Statement'} 
                    label1={'Yes'} 
                    label2={'No'}
                    identifier={'bank_statement'}
                    isCompulsoryField={true}
                    handleInput={handleHtlForm}
                    supportiveTextRed={(htlForm?.bank_statement === '' && isFormSubmitted) && 'Please Select Bank Statemenmt'}
                  />
                </div>
              </div>
            </>
          }
          {
            selectedProducts.includes('Insurance') && 
            <>
              <div className="col-md-4 col-sm-6 mb-4">
                <TextInput
                  heading={'Mother Name'}
                  value={insuranceForm?.mother_name}
                  isCompulsoryField={true}
                  type={'text'}
                  placeholder={'Mother Name'}
                  handleInput={handleInsuranceForm}
                  identifier={'mother_name'}
                  supportiveTextRed={(insuranceForm?.mother_name === '' && isFormSubmitted) && 'Please Fill Mother Name'}
                />
              </div>
            </>
          }
          <div className="col-md-4 col-sm-6 mb-4">
            <FilePicker
              heading={'Shop Photo (Outside/Banner/ Sign Board)'}
              isCompulsoryField={true}
              identifier={'shop_outside_photo'}
              handleInput={handleInput}
              accept={'.jpg, .jpeg, .png'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <FilePicker
              heading={'Shop Photo (Inside/Stock)'}
              isCompulsoryField={true}
              identifier={'shop_inside_photo'}
              handleInput={handleInput}
              accept={'.jpg, .jpeg, .png'}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <FilePicker
              heading={'Shop Photo (Selfie with Owner)'}
              isCompulsoryField={true}
              identifier={'shop_photo_with_owner'}
              handleInput={handleInput}
              accept={'.jpg, .jpeg, .png'}
            />
          </div>
          {/* <div className="col-md-4 col-sm-6 mb-4">
            <VideoRecorder
              heading={'Shop Photo (Environment/Locality)'}
              isCompulsoryField={true}
              identifier={'shop_video'}
              handleCapture={handleVideoFormData}
              supportiveText={isFormSubmitted && videoFormData === '' && 'Please Upload Video'}
            />
          </div> */}
          {isUserInterested && <><div className="col-md-4 col-sm-6 mb-4">
            <div>
              <div className="qr-code">
                Make Payment <span style={{color: "red"}}>*</span>
              </div>
              <button className='btn btn-secondary' onClick={() => {toaster.viewImage(QRCode, '100%')}}>QR Code</button>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className='d-flex'>
              <RadioInput 
                value={form?.is_payment_done}
                heading={'Is Payment Done'} 
                label1={'Paid'} 
                label2={'Not Paid'}
                identifier={'is_payment_done'}
                isCompulsoryField={true}
                handleInput={handleInput}
                supportiveTextRed={(form?.is_payment_done === '' && isFormSubmitted) && 'Please Select Payment Status'}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div>
              <div className="qr-code">
                Submit Lead for TIDE Card
              </div>
              <a href="https://web.tide.co/login" target="_blank">Open Link</a>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div>
              <div className="qr-code">
                Submit Lead to Rupee Circle
              </div>
              <a href="https://max-uat.rupeecircle.com/auth/login/" target="_blank">Open Link</a>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className='d-flex'>
              <RadioInput
                value={form?.rupee_product_completion}
                heading={'Rupee Circle Done?'} 
                label1={'Yes'} 
                label2={'No'}
                identifier={'rupee_product_completion'}
                isCompulsoryField={true}
                handleInput={handleInput}
                supportiveTextRed={(form?.rupee_product_completion === '' && isFormSubmitted) && 'Please Select Rupee Circle Status'}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className='d-flex'>
              <RadioInput
                value={form?.tide_product_completion}
                heading={'Tide Done?'} 
                label1={'Yes'} 
                label2={'No'}
                identifier={'tide_product_completion'}
                isCompulsoryField={true}
                handleInput={handleInput}
                supportiveTextRed={(form?.tide_product_completion === '' && isFormSubmitted) && 'Please Select Tide Status'}
              />
            </div>
          </div>
          </>}
        </div>
      </div>
      <div className='hunting-form-button'>
        {!disableButton && <button onClick={submitForm} className='btn btn-primary'>Submit</button>}
      </div>
    </div>
    </div>
  )
}

export default HuntingForm;
