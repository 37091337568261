import { useEffect, useState } from 'react';
import TextInput from '../../../../components/partner-application/text-input/TextInput';
import FormButton from '../../../../components/partner-application/button/FormButton';
import { InfoIcon } from '../../../../assets/svg';
import { PartnerService } from '../../../../services/api/partnerService';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toaster } from '../../../../utils/utilities/Toaster';
import { useSelector } from 'react-redux';
import { inputDisabled } from '../../../../utils/constants/dropdownConstants';


const BankProfileUpdates = () => {

  const partnerInfo = useSelector((state: any) => (state.partnerInfo.data))
  const [ifscCode, setIfscCode] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountHolderName, setAccountHolderName] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [isIfscCodeValid, setIsIfscCodeValid] = useState<boolean>(true);
  const [isAccountNumberValid, setIsAccountNumberValid] = useState<boolean>(true);
  const partnerService = new PartnerService();
  const toaster = new Toaster();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handleInput = (type: string, value: string) => {
    if (type === 'ifsc') {
      setIsIfscCodeValid(true);
      setIfscCode(value.toUpperCase());
      setBankName('');
    } else {
      setIsAccountNumberValid(true);
      setAccountNumber(value);
      setAccountHolderName('');
    }
  }

  const nextClick = async () => {
    try {
      setIsFormSubmitted(true);
      if (ifscCode && accountNumber) {
        let bank_name: string = bankName;
        let holder_name: string = accountHolderName;
        bank_name = await fetchBankName();
        holder_name = await fetchAccountHolderName();
        if (holder_name && bank_name) {
          submitProfileUpdateRequests(bank_name, holder_name);
        }
      } else {
        setIsAccountNumberValid(false);
        setIsIfscCodeValid(false);
      }
    } catch {
    }
  }

  const fetchBankName = async () => {
    try {
      const response = await partnerService.fetchBankName(ifscCode);
      const { bank_name } = response.data.data;
      setBankName(bank_name);
      return bank_name;
    } catch {
      setIsIfscCodeValid(false);
    }
  }

  const fetchAccountHolderName = async () => {
    try {
      const response = await partnerService.fetchAccountHolderName(ifscCode, accountNumber);
      const { name_at_bank } = response.data.data;
      setAccountHolderName(name_at_bank);
      setIsAccountNumberValid(true);
      return accountHolderName;
    } catch {
      setIsAccountNumberValid(false);
    }
  }

  const submitProfileUpdateRequests = (name: string, holderName: string) => {
    const payload = {
      "partner_profile_update_request_type": 'BANK_ACCOUNT_DETAILS',
      "data": {
        "partner_bank_ifs_code": ifscCode,
        "partner_bank_name": name,
        "partner_bank_account_number": accountNumber,
        "partner_bank_account_holder_name": holderName  
      }
    }

    partnerService.updatePartnerProfileRequests(payload).then((response) => {
    }).catch((error: any) => {
      toaster.show(true, error?.response?.data?.message);
    })
  }

  const prevClick = () => {
    navigate(`/partner-profile`)
  }

  return (
    <div className='p-4'>
      <div className="follow-us">
        <div className="heading mb-4">My Profile</div>
        <div className="card">
          <div className="card-body">

            <div className='profile-form-card'>
              <div className='profile-basic-details'>
                <div className='profile-header mt-4 font-weight-bold'>
                  Bank details update
                </div>
                <div className="info-icon-containor mt-4">
                  <InfoIcon /> Note : KYC being collected is as per RBI guidelines.
                </div>
                <div className="row mt-4">
                  <div className="col-md-4 col-sm-6 mb-4">
                    <TextInput
                      value={ifscCode}
                      heading={'IFSC Code'}
                      placeholder={'IFSC'}
                      handleInput={handleInput}
                      identifier={'ifsc'}
                      supportiveTextRed={isFormSubmitted && !isIfscCodeValid && 'Please enter valid IFS code.'}
                      supportiveTextGreen={bankName}
                      isCompulsoryField={true}
                      disabled={inputDisabled.includes(partnerInfo?.qc_status)}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 mb-4">
                    <TextInput
                      heading={'Account Number'}
                      value={accountNumber}
                      placeholder={'Account Number'}
                      handleInput={handleInput}
                      identifier={'account_number'}
                      supportiveTextRed={isFormSubmitted && !isAccountNumberValid && 'Please enter valid account number.'}
                      supportiveTextGreen={accountHolderName}
                      isCompulsoryField={true}
                      disabled={inputDisabled.includes(partnerInfo?.qc_status)}
                    />
                  </div>
                </div>
                <div>
                  <FormButton
                    showPrevButton={true}
                    onPrevClick={prevClick}
                    showNextButton={true}
                    onNextClick={nextClick}
                    isLastPage={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankProfileUpdates;