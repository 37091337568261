const initialState ={
  data: ''
}

export const CustomerDetailsLOSReducer = (state = initialState, action) => {
  switch(action.type){
    case 'LOS_DETAILS':
      return{
        ...state,
        data: action.payload
      }
    default: 
      return state
  }
}