import React from "react";
import Col from "react-bootstrap/Col";
import "./cashflow-summary.scss";

function CashflowSummary(cashflow_data) {
  return (
    <div style={{ padding: "10px" }}>
      <span>Cashflow Summary</span>
      <div style={{ display: "flex", gap: "5px", padding: "5px" }}>
        <Col className="sub-outer-box">
          <div>
            <div style={{ margin: "10px" }}>Purchase Details</div>
            <div className="hdng-container">
              <Col xs={8}>
                <span className="first-col-style">ID</span>
              </Col>
              <Col class="col-md">Qty</Col>
              <Col class="col-md">Price/Unit</Col>
            </div>
            <div>
              <div>
                {cashflow_data["cashflow_data"]["buy_items"].map(
                  (row, index) => (
                    <div className="trns-cntnt">
                      <Col xs={8}>
                        <span className="first-col-style">{row["name"]}</span>
                      </Col>
                      <Col class="col-md">{row["quantity"]}</Col>
                      <Col class="col-md">₹{row["price_per_unit"]}</Col>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col className="sub-outer-box">
          <div>
            <div style={{ margin: "10px" }}>Sell Details</div>
            <div className="hdng-container">
              <Col xs={8}>
                <span className="first-col-style">Item</span>
              </Col>
              <Col class="col-md">Qty.</Col>
              <Col class="col-md">Price/Unit</Col>
            </div>
            <div>
              <div>
                {cashflow_data["cashflow_data"]["sell_items"].map(
                  (row, index) => (
                    <div className="trns-cntnt">
                      <Col xs={8}>
                        <span className="first-col-style">{row["name"]}</span>
                      </Col>
                      <Col class="col-md">{row["quantity"]}</Col>
                      <Col class="col-md">₹{row["price_per_unit"]}</Col>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="sub-outer-box cashflow-price-box cashflow-pdng-first">
            <div>
              <span>₹{cashflow_data["cashflow_data"]["profit"]}</span>
              <span>
                {" "}
                ({cashflow_data["cashflow_data"]["profit_percentage"]}%)
              </span>
            </div>
            <div>Profit</div>
          </div>
          <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
            <Col>
              <div className="sub-outer-box cashflow-price-box cashflow-pdng-second">
                <div>{cashflow_data["cashflow_data"]["occupation_margin"]}%</div>
                <div>Standard</div>
                <div>Occupation Margin</div>
              </div>
            </Col>
            <Col>
              <div className="sub-outer-box cashflow-price-box cashflow-pdng-second">
                <div>
                  +{cashflow_data["cashflow_data"]["income_deviation"]}%
                </div>
                <div>Deviation from</div>
                <div>Declared Income</div>
              </div>
            </Col>
          </div>
        </Col>
      </div>
    </div>
  );
}

export default CashflowSummary;
