import "./loansummary-screen.scss";
import {
  DisabledCircle,
  GreenDot,
  MoneyBag,
  OrangeDot,
  RightArrow,
} from "../../../../assets/svg";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../services/api/apiService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoanSummaryHeader from "../../loan-summary/LoanSummaryHeader";
import InstallmentBar from "../../loan-summary/InstallmentBar";
import { currentAction } from "../../../../store/actions/customer-application/offerAction";
import { useDispatch, useSelector } from "react-redux";
import { Mixpanel } from "../../../../routing/MixPannelWrapper";

const LoanSummaryScreen = () => {
  const apiService = new ApiService();
  const navigate = useNavigate();
  const location = useLocation();
  const { loanData, mobile, name, email } = location.state || {};
  const [offered, setOffered] = useState<any>({});
  const dispatch = useDispatch();
  const { partnerSlug } = useParams();

  const handleTrackApplication = () => {
    dispatch(currentAction("track_application"));
    navigate(`/${partnerSlug}/session/${mobile}/track_application`);
  };

  const [loanMap, setLoanMap] = useState([]);

  useEffect(() => {
    if (loanData) {
      let map = [];
      Object.keys(loanData).forEach((key) => {
        let x = {};
        const loan = loanData[key];
        x[loan.loan_uid] = loan;
        map.push(loan);
      });
      setLoanMap(map);
    }
    openJourneyLink();
  }, []);

  useEffect(() => {
    loanMap.forEach((value, key) => {
      const cohort_uid = value.cohort_uid;
      const customerCode = value.customer_code;
      apiService
        .getNewOffer(customerCode, cohort_uid)
        .then((res) => {
          setOffered(res?.data);
        })
        .catch((error) => {
          console.error("Error fetching offer for cohort");
        });
    });
  }, []);

  const handleOfferLink = () => {
    dispatch(currentAction("journey_wizard"));
    navigate(`/${partnerSlug}/session/${mobile}/journey_wizard`);
  };

  const openJourneyLink = () => {
    if (
      (sessionStorage.getItem("utm_source") &&
        sessionStorage.getItem("utm_medium") &&
        sessionStorage.getItem("utm_campaign") === "repeat-closed") !== null
    ) {
      Mixpanel.identify(
        mobile +
          "-" +
          sessionStorage.getItem("utm_source") +
          "-" +
          sessionStorage.getItem("utm_medium") +
          "-" +
          sessionStorage.getItem("utm_campaign") +
          "-" +
          localStorage.getItem("Device")
      );
      Mixpanel.track("Repeat-Onboarding Fulfillment", "");
      Mixpanel.people.set({
        $first_name: name,
      });
    } else if (
      (sessionStorage.getItem("utm_source") &&
        sessionStorage.getItem("utm_medium") &&
        sessionStorage.getItem("utm_campaign") === "top-up") !== null
    ) {
      Mixpanel.identify(
        mobile +
          "-" +
          sessionStorage.getItem("utm_source") +
          "-" +
          sessionStorage.getItem("utm_medium") +
          "-" +
          sessionStorage.getItem("utm_campaign") +
          "-" +
          localStorage.getItem("Device")
      );
      Mixpanel.track("TopUp-Onboarding Fulfillment", "");
      Mixpanel.people.set({
        $first_name: name,
      });
    }
    window.open(offered?.journey_link, "_self");
  };

  return (
    <div>
      <LoanSummaryHeader />
      <div>
        <button
          className="hii"
          style={{ fontSize: "1.4rem" }}
          onClick={() =>
            navigate(`/customerprofile`, {
              state: {
                mobile: mobile,
                name: name,
                email: email,
                loanData: loanData,
              },
            })
          }
        >
          {name.charAt(0)}
        </button>
      </div>
      <div className="home_screen">
        <div className="scrollable_loan">
          {["offered"].includes(offered?.status) && (
            <div className="loan_detail">
              <div className="repeat">
                <div className="sub-heading p-3">Congratulations</div>
                <div className="label px-3">
                  You are eligible for a loan upto
                </div>
                <div className="amount p-3">₹ 50,000</div>
                <div className="d-flex justify-content-between">
                  <div className="p-3">
                    <div className="label">Complete Your Loan Application</div>
                    <button
                      className="continue-button px-3 py-2 mt-3"
                      onClick={openJourneyLink}
                    >
                      Start
                    </button>
                  </div>
                  <div className="d-flex">
                    <MoneyBag />
                  </div>
                </div>
              </div>
            </div>
          )}
          {loanMap.map((value, key) => (
            <div key={key}>
              {["requested"].includes(value["status"]) && (
                <div className="loan_detail">
                  <div className="repeat">
                    <div className="sub-heading p-3">Congratulations</div>
                    <div className="label px-3">
                      You are eligible for a loan upto
                    </div>
                    <div className="amount p-3">
                      ₹{value["amount_requested"]}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="p-3">
                        <div className="label">
                          Complete Your Loan Application
                        </div>
                        <button
                          className="continue-button px-3 py-2 mt-3"
                          onClick={handleOfferLink}
                        >
                          Continue
                        </button>
                      </div>
                      <div className="d-flex">
                        <MoneyBag />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {["disbursed", "partially_repaid"].includes(value["status"]) && (
                <div className="loan_detail">
                  <div className="inner-card active">
                    <div className="d-flex justify-content-between p-3">
                      <div>
                        <label className="sub-heading">Loan Id</label>
                        <span className="loan-uid ms-2">
                          {value["loan_uid"]}
                        </span>
                      </div>
                      <div className="status px-1 d-flex">
                        <div className="status-svg me-2">
                          <GreenDot />
                        </div>
                        <div>Active</div>
                      </div>
                    </div>
                    <div className="px-3 amount">
                      ₹{value["amount_requested"]}
                    </div>
                    <div className="installment p-3 pt-2">
                      <label className="text">Installment Amount</label>
                      <span className="mx-2 value">
                        {value["paid_installment"] || 0}/
                        {value["number_of_installments"]}
                      </span>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                          <span className="text">Paid</span>
                          <span className="mx-2 value">
                            ₹{value["amount_paid"]}
                          </span>
                        </div>
                        <div>
                          <span className="text">Due</span>
                          <span className="mx-2 value">
                            ₹{value["amount_due"]}
                          </span>
                        </div>
                      </div>
                      <InstallmentBar
                        paid={parseInt(value["amount_paid"])}
                        due={parseInt(value["amount_due"])}
                      />
                    </div>
                    <div
                      className="view-button"
                      onClick={() => {
                        const customer_ucic = value["customer_code"];
                        Mixpanel.identify(
                          mobile +
                            "-" +
                            customer_ucic +
                            "-" +
                            localStorage.getItem("Device")
                        );
                        Mixpanel.track("Visits-Loan Summary Section", "");
                        Mixpanel.people.set({
                          $first_name: name,
                        });
                        navigate(`/loanscreen`, {
                          state: {
                            loan_uid: value["loan_uid"],
                            paid_installment: value["paid_installment"],
                            name: name,
                            mobile: mobile,
                          },
                        });
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>
              )}
              {[
                "accepted",
                "disbursal_put_on_hold",
                "disbursal_approved",
                "disbursal_approved",
                "kyc_on_hold",
                "lender_approved",
                "kyc_verified",
                "re_kyc",
              ].includes(value["status"]) && (
                <div className="loan_detail">
                  <div className="inner-card processing">
                    <div className="d-flex justify-content-between p-3">
                      <div>
                        <label className="sub-heading">Loan Id</label>
                        <span className="loan-uid ms-2">
                          {value["loan_uid"]}
                        </span>
                      </div>
                      <div className="status px-1 d-flex">
                        <div className="status-svg me-2">
                          <OrangeDot />
                        </div>
                        Processing
                      </div>
                    </div>
                    <div className="px-3 amount">
                      ₹{value["amount_requested"]}
                    </div>
                  </div>
                  <div>
                    <div className="installment p-3">
                      <span className="text">
                        Your application is under process.
                      </span>
                    </div>
                    <div className="view-button">
                      <div
                        className="application-box px-3 py-2"
                        onClick={() =>
                          navigate(`/trackapplication`, {
                            state: { loan_uid: value["loan_uid"] },
                          })
                        }
                      >
                        Track Application
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="banner mt-3">
          <img
            src="https://arth-banner.s3.ap-south-1.amazonaws.com/Udhyam+Banner+(1).png"
            style={{ height: "9rem", borderRadius: "12px" }}
          ></img>
        </div>
        <br></br>
        {loanMap.map((value, key) => (
          <div key={key}>
            {["closed", "fully_repaid"].includes(value["status"]) && (
              <div className="main-heading my-3">
                {" "}
                Previous loan
                <div className="loan_detail">
                  <div className="inner-card closed">
                    <div className="d-flex justify-content-between p-3">
                      <div>
                        <label className="sub-heading">Loan Id</label>
                        <span className="loan-uid ms-2">
                          {value["loan_uid"]}
                        </span>
                      </div>
                      <div className="status px-1 d-flex">
                        <div className="status-svg me-2">
                          <DisabledCircle />
                        </div>
                        <div>Closed</div>
                      </div>
                    </div>
                    <div className="px-3 amount">Amount</div>
                    <div className="installment p-3">
                      <label className="text">Installment Amount</label>
                      <span className="mx-2 value">
                        {value["paid_installment"]}/
                        {value["number_of_installments"]}
                      </span>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                          <span className="text">Paid</span>
                          <span className="mx-2 value">
                            {value["amount_paid"]}
                          </span>
                        </div>
                        <div>
                          <span className="text">Due</span>
                          <span className="mx-2 value">
                            {value["amount_due"]}
                          </span>
                        </div>
                      </div>
                      <InstallmentBar
                        paid={parseInt(value["amount_paid"])}
                        due={parseInt(value["amount_due"])}
                      />
                    </div>
                    <div
                      className="view-button"
                      onClick={() => {
                        const customer_ucic = value["customer_code"];
                        const loanID = value["loan_uid"];
                        Mixpanel.identify(
                          mobile +
                            "-" +
                            customer_ucic +
                            "-" +
                            loanID +
                            "-" +
                            localStorage.getItem("Device")
                        );
                        Mixpanel.track("Visits-Loan Summary Section", "");
                        Mixpanel.people.set({
                          $first_name: name,
                        });
                        navigate(`/loanscreen`, {
                          state: {
                            loan_uid: value["loan_uid"],
                            paid_installment: value["paid_installment"],
                            name: name,
                            mobile: mobile,
                          },
                        });
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoanSummaryScreen;