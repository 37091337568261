import React from "react";
import BusinessPhoto from "../BusinessPhoto/BusinessPhoto";

function BusinessKYC({ businessData }) {
  const kycResponse = {
    "Udhyam Adhar Details": {
      "Udhyam Aadhar": businessData?.udyam_aadhar_details?.udyam_aadhar,
    },
    "GSTIN Details": {
      GSTIN: businessData?.gstin_details?.gstin,
    },
  };
  return (
    <div className="kyc-container">
      <span>KYC DETAILS</span>
      <div className="kyc-content-box">
        <div class="col-md kyc-container">
          <span>Udyam Aadhar Details</span>
          <div>
            <div>
              <div class="col-md">
                <div class="row">
                  <div class="col-md basic-left-body">Udyam Aadhar</div>
                  <div class={`col-md basic-right-body`}>
                    {businessData?.udyam_aadhar_details?.udyam_aadhar}
                  </div>
                </div>
                {businessData?.udyam_aadhar_details?.udyam_aadhar ? (
                  <>
                    <div class="col-md-auto basic-left-body"></div>
                    <div class="col-md basic-right-body pan-name">Verified</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md kyc-container">
          <span>GSTIN Details</span>
          <div style={{ display: "flex" }}>
            <div>
              <div class="col-md-auto">
                <div class="row">
                  <div class="col-md-auto basic-left-body">GSTIN</div>
                  <div class={`col-md basic-right-body`}>
                    {businessData?.gstin_details?.gstin}
                  </div>
                </div>
                {businessData?.gstin_details?.gstin_document &&
                <div class="row">
                  {/* <BusinessPhoto imageUrl={businessData?.gstin_details?.gstin_document} /> */}
                  <div class="col-md-auto basic-left-body">Document</div>
                            <div class={`col-md basic-right-body`}>
                              <a
                                href={businessData?.gstin_details?.gstin_document}
                                target="_blank"
                                style={{ color: "#EE3E80" }}
                              >
                                Click Here
                              </a>
                            </div>
                </div>
                }
                {businessData?.gstin_details?.gstin ? (
                  <>
                    <div class="col-md-auto basic-left-body"></div>
                    <div class="col-md basic-right-body pan-name">Verified</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessKYC;
