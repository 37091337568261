import { NextButtonSVG } from '../../../assets/svg';
import './button.scss';

const NextButton = ({onNextClick, isLastPage}) => {

  const lastPagestyle = {
    background: '#10B759',
    width: '5.125rem',
    color: 'white',
    padding: '0.65rem 1rem'
  };

  const style = {
    background: '#EE3E80',
    width: '4.5rem',
    padding: '0.4375rem 1.5rem'
  };

  return (
    <div className='next-button' style={isLastPage ? lastPagestyle : style} onClick={onNextClick}>
      {isLastPage ? <span>Finish</span> : <NextButtonSVG />}
    </div>
  )
}

export default NextButton;
