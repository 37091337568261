import { Modal } from "react-bootstrap";
import "./action-buttons.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Toaster } from "../../../../utils/utilities/Toaster";
function ActionButtons({ status, setAction, userData, productCode, amount, processingFee, emi, interestRate, tenure }) {
  const [holdShow, setHoldShow] = useState(false);
  const navigate = useNavigate();
  const handleHoldClose = () => setHoldShow(false);
  const handleHoldShow = () => setHoldShow(true);

  const [approveShow, setApproveShow] = useState(false);
  const handleApproveClose = () => setApproveShow(false);
  const handleApproveShow = () => setApproveShow(true);

  const [rejectShow, setRejectShow] = useState(false);
  const handleRejectClose = () => setRejectShow(false);
  const handleRejectShow = () => setRejectShow(true);

  const [holdReason, setHoldReason] = useState();
  const [rejectReason, setRejectReason] = useState();

  const [holdDesc, setHoldDesc] = useState("");
  const [rejectDesc, setRejectDesc] = useState("");

  const takeAction = async (decision) => {
    const toaster = new Toaster();
    const url = new URL(
      process.env.REACT_APP_ICE_BASE_URL +
        "/ice-decision-update/" +
        userData?.basic_details?.id
    );
    try {
      let remark = "Accepted";
      if (decision === "On Hold") {
        if (!holdReason) {
          toaster.show(true, "Please select one option!");
          return;
        }
        remark = holdReason === "Other" ? holdDesc : holdReason;
      } else if (decision === "Rejected") {
        if (!rejectReason) {
          toaster.show(true, "Please select one option!");
          return;
        }
        remark = rejectReason === "Other" ? rejectDesc : rejectReason;
      }

      if (remark === "") {
        toaster.show(true, "Please provide remark!");

        return;
      }

      const request_data = {
        status: decision,
        _remark: remark,
        _open_ts: new Date(),
      };


      if (decision === "Accepted") {
        request_data["product_code"] = productCode
        request_data["amount"] = amount
        request_data["logic_product"] = null
        request_data["paymint_opted"] = false
        request_data["processing_fees"] = processingFee
        request_data["emi"] = emi
        request_data["monthly_interest"] = interestRate
        request_data["tenure"] = tenure        
      } 

      const config = {
        headers: { Authorization: process.env.REACT_APP_ICE_AUTHORIZATION },
      };
      const data = await axios.put(url, request_data, config);

      if (data.status === 202) {
        alert(decision + " action succesfully taken.");
        setAction(decision + userData?.basic_details?.id);
        if (decision === "On Hold") {
          handleHoldClose();
        } else if (decision === "Accepted") {
          handleApproveClose();
        } else {
          handleRejectClose();
        }
        navigate("/ice/customers");
      }
    } catch (error) {
      toaster.show(true, error?.response?.data?.message)
      console.log(error);
    }
  };

  let button_style = {
    backgroundColor: "#FAFBFD",
    width: "120px",
    borderColor: "#D5D5D5",
    cursor: "pointer",
  };
  let hold_style = {
    backgroundColor: "#FAFBFD",
    width: "120px",
    color: "#F1B300",
    borderColor: "#D5D5D5",
    cursor: "pointer",
  };

  let reject_style = {
    backgroundColor: "#FAFBFD",
    width: "120px",
    color: "red",
    borderColor: "#D5D5D5",
  };

  let approve_style = {
    backgroundColor: "#FAFBFD",
    width: "120px",
    color: "#10B759",
    borderColor: "#D5D5D5",
  };

  if (status === "Approve" || status === "Reject") {
    return <></>;
  }

  const rejectOptions = [
    "Bureau Track Record (Defaults/Writoffs)",
    "High Active Loan",
    "Low credit Score",
    "High outstanding",
    "Temporary Establishment",
    "Low Business Stock",
    "No Business Proof",
    "Both rented",
    "Business Loan Requirement not clear",
    "Low income",
    "Low vintage of the business",
    "Age<24",
    "Age>58",
    "Customer Not Interested",
    "Low Eligiblity",
    "Other",
  ];

  const holdOptions = [
    "Business Photos and Video",
    "Customer connect pending",
    "Document Pending",
    "Other",
  ];

  return (
    <div style={{ marginLeft: "auto" }}>
      <div className="action-buttons">
        {/* HOLD LOGIC */}
        <Modal show={holdShow} onHide={handleHoldClose}>
          <div style={{ padding: "10px" }}>
            <div className="action-hdr">You Want to Hold?</div>

            <div>Reason</div>
            <div>
              <select
                class="form-select form-select-md"
                style={{ borderRadius: "10px", color: "#3E3E3E" }}
                onChange={(key) => setHoldReason(key.target.value)}
              >
                <option selected style={{ color: "#B0B0B0 !important" }}>
                  Select
                </option>
                {holdOptions.map((key) => (
                  <option value={key}>{key}</option>
                ))}
              </select>
            </div>
            {holdReason === "Other" && (
              <div style={{ marginTop: "10px" }}>
                <div>Describe</div>
                <textarea
                  style={{
                    border: "solid",
                    borderWidth: "1px",
                    borderColor: "#D5D5D5",
                    borderRadius: "10px",
                    height: "100px",
                    width: "475px",
                  }}
                  placeholder="Describe"
                  onChange={(key) => setHoldDesc(key.target.value)}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <div onClick={handleHoldClose} className="btn-pointer-class">
                <button
                  type="button"
                  disabled
                  className="btn btn-sm"
                  style={button_style}
                >
                  Cancel
                </button>
              </div>
              <div
                className="btn-pointer-class hold-hover"
                onClick={() => takeAction("On Hold")}
              >
                <button
                  type="button"
                  disabled
                  className="btn btn-sm"
                  style={hold_style}
                >
                  Hold
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* APPROVE LOGIC */}
        <Modal show={approveShow} onHide={handleApproveClose}>
          <div style={{ padding: "10px" }}>
            <div className="action-hdr">You Want to Approve?</div>

            <div className="btn-pointer-class">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
                onClick={handleApproveClose}
              >
                <button
                  type="button"
                  disabled
                  className="btn btn-sm"
                  style={button_style}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-sm approve-hover"
                  style={approve_style}
                  onClick={() => takeAction("Accepted")}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* REJECT LOGIC */}
        <Modal show={rejectShow} onHide={handleRejectClose}>
          <div style={{ padding: "10px" }}>
            <div className="action-hdr">You Want to Reject?</div>

            <div>Reason</div>
            <div>
              <select
                class="form-select form-select-md"
                style={{ borderRadius: "10px", color: "#3E3E3E" }}
                onChange={(key) => setRejectReason(key.target.value)}
              >
                <option selected style={{ color: "#B0B0B0 !important" }}>
                  Select
                </option>
                {rejectOptions.map((key) => (
                  <option value={key}>{key}</option>
                ))}
              </select>
              {rejectReason === "Other" && (
                <div style={{ marginTop: "10px" }}>
                  <div>Describe</div>
                  <div
                    style={{
                      border: "solid",
                      borderWidth: "1px",
                      borderColor: "#D5D5D5",
                      borderRadius: "10px",
                      height: "100px",
                    }}
                  >
                    <input
                      placeholder="Describe"
                      onChange={(key) => setRejectDesc(key.target.value)}
                    />
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="btn-pointer-class" onClick={handleRejectClose}>
                  <button
                    type="button"
                    disabled
                    className="btn btn-sm"
                    style={button_style}
                  >
                    Cancel
                  </button>
                </div>
                <div
                  className="btn-pointer-class"
                  onClick={() => takeAction("Rejected")}
                >
                  <button
                    type="button"
                    // disabled
                    className="btn btn-sm reject-hover"
                    style={reject_style}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {status !== "On Hold" && (
          <div
            className="action-style hold-style left-most"
            onClick={handleHoldShow}
          >
            Hold
          </div>
        )}
        <div
          className={`action-style approve-style ${
            status === "On Hold" && "left-most"
          }`}
          onClick={handleApproveShow}
        >
          Approve
        </div>
        <div className="action-style reject-style" onClick={handleRejectShow}>
          Reject
        </div>
      </div>
    </div>
  );
}

export default ActionButtons;
