import React, { useState } from "react";
import "./help-support.scss";
import LoanSummaryHeaderPWA from "../credits/loansummaryHeader2";
import { PinkChevron } from "../../../../assets/svg";

const ProfileChangeSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const knowMoreClicked = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "    How can I change my Mobile Number or Email Id? ",
      answer: (
        <>
          <p>
            Please contact our Customer Support on 9044052255 or email us at
            <span>&nbsp;</span>
            <a href="support@arthimpact.in">support@arthimpact.in</a>. We will
            get back to you shortly to help you with the change of your mobile
            number, email Id or address.
          </p>
        </>
      ),
    },
    {
      question: "    How can I change my Address?   ",
      answer: (
        <>
          <p>
            {" "}
            You can change the address at your convenience however you have to
            provide the necessary Proof of Address for verification of your new
            address.{" "}
          </p>
          <p>
            Please contact our Customer Support on 9044052255 or email us at{" "}
            <span>&nbsp;</span>
            <a href="support@arthimpact.in">support@arthimpact.in</a>. We will
            get back to you shortly to help you with the change of your mobile
            number, email Id or address.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <LoanSummaryHeaderPWA />
      <div className="payments-help-section">
        <div className="payments-help-section-content">
          {faqItems.map((item, index) => (
            <React.Fragment key={index}>
              <div className="payments-help-section-content-questions" style={{display:'grid', gridTemplateColumns:'1fr auto'}}>
                <div style={{ textAlign: "left", fontSize: "0.95rem" }}>
                  {item.question}
                </div>
                <div
                  style={
                    openIndex === index
                      ? { textAlign: "right" }
                      : {
                          transform: "rotate(180deg)",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => knowMoreClicked(index)}
                >
                  <PinkChevron />
                </div>
              </div>
              {openIndex === index && (
                <div className="payments-help-section-content-answers" style={{fontSize:'0.85rem', lineHeight: "18px"}}>
                  {item.answer}
                </div>
              )}
              <div className="fnQ-breakLine"></div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProfileChangeSection;
