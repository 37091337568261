import React, { useEffect, useState } from 'react';
import trackApplication from '../../../../assets/images/Track_application.json';
import Lottie from "lottie-react";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../../components/customer-application/headers/Header';
import { ArthLogo, BackButton, WhiteCheck } from '../../../../assets/svg';
import { ApiService } from '../../../../services/api/apiService';
import './track-application-pwa.scss';
import ButtonFooter from '../../../../components/customer-application/button-footer/ButtonFooter';
import AddArthToHomeScreen from '../../shortcut/Shortcut';
import CTA from '../../../../components/customer-application/installationcta/CTA';

const TrackApplicationPWA = () => {
  const apiService = new ApiService();
  const [currentActive, setCurrentActive] = useState('Application Submitted');
  const [currentActive2, setCurrentActive2] = useState('');
  const [currentActive3, setCurrentActive3] = useState('');
  const [currentActive4, setCurrentActive4] = useState('');
  const [isDisbursed, setIsDisbursed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { loan_uid } = location.state || {};

  useEffect(() => {
    const fetchApplicationStatus = async () => {
      try {
        if (loan_uid) {
          const response = await apiService.fetchAllLoanDetails(loan_uid);
          const status = response?.data?.loan?.status;
          console.log(status)
          if (status === 'accepted') {
            setCurrentActive('Application Submitted');
          } else if (status === 'kyc_on_hold' || status === 'kyc_verified') {
            setCurrentActive2('Application Verification')
          } else if (status === 'disbursal_put_on_hold' || status === 'disbursal_approved' || status === 'disbursal_initiated') {
            setCurrentActive3('Ready for Disbursal');
            setCurrentActive2('Application Verification')
          } else if (status === 'partially_repaid' || status === 'disbursed') {
            setCurrentActive4('Disbursed');
            setCurrentActive3('Ready for Disbursal');
            setCurrentActive2('Application Verification')
            setIsDisbursed(true);
          }
        }
      } catch (error) {
        console.error('Error fetching application status:', error);
      }
    };
    fetchApplicationStatus();
  }, []);

  const handleCheckLoanSummary = () => {
      checkLoanSummary();
  }



  const checkLoanSummary = () => {
    const loan_uid = localStorage.getItem('loan_uid');
    navigate(`/pwa?loan_uid=${loan_uid}`);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <Header />
      <div className="blurr-div thank-you-page">
        <div className="containerpwa" style={{padding:'2rem'}}>
          <div onClick={goBack}>
            <BackButton/> 
          </div>
          <div style={{marginLeft:'40%'}}>
            <ArthLogo />
          </div>
        </div>
        <Lottie animationData={trackApplication} loop={true} />
        <div className='status-heading'>
          {currentActive === 'Application Verification' && 'Your Application is being Verified'}
          {currentActive === 'Disbursed' && 'Your Application is Disbursed'}
        </div>
        <div className='application-progress pt-3 ps-2 mt-5'>
          <div className='sub-heading'>
            Your application progress
          </div>
          <div className='mt-3'>
            <div className={`text-wrap ${currentActive === 'Application Submitted' ? "active" : ""}`}>
              <div className="circle">{['Application Submitted', 'Application Verification', 'Ready for Disbursal', 'Disbursed'].includes(currentActive) && <WhiteCheck /> }</div>
              <p className={`text ${currentActive === 'Application Submitted' && "completed-step" }`}>
                Application Submitted
              </p>
            </div>
            <div className={`text-wrap ${currentActive2 === 'Application Verification' ? "active" : ""}`}>
              <div className="circle">{['Application Verification', 'Ready for Disbursal', 'Disbursed'].includes(currentActive2) && <WhiteCheck /> }</div>
              <p className={`text ${currentActive2 === 'Application Verification' ? "completed-step" : "incomplete-step"}`}>
                Application Verification
              </p>
            </div>
            <div className={`text-wrap ${currentActive3 === 'Ready for Disbursal' ? "active" : ""}`}>
              <div className="circle">{['Ready for Disbursal', 'Disbursed'].includes(currentActive3) && <WhiteCheck />}</div>
              <p className={`text ${currentActive3 === 'Ready for Disbursal' ? "completed-step" : "incomplete-step"}`}>
                Ready for Disbursal
              </p>
            </div>
            <div className={`text-wrap ${currentActive4 === 'Disbursed' ? "active" : ""}`}>
              <div className="circle">{currentActive4 === 'Disbursed' && <WhiteCheck />}</div>
              <p className={`text ${currentActive4 === 'Disbursed' ? "completed-step" : "incomplete-step"}`}>
                Disbursed
              </p>
            </div>
          </div>
          {(currentActive4 === 'Disbursed') && (
            <div>
              <ButtonFooter buttonContent={'Check Loan Summary'} onClick={() => handleCheckLoanSummary()}/>
            </div>
          )}
        </div>
      </div>
    </>
  );  
};

export default TrackApplicationPWA;