import { useLocation, useNavigate } from "react-router-dom";
import {
  ArthLogo,
  BA,
  HomeScreenSVG,
  Insurance,
  LB,
  MIA,
} from "../../../../assets/svg";
import "./dashboard.scss";

const UdyamDashBoard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    mobile,
    name,
    email,
    loanData,
    active_loans,
    transaction_amount,
    rewards,
  } = location.state || {};

  const customer_name = name.split(" ");

  const profilePage = () => {
    navigate(`/arth/profile`, {
      state: {
        mobile: mobile,
        name: name,
        email: email,
        loanData: loanData,
      },
    });
  };

  return (
    <>
      <div className="udyam-dashboard">
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
          <ArthLogo />
          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
            <span
              style={{
                textAlign: "right",
                marginTop: "20px",                
              }}
            >
              Hi {customer_name[0]}
            </span>
            <span style={{ textAlign: "right" }}>
              <button className="udyam-profile-icon" onClick={profilePage}>
                {name.charAt(0) || ""}
              </button>
            </span>
          </div>
        </div>
        {/* <div className="udyam-username">Hi {name}!</div> */}
        <div className="card user-info-card">
          <div className="udyam-user-info">
            <div>
              <HomeScreenSVG />
            </div>
            <div>
              <div className="udyam-user-info-content">
                <b className="udyam-user-info-content-text">Active Loan</b> :{" "}
                <b>{parseInt(active_loans) || 0}</b>
              </div>
              <div className="udyam-user-info-content">
                <b className="udyam-user-info-content-text">Transactions</b> : ₹{" "}
                <b>{parseInt(transaction_amount) || 0}</b>
              </div>
              <div className="udyam-user-info-content">
                <b className="udyam-user-info-content-text">Rewards</b> : ₹{" "}
                <b>{parseInt(rewards) || 0}</b>
              </div>
            </div>
          </div>
        </div>
        <div className="promotional-banner">
          <img
            src="https://arth-banner.s3.ap-south-1.amazonaws.com/Udhyam+Banner+(1).png"
            style={{ height: "9rem", borderRadius: "12px" }}
          ></img>
        </div>
        <div className="fieldset card">
          <h1>
            <span>Business Prime</span>
          </h1>
          <div className="nav-row">
            <div>
              <p className="fieldset-icon-alignment">
                <MIA className="nav-icon-size" />
                <div
                  style={{
                    border: "1px solid #fef0e4",
                    padding: "2px",
                    borderRadius: "10px",
                  }}
                >
                  Demat Account
                </div>
              </p>
            </div>
            <div>
              <p className="fieldset-icon-alignment">
                <BA className="nav-icon-size" />
                <div
                  style={{
                    border: "1px solid #fef0e4",
                    padding: "2px",
                    borderRadius: "10px",
                  }}
                >
                  Business Account
                </div>
              </p>
            </div>
          </div>
          <div className="nav-row">
            <div>
              <p className="fieldset-icon-alignment">
                <LB className="nav-icon-size" />
                <div
                  style={{
                    border: "1px solid #fef0e4",
                    padding: "2px",
                    borderRadius: "10px",
                  }}
                >
                  Payment Device
                </div>
              </p>
            </div>
            <div>
              <p className="fieldset-icon-alignment">
                <Insurance className="nav-icon-size" />
                <div
                  style={{
                    border: "1px solid #fef0e4",
                    padding: "2px",
                    borderRadius: "10px",
                  }}
                >
                  Insurance 360
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UdyamDashBoard;
