import { ArthLogo, BottomLeftOval, BottomRightOval, TopLeftOval, TopRightOval } from "../../../assets/svg";
import "./login.scss";



function LogInBackground() {
    return (
        <>
            <div className="logIn-background">
                <img src="https://arth-banner.s3.ap-south-1.amazonaws.com/Rectangle+4+1.png" className="ntl-img" />
                <div className="topRigtOval">
                    <TopRightOval />
                </div>
                <div className="topLeftOval">
                    <TopLeftOval />
                </div>
                <div className="bottomLeftOval">
                    <BottomLeftOval />
                </div>
                <div className="bottomRightOval">
                    <BottomRightOval />
                </div>
                <div className="arth-login-logo">
                    <ArthLogo />
                </div>
            </div>
        </>
    )
}

export default LogInBackground;