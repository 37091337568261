import { BlueCircle, PinkCircle, ZigZagPinkCircle } from "../../../assets/svg";
import './headers.scss';

const Header = () => {
  return (
    <>
      <div className="blurr-images">
        <BlueCircle className='blue-circle' />
        <PinkCircle className='pink-circle' />
        <ZigZagPinkCircle className='zig-zag' />
        <span className="purple-circle"></span>
      </div>
    </>
  )
}

export default Header;
