import { useState } from "react";
import "./loandetail-screen.scss";
import LoanSummaryHeaderPWA from "./loansummaryHeader2";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BA, InfoIcon, Insurance, LB, MIA, Tick } from "../../../../assets/svg";
import { useLocation, useNavigate } from "react-router-dom";

const LoanScreen = () => {
  const [openKnowMore, setOpenKnowMore] = useState<boolean>(false);
  const displayText = useSelector((state: any) => state.language.languageText);
  const [isPopUPVisible1, setIsPopUPVisible1] = useState(false);
  const [isPopUPVisible2, setIsPopUPVisible2] = useState(false);
  const [isPopUPVisible3, setIsPopUPVisible3] = useState(false);
  const [isPopUPVisible4, setIsPopUPVisible4] = useState(false);
  const [isPopUPVisible5, setIsPopUPVisible5] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { loanDetails, loan_uid } = location.state || {};
  const feesCharges = Math.round(
    parseInt(loanDetails?.init_fee_with_gst) +
      parseInt(loanDetails?.final_fee_with_gst) +
      parseInt(loanDetails?.facilitation_fees_with_gst) +
      parseInt(loanDetails?.enach_charges_with_gst)
  ).toLocaleString();


  const repaymentSchedule = () => {
    navigate(`/repaymentSchedule`, { state: { loanDetail: loanDetails } });
  };

  const knowMoreClicked = () => {
    setOpenKnowMore(!openKnowMore);
  };
  const handleDematBox = () => {
    setIsPopUPVisible2(true);
  };
  const handleDematPopClose = () => {
    setIsPopUPVisible2(false);
  };
  const handleBA = () => {
    setIsPopUPVisible3(true);
  };
  const handleBAClose = () => {
    setIsPopUPVisible3(false);
  };
  const handlePayment = () => {
    setIsPopUPVisible4(true);
  };
  const handlePaymentClose = () => {
    setIsPopUPVisible4(false);
  };
  const handleInsurance = () => {
    setIsPopUPVisible5(true);
  };
  const handleInsuranceClose = () => {
    setIsPopUPVisible5(false);
  };

  return (
    <>
      <div
        className={` ${isPopUPVisible1 ? "blur-background" : ""} ${
          isPopUPVisible2 ? "blur-background" : ""
        } ${isPopUPVisible3 ? "blur-background" : ""} ${
          isPopUPVisible4 ? "blur-background" : ""
        } ${isPopUPVisible5 ? "blur-background" : ""}`}
      >
        <LoanSummaryHeaderPWA />
        <div className="new-loan-details-page">
          Loan Details
          <div className="new-loan-details-content">
            <div className="new-loan-details">
              <div className="new-loan-text-padding">
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">Loan ID</div>
                  <div className="new-loan-text-value">
                    {loanDetails?.loan_uid}
                  </div>
                </div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">Loan Type</div>
                  <div className="new-loan-text-value">Business Loan</div>
                </div>
                <div className="loan-details-break-Line"></div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">Tenure</div>
                  <div className="new-loan-text-value">
                    {loanDetails?.tenure}
                  </div>
                </div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">Repayment frequency</div>
                  <div className="new-loan-text-value">
                    {loanDetails?.repayment_frequency}
                  </div>
                </div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">
                    Number of Installments
                  </div>
                  <div className="new-loan-text-value">
                    {loanDetails?.number_of_installments}
                  </div>
                </div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">Installment Amount</div>
                  <div className="new-loan-text-value">
                    ₹ {(loanDetails?.installment_amount).toLocaleString()}
                  </div>
                </div>
                <div className="loan-details-break-Line"></div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">
                    Monthly Interest Rate
                    <a data-tooltip-id="my-tooltip">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-right"}>
                            Monthly Interest Rate
                          </Tooltip>
                        }
                      >
                        <InfoIcon className="question-svg" />
                      </OverlayTrigger>
                    </a>
                  </div>
                  <div className="new-loan-text-value">
                    {" "}
                    {parseInt(loanDetails?.interest_rate)}%
                  </div>
                </div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">
                    Annual Interest rate
                    <a data-tooltip-id="my-tooltip">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-right"}>
                            Annual Interest rate
                          </Tooltip>
                        }
                      >
                        <InfoIcon className="question-svg" />
                      </OverlayTrigger>
                    </a>
                  </div>
                  <div className="new-loan-text-value">
                    {parseInt(loanDetails?.interest_rate_percent)}%
                  </div>
                </div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-text-name">
                    Annual Percentage Rate
                    <a data-tooltip-id="my-tooltip">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-right"}>
                            APR is the yearly interest rate on the loan
                          </Tooltip>
                        }
                      >
                        <InfoIcon className="question-svg" />
                      </OverlayTrigger>
                    </a>
                  </div>
                  <div className="new-loan-text-value">
                    {parseInt(loanDetails?.interest_rate_percent)}%
                  </div>
                </div>
                <div className="loan-details-break-Line"></div>
                <div className="new-loan-text-alignment">
                  <div className="new-loan-fees-text-name">
                    {displayText?.fee_charges}
                    <a data-tooltip-id="my-tooltip">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-right"}>
                            Deduction from loan amount
                          </Tooltip>
                        }
                      >
                        <InfoIcon className="question-svg" />
                      </OverlayTrigger>
                    </a>
                  </div>
                  {!openKnowMore && (
                    <>
                      <div className="new-loan-fees-text-value">
                        ₹ {feesCharges}
                      </div>
                    </>
                  )}
                </div>
                {!openKnowMore && (
                  <>
                    <div className="know-more">
                      <div onClick={knowMoreClicked} className="know-more-tag">
                        View All
                      </div>
                    </div>
                  </>
                )}
                {openKnowMore && (
                  <>
                    <div className="new-loan-text-alignment">
                      <div className="new-loan-text-name">
                        {displayText?.processing_fee}
                        <a data-tooltip-id="my-tooltip">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-right"}>
                               {loanDetails?.processing_fees_description}
                              </Tooltip>
                            }
                          >
                            <InfoIcon className="question-svg" />
                          </OverlayTrigger>
                        </a>
                        <Tooltip id="my-tooltip" />
                      </div>
                      <div className="new-loan-text-value">
                        ₹{" "}
                        {parseInt(
                          loanDetails?.init_fee_with_gst
                        ).toLocaleString() || 0}
                      </div>
                    </div>
                    <div className="new-loan-text-alignment">
                      <div className="new-loan-text-name">
                        {displayText?.insurence_fee}
                        <a data-tooltip-id="my-tooltip">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-right"}>
                               0.476% of loan amount
                              </Tooltip>
                            }
                          >
                            <InfoIcon className="question-svg" />
                          </OverlayTrigger>
                        </a>
                        <Tooltip id="my-tooltip" />
                      </div>
                      <div className="new-loan-text-value">
                        ₹{" "}
                        {parseInt(loanDetails?.insurance_charges) || 0}
                      </div>
                    </div>
                    <div className="new-loan-text-alignment">
                      <div className="new-loan-text-name">
                        {displayText?.enach_charges}
                        <a data-tooltip-id="my-tooltip">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-right"}>
                                These are bank charges to setup mandate for
                                repayment
                              </Tooltip>
                            }
                          >
                            <InfoIcon className="question-svg" />
                          </OverlayTrigger>
                        </a>
                        <Tooltip id="my-tooltip" />
                      </div>
                      <div className="new-loan-text-value">
                        ₹{" "}
                        {parseInt(
                          loanDetails?.enach_charges_with_gst
                        ).toLocaleString() || 0}
                      </div>
                    </div>
                    {/* {loanDetails?.is_ntl_loan && (
                                            <div className="new-loan-text-name">
                                                Interest
                                                <a data-tooltip-id="my-tooltip">
                                                    <OverlayTrigger
                                                        key='right'
                                                        placement='right'
                                                        overlay={
                                                            <Tooltip id={'tooltip-right'} >
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <InfoIcon className='question-svg' />
                                                    </OverlayTrigger>
                                                </a>
                                                <Tooltip id="my-tooltip" />
                                            </div>
                                            <div className="new-loan-text-value">
                                                ₹ {parseInt(loanDetails?.init_interest).toLocaleString() || 0}
                                            </div>
                                    )} */}
                    <div className="value know-more">
                      <div onClick={knowMoreClicked} className="know-more-tag">
                        Hide
                      </div>
                    </div>
                  </>
                )}
                {false && (
                  <>
                    <div className="loan-details-break-Line"></div>
                    <div className="solar-device-field">
                      <div>
                        <Tick />
                      </div>
                      <div>
                        Solar Device
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Lorem Epsum"
                        >
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-right"}>
                                Inclusive of GST
                              </Tooltip>
                            }
                          >
                            <InfoIcon className="question-svg" />
                          </OverlayTrigger>
                        </a>
                      </div>
                      <div className="solar-device-field-value">₹ 1,000</div>
                    </div>
                  </>
                )}
                {false && (
                  <>
                    <div className="loan-details-break-Line"></div>
                    <div className="bp-field">
                      <div>
                        <Tick />
                      </div>
                      <div>
                        Business Prime Benefits
                        <a data-tooltip-id="my-tooltip">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-right"}>
                                Inclusive of GST
                              </Tooltip>
                            }
                          >
                            <InfoIcon className="question-svg" />
                          </OverlayTrigger>
                        </a>
                      </div>
                      <div className="bp-field-value">₹ 4,999</div>
                    </div>
                    <div className="bp-secondary-fields">
                      <div>
                        <Tick />
                      </div>
                      <div className="bp-secondary-fields-text">
                        Demant Account
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Lorem Epsum"
                          onClick={handleDematBox}
                        >
                          <InfoIcon className="question-svg" />
                        </a>
                      </div>
                    </div>
                    <div className="bp-secondary-fields">
                      <div>
                        <Tick />
                      </div>
                      <div className="bp-secondary-fields-text">
                        Business Account
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Lorem Epsum"
                          onClick={handleBA}
                        >
                          <InfoIcon className="question-svg" />
                        </a>
                      </div>
                    </div>
                    <div className="bp-secondary-fields">
                      <div>
                        <Tick />
                      </div>
                      <div className="bp-secondary-fields-text">
                        Payment Device
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Lorem Epsum"
                          onClick={handlePayment}
                        >
                          <InfoIcon className="question-svg" />
                        </a>
                      </div>
                    </div>
                    <div className="bp-secondary-fields">
                      <div>
                        <Tick />
                      </div>
                      <div className="bp-secondary-fields-text">
                        Insurance 360
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Lorem Epsum"
                          onClick={handleInsurance}
                        >
                          <InfoIcon className="question-svg" />
                        </a>
                      </div>
                    </div>
                  </>
                )}
                {false && (
                  <>
                    <div className="loan-details-break-Line"></div>
                    <div className="secure-repayment-field">
                      <div>
                        <Tick />
                      </div>
                      <div>Secure Repayment</div>
                      <div className="secure-repayment-field-value">
                        ₹ 5,000
                      </div>
                    </div>
                    <div className="secure-repayment-field-downtext">
                      <li>
                        You can secure your emi repayment incase of any
                        unforeseen circumstances.
                      </li>
                    </div>
                  </>
                )}
                {false && (
                  <>
                    <div className="loan-details-break-Line"></div>
                    <div className="credit-score-field">
                      <div>
                        <Tick />
                      </div>
                      <div>
                        Credit Score Report
                        <a data-tooltip-id="my-tooltip">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-right"}>
                                Inclusive of GST
                              </Tooltip>
                            }
                          >
                            <InfoIcon className="question-svg" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="tranfer-to-bank">
            <div className="heading1">Transfer to Bank Account</div>
            <div style={{textAlign:'right'}}>₹ {parseInt(loanDetails?.net_disbursal_amount).toLocaleString()}</div>
          </div>
          {false && (
            <>
              <div className="repayment-type">
                <div>
                  <Tick />
                </div>
                <div className="heading1">Repayment Type - Daily</div>
              </div>
            </>
          )}
          {false && (
            <>
              <div
                className="repayment-button-placement"
                onClick={repaymentSchedule}
              >
                <button className="repayment-button" type="button">
                  <p className="repayment-button-text">Repayment Schedule</p>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {isPopUPVisible2 && (
        <>
          <div className="dematContainer">
            <div className="tc8">
              <div>
                <MIA />
              </div>
              <div className="heading8">Demat Account</div>
            </div>
            <span className="text-down9 tc9">
              Open Demat Account and start your Investing journey qucikly
            </span>
            <span className="text-down10 tc9">
              <li>Open Demat Account</li>
            </span>
            <span className="text-down10 tc9">
              <li>Option to Invest in Mutual Funds</li>
            </span>
            <button className="close-button" onClick={handleDematPopClose}>
              Close
            </button>
          </div>
        </>
      )}
      {isPopUPVisible3 && (
        <>
          <div className="baContainer">
            <div className="tc9">
              <div>
                <BA />
              </div>
              <div className="heading9">Business Account</div>
            </div>
            <span className="text-down10 tc10">
              Open Business Account to facilitate transaction and get a chance
              to earn cashback
            </span>
            <span className="text-down11 tc10">
              <li>Open Business Bank Account for Free</li>
            </span>
            <span className="text-down11 tc10">
              <li>Get NFC enabled Expense Card with zero charge</li>
            </span>
            <span className="text-down11 tc10">
              <li>Enjoy Cashback on transactions</li>
            </span>
            <button className="close-button" onClick={handleBAClose}>
              Close
            </button>
          </div>
        </>
      )}
      {isPopUPVisible4 && (
        <>
          <div className="paymentContainer">
            <div className="insideContainer">
              <div className="tc9">
                <div>
                  <LB />
                </div>
                <div className="heading9">Payment Device</div>
              </div>
              <span className="text-down12">
                One Month subscription charge ₹590 Included
              </span>
              <span className="text-down10 tc10">
                Payments Device facilities UPI Payments Acceptance and
                transaction tracking with monthly subscription of ₹590
              </span>
              <span className="text-down11 tc10">
                <li>UPI Payment Acceptance</li>
              </span>
              <span className="text-down11 tc10">
                <li>Easy apply for Loan from Payment Device</li>
              </span>
              <span className="text-down11 tc10">
                <li>Real-time Settlement</li>
              </span>
              <button className="close-button" onClick={handlePaymentClose}>
                Close
              </button>
            </div>
          </div>
        </>
      )}
      {isPopUPVisible5 && (
        <>
          <div className="insuranceContainer">
            <div className="tc9">
              <div>
                <Insurance />
              </div>
              <div className="heading9">Insurance 360</div>
            </div>
            <span className="text-down10 tc10">
              Holistic Insurance offering to cover you in case of any unforeseen
              circumstances
            </span>
            <span className="text-down11 tc10">
              <li>Shop Insurance</li>
            </span>
            <span className="text-down11 tc10">
              <li>Health Protection</li>
            </span>
            <span className="text-down11 tc10">
              <li>EMI Protection</li>
            </span>
            <span className="text-down11 tc10">
              <li>Hospicash</li>
            </span>
            <button className="close-button" onClick={handleInsuranceClose}>
              Close
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default LoanScreen;