import React, { useState } from "react";
import "./help-support.scss";
import LoanSummaryHeaderPWA from "../credits/loansummaryHeader2";
import { PinkChevron } from "../../../../assets/svg";

const PaymentHelpSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const knowMoreClicked = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is Business Prime?",
      answer:
        "Business Prime is the unique financial services offered by ARTH under which you get Paymint – Audio Loanbox, 3600 Insurance, Demat Account for Investment into Mutual Funds, and Business Account with Free Rupay Prepaid Card.",
    },
    {
      question: "How can I apply for Business Prime?",
      answer:
        "Business Prime is the unique financial services offered by ARTH under which you get Paymint – Audio Loanbox, Insurance, Demat Account for Investment into Mutual Funds, and Business Account with Free Rupay Prepaid Card. You can reach out to our Customer Support if you wish to apply for Business Prime.",
    },
    {
      question: "What are the benefits of Paymint – Audio Loanbox?",
      answer: (
        <>
          Paymint is the first Audio Loanbox of India, here are a few of the
          benefits of Paymint:
          <ul style={{ marginTop: "10px", marginLeft: "-30px" }}>
            - Free Payment acceptance from your customers
          </ul>
          <ul style={{ marginLeft: "-30px" }}>
            - Audio announcement of the payments received
          </ul>
          <ul style={{ marginLeft: "-30px" }}>
            - Ease of investment with weekly or monthly SIP directly from your
            bank account
          </ul>
          <ul style={{ marginLeft: "-30px" }}>- Earn better returns </ul>
          <ul style={{ marginLeft: "-30px" }}>
            -Withdrawal as per the convenience after the completion of Lock in
            period
          </ul>
        </>
      ),
    },
    {
      question: "What are the benefits / cover I get with 360 Insurance?",
      answer:
        "You get Shopkeeper Insurance, Health Insurance, EMI Protection for your Loans and Cover from Vector Borne Diseases.",
    },
    {
      question: "What is the benefit of Investment Account?",
      answer: (
        <>
          To become financially independent and secure the future of your
          family, we must invest a part of our earning, here is why:
          <ul style={{ marginTop: "10px", marginLeft: "-30px" }}>
            -Accumulate your money over longer period for uncertain times
          </ul>
          <ul style={{ marginLeft: "-30px" }}>
            - Plan your financial goals and achieve them gradually
          </ul>
          <ul style={{ marginLeft: "-30px" }}>
            - Ease of investment with weekly or monthly SIP directly from your
            bank account
          </ul>
          <ul style={{ marginLeft: "-30px" }}>- Earn better returns </ul>
          <ul style={{ marginLeft: "-30px" }}>
            -Withdrawal as per the convenience after the completion of Lock in
            period
          </ul>
        </>
      ),
    },
  ];

  return (
    <>
      <LoanSummaryHeaderPWA />
      <div className="payments-help-section">
        <div className="payments-help-section-content">
          {faqItems.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className="payments-help-section-content-questions"
                style={{ display: "grid", gridTemplateColumns: "1fr auto" }}
              >
                <div style={{ textAlign: "left", fontSize: "0.96rem" }}>
                  {item.question}
                </div>
                <div
                  style={
                    openIndex === index
                      ? { textAlign: "right" }
                      : {
                          transform: "rotate(180deg)",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => knowMoreClicked(index)}
                >
                  <PinkChevron />
                </div>
              </div>
              {openIndex === index && (
                <div
                  className="payments-help-section-content-answers"
                  style={{ fontSize: "0.85rem", lineHeight: "18px" }}
                >
                  {item.answer}
                </div>
              )}
              <div className="fnQ-breakLine"></div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default PaymentHelpSection;
