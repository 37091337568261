const partnerInfo = {};

export const PartnerInfo = (state = partnerInfo, action) => {
  switch(action.type){
    case 'PARTNER_INFO_DATA':
      return{
        ...state,
        data: action.payload
      }
    default: 
      return state
  }
}