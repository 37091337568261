import "./paymint.scss";
import { DownloadSVG } from "../../../../assets/svg";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useEffect, useState, ChangeEvent } from "react";
import BreadCrumb from "../../../../components/partner-application/breadcrumb/BreadCrumb";
import Pagination from "@mui/material/Pagination";
import { ApiService } from "../../../../services/api/apiService";
import { LocalStorage } from "../../../../utils/constants/localStorage";
import { Mixpanel } from "../../../../routing/MixPannelWrapper";
import { useLocation } from "react-router-dom";

const breadCrumbData = [
  // { name: "Home", path: "/arth/home" },
  { name: "Paymint", path: "" },
];

const PaymintScreen = () => {
  const [activeTab, setActiveTab] = useState("transactions");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(1);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [transactionData, setTransactionData] = useState<any[]>([]);
  const [settlementData, setSettlementData] = useState<any[]>([]);
  const [downloadTransaction, setDownloadTransaction] =
    useState<boolean>(false);
  const apiService = new ApiService();
  const customer_code = localStorage.getItem("Customer_code");
  const location = useLocation();
  const { name, mobile } = location.state || {};

  useEffect(() => {
    fetchData();
  }, [activeTab, pageNo, startDate, endDate, searchText]);

  const fetchData = () => {
    if (activeTab === "transactions") {
      fetchTransactions();
    } else {
      fetchSettlements();
    }
  };

  const fetchTransactions = () => {
    // const customer_code = "ADA2RTBL2C";
    const data_type = downloadTransaction === true ? "file" : "";
    apiService
      .getTransactionDetails(
        customer_code,
        pageNo,
        startDate,
        endDate,
        searchText,
        data_type
      )
      .then((response) => {
        setTransactionData(response?.data || []);
        setTotalRecords(response?.data?.totalRecords || 0);
        if (downloadTransaction === true) {
          const pdfUrl = response?.data?.pdf_url;
          window.open(pdfUrl, "_self");
        }
      })
      .catch((error) => {
        console.error("Error fetching transaction data:", error);
      });
  };

  const downloadData = () => {
    setDownloadTransaction(true);
    fetchTransactions();
  };

  const fetchSettlements = () => {
    // const customer_code = "ADA2RTBL2C";
    apiService
      .getSettlementDetails(
        customer_code,
        pageNo,
        startDate,
        endDate,
        searchText
      )
      .then((response) => {
        console.log("Settlement data fetched:", response?.data);
        setSettlementData(response?.data?.results || []);
        setTotalRecords(response?.data?.totalRecords || 0);
      })
      .catch((error) => {
        console.error("Error fetching settlement data:", error);
      });
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    setPageNo(1);
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    console.log("Pagination changed to:", value);
    setPageNo(value);
  };

  const handleDateRangeChange = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    setStartDate(startDate ? formatDate(startDate) : null);
    setEndDate(endDate ? formatDate(endDate) : null);
    setPageNo(1);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPageNo(1);
  };

  return (
    <div>
      <div className="udyam-payment-filter">
        <b>
          <BreadCrumb breadCrumbData={breadCrumbData} />
        </b>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto auto",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <input
            type="text"
            className="udyam-payments-search"
            placeholder="Search"
            name="search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: "100%" }}
            onClick={() => {
              Mixpanel.identify(
                mobile +
                  "-" +
                  customer_code +
                  "-" +
                  localStorage.getItem("Device")
              );
              Mixpanel.track("Payments Section- Search", "");
              Mixpanel.people.set({
                $first_name: name,
              });
            }}
          />
          <div
            style={
              activeTab === "settlement" ? { width: "86%" } : { width: "100%" }
            }
            onClick={() => {
              Mixpanel.identify(
                mobile +
                  "-" +
                  customer_code +
                  "-" +
                  localStorage.getItem("Device")
              );
              Mixpanel.track("Payments Section- Filter", "");
              Mixpanel.people.set({
                $first_name: name,
              });
            }}
          >
            {" "}
            {true ? (
              <DateRangePicker
                format="dd-MM-yyyy"
                placeholder="DD-MM-YYYY"
                block
                showOneCalendar
                onChange={([start, end]: [Date | null, Date | null]) =>
                  handleDateRangeChange(start, end)
                }
              />
            ) : (
              <></>
            )}
          </div>
          {true ? (
            activeTab === "settlement" ? null : (
              <div onClick={downloadData}>
                <div
                  onClick={() => {
                    Mixpanel.identify(
                      mobile +
                        "-" +
                        customer_code +
                        "-" +
                        localStorage.getItem("Device")
                    );
                    Mixpanel.track("Payments Section- Download", "");
                    Mixpanel.people.set({
                      $first_name: name,
                    });
                  }}
                >
                  <DownloadSVG />
                </div>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="payment-settlement">
        <a
          className={activeTab === "transactions" ? "active" : ""}
          onClick={() => handleTabClick("transactions")}
        >
          <span className="payment-settlement-text">Transactions</span>
        </a>
        <a
          className={activeTab === "settlement" ? "active" : ""}
          onClick={() => handleTabClick("settlement")}
        >
          <span className="payment-settlement-text">Settlement</span>
        </a>
      </div>
      <div>
        {activeTab === "transactions" && (
          <div
            onClick={() => {
              Mixpanel.identify(
                mobile +
                  "-" +
                  customer_code +
                  "-" +
                  localStorage.getItem("Device")
              );
              Mixpanel.track("Payments Sections -Transactions", "");
              Mixpanel.people.set({
                $first_name: name,
              });
            }}
          >
            <TransactionsComponent
              transactionData={transactionData}
              handlePaginationChange={handlePaginationChange}
              totalRecords={totalRecords}
              pageNo={pageNo}
            />
          </div>
        )}
        {activeTab === "settlement" && (
          <div
            onClick={() => {
              Mixpanel.identify(
                mobile +
                  "-" +
                  customer_code +
                  "-" +
                  localStorage.getItem("Device")
              );
              Mixpanel.track("Payments Section- Settlement", "");
              Mixpanel.people.set({
                $first_name: name,
              });
            }}
          >
            <SettlementComponent
              settlementData={settlementData}
              handlePaginationChange={handlePaginationChange}
              totalRecords={totalRecords}
              pageNo={pageNo}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const TransactionsComponent = ({
  transactionData,
  handlePaginationChange,
  totalRecords,
  pageNo,
}: {
  transactionData: any[];
  handlePaginationChange: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void;
  totalRecords: number;
  pageNo: number;
}) => (
  <div className="transaction-section">
    {transactionData.length > 0 ? (
      <>
        <div style={{ overflow: "scroll" }}>
          {transactionData.map((transaction) => (
            <div
              key={transaction.id}
              className="card"
              style={{
                margin: "10px",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <div className="transaction-card-content">
                <div style={{ textAlign: "left" }}>
                  {transaction.remitter_full_name}
                </div>
                <div style={{ textAlign: "right" }}>
                  ₹ {parseInt(transaction.amount)}
                </div>
              </div>
              <div className="upi-id">{transaction.remitter_upi_handle}</div>
              <div className="transaction-date-status">
                <div className="transaction-date">
                  {transaction.transaction_date}
                </div>
                <div className="transaction-status">{transaction.status}</div>
              </div>
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(totalRecords / 10)}
            variant="outlined"
            shape="rounded"
            page={pageNo}
            onChange={handlePaginationChange}
          />
        </div>
      </>
    ) : (
      <div style={{ padding: "20px" }}>No transactions available</div>
    )}
  </div>
);

const SettlementComponent = ({
  settlementData,
  handlePaginationChange,
  totalRecords,
  pageNo,
}: {
  settlementData: any[];
  handlePaginationChange: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void;
  totalRecords: number;
  pageNo: number;
}) => (
  <div className="setllment-section">
    {settlementData.length > 0 ? (
      <>
        <div style={{ overflow: "scroll" }}>
          {settlementData.map((settlement) => (
            <div
              key={settlement.id}
              className="card"
              style={{
                margin: "10px",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <div className="transaction-card-content">
                <div style={{ textAlign: "left" }}>
                  {settlement.utr}
                  {/* 456789 */}
                </div>
                <div style={{ textAlign: "right" }}>
                  ₹{parseInt(settlement.settlement_amount)}
                </div>
              </div>
              <div className="upi-id">{settlement.remitter_upi_handle}</div>
              <div className="transaction-date">
                {settlement.settled_at}
                {/* 2024-May-10 */}
              </div>
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(totalRecords / 10)}
            variant="outlined"
            shape="rounded"
            page={pageNo}
            onChange={handlePaginationChange}
          />
        </div>
      </>
    ) : (
      <div style={{ padding: "20px" }}>No settlements available</div>
    )}
  </div>
);

const formatDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export default PaymintScreen;
