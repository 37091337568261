import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/partner-application/breadcrumb/BreadCrumb';
import { AddWhiteSVG, SearchWhiteSVG } from '../../../assets/svg';
import ActionButton from '../../../components/partner-application/button/ActionButton';
import PrimeryButton from '../../../components/partner-application/button/PrimeryButton';
import "../invoice/invoice-index.scss";
import {useNavigate} from "react-router-dom";
import {PartnerService} from "../../../services/api/partnerService";
import {Toaster} from "../../../utils/utilities/Toaster";
import Pagination from '@mui/material/Pagination';

const breadCrumbData = [{ name: 'Invoice', path: '' }];

const Invoice_Index = () => {
  const navigate = useNavigate();
  const toaster = new Toaster();
  const [invoices, setInvoices] = useState([]);
  const [searchText, setSearchText] = useState('');
  const partnerService = new PartnerService();
  const partnerId = localStorage.getItem('currentPartnerId');
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(1);

  useEffect(() => {
      fetchInvoices(partnerId, searchText, pageNo)
  }, []);

  const handleSearchInput = (value: string) => {
    setSearchText(value.toLowerCase())
  };

  const handleSearchClick = () => {
      fetchInvoices(partnerId, searchText, pageNo)
      // partnerService.fetchInvoices(partnerId, searchText, pageNo).then((response: any) => {
      //     if (response?.data?.status === 'success') {
      //         setInvoices(response?.data?.data)
      //         setTotalRecords(response?.data?.total)
      //     }
      // }).catch((error: any) => {
      //     toaster.show(true, error?.data?.message);
      // })
  }

  const createNewInvoice = () => {
    navigate(`/invoice/invoice-create`);
  };

  const fetchInvoices = (partnerId, searchText, pageNo) => {
      partnerService.fetchInvoices(partnerId, searchText, pageNo).then((response: any) => {
          if (response?.data?.status === 'success') {
              setInvoices(response?.data?.data)
              setTotalRecords(response?.data?.total)
          }
      }).catch((error: any) => {
        if (error?.response?.data?.code === "token_not_valid") {
          localStorage.clear();
          navigate(`/`);
        } else {          
          toaster.show(true, error?.data?.message);
        }
      })
  }

  const handleOnboardingDate = (stamp:any) => {
    if(stamp) {
        const creationTimestamp = stamp;
        const date = new Date(creationTimestamp);
        const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: '2-digit'};
        const formattedDate = date.toLocaleDateString('en-GB', options);
        return formattedDate;
    }
  }

    const handlePaginationChange = (event: any, value: number) => {
        setPageNo(value);
        fetchInvoices(partnerId, searchText, value)
    }

  return (
    <div className='p-4'>
      <div className='d-flex justify-content-between mb-2'>
        <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', gap:'08px'}}>
        <div><BreadCrumb breadCrumbData={breadCrumbData}/></div>
        <div>- {totalRecords}</div>
        </div>
        <div onClick={createNewInvoice} style={{ width: 119, height: 40 }}><ActionButton isActive={true} buttonContent={<><AddWhiteSVG /> Create</>} /></div>
      </div>
      <form className="d-flex">
        <div className="search-container1">
          <input className="form-control search-input" type="search" placeholder="Search" style={{ width: 326, height: 36 }} onChange={(e) => handleSearchInput(e.target.value)} />
        </div>
        <div className="search-button" style={{ width: 50, height: 36 }} onClick={handleSearchClick}>
          <PrimeryButton buttonContent={<SearchWhiteSVG />} />
        </div>
      </form><br />
      <div>
        {invoices.map(invoice => (
          <div key={invoice.id}>
            <div className="card mt-2" onClick={() => navigate('/invoice/' + invoice.invoice_id)} style={{ cursor: 'pointer' }}>
              <div className="card-body p-2">
                <div className="listing-wrapper d-flex justify-content-between">
                  <div className="left-side-card">
                    <div className="m-2">{invoice.customer_name}</div>
                    <div className="m-2">{invoice.mobile}</div>
                  </div>
                  <div className="right-side-card mr-4">
                    <div className="m-2">Invoice ID: {invoice.invoice_id}</div>
                    <div className="d-flex justify-content-end m-2">{handleOnboardingDate(invoice.invoice_date)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
        <div className='mt-4 d-flex justify-content-center'>
            <Pagination count={Math.ceil(totalRecords / 20)} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
    </div>
  );
}

export default Invoice_Index;