import BreadCrumb from "../../../components/partner-application/breadcrumb/BreadCrumb";
import { useEffect, useState } from "react";
import "./invoice-view.scss";
import { PartnerService } from '../../../services/api/partnerService';
import { Toaster } from '../../../utils/utilities/Toaster';
import { useParams } from "react-router-dom";
import { AddWhiteSVG, DownloadSVG } from "../../../assets/svg";
import ActionButton from "../../../components/partner-application/button/ActionButton";

const Invoice_view = () => {
    const { id } = useParams();
    const partnerService = new PartnerService();
    const toaster = new Toaster();
    const breadCrumbData = [{ name: 'Invoice / Invoice', path: '' }];
    const partnerId = localStorage.getItem('currentPartnerId');
    const [invoiceData, setInvoiceData] = useState({});
    const [description, setDescription] = useState([]);
    const [invoicePdfUrl, setInvoicePdfUrl] = useState('');

    useEffect(() => {
        if (id) {
            partnerService.fetchInvoiceData(id, partnerId).then((response: any) => {
                if (response?.data?.status === 'success') {
                    setInvoiceData(response?.data?.data[0])
                    setDescription(response?.data?.data[0]['items'])
                }
            }).catch((error: any) => {
                toaster.show(true, error?.data?.message);
            })
            partnerService.generateInvoicePdf(id).then((response: any) => {
                if (response?.data?.status === 'Success') {
                    setInvoicePdfUrl(response?.data?.pdf_url)
                }
            }).catch((error: any) => {
                toaster.show(true, error?.data?.message);
            })
        }
    }, []);

    // console.log(invoiceData)

    const handleOnboardingDate = (stamp:any) => {
        if(stamp) {
            const creationTimestamp = stamp;
            const date = new Date(creationTimestamp);
            const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: '2-digit'};
            const formattedDate = date.toLocaleDateString('en-GB', options);
            return formattedDate;
        }
    }
    

    return (
        <div className='p-4'>
            <div className="d-flex justify-content-between mb-2">
                <div>
                    <BreadCrumb breadCrumbData={breadCrumbData} />
                </div>
            </div>
            <div className="container2">
                <div className="container5">
                    {/*<div className="row">*/}
                        <div className="flex-item-left">
                            <span>Invoice</span>
                        </div>
                        <div className="flex-item-right">
                            <a href={invoicePdfUrl} download="invoice.pdf" target="_blank">
                                <DownloadSVG />
                            </a>
                            {/* <button className="btn btn-primary mr-2">Download</button> */}
                            {/* <span>&nbsp;&nbsp;&nbsp;</span> */}
                            {/* <button className="btn btn-primary mr-2">Print</button> */}
                        </div>
                    {/*</div>*/}
                </div>
                {/*<div className="row" style={{ padding: '2rem' }}>*/}
                <div className="container2" style={{ padding: 0 }}>
                    <div className="partner-header">
                        <div className="partner-name">
                            { invoiceData['business_logo'] && <div className="invoice-logo">
                                <img src={invoiceData['business_logo']}></img>
                            </div>}
                            <div className="partner-business-name">
                                <span>{invoiceData['business_name']}</span>
                                <span>{invoiceData['business_mobile']}</span>
                            </div>
                        </div>
                        <div className="partner-address">
                            <span>{invoiceData['business_gstin'] ? "GSTIN: "+invoiceData['business_gstin'] : ""}</span>
                            <span>{invoiceData['business_address']}</span>
                        </div>
                        {/*<div className="col-md-6">*/}
                            {/*/!*<br></br>*!/*/}
                            {/*/!*<div className="row">*!/*/}
                            {/*/!*<div className="col-md-2">*!/*/}
                                {/*/!*<img src="https://image.similarpng.com/very-thumbnail/2021/05/Illustration-of-logo-design-template-on-transparent-background-PNG.png"></img>*!/*/}
                            {/*/!*</div>*!/*/}
                            {/*<div className="col-md-12">*/}
                                {/*<div className="invoice-logo">*/}
                                    {/*<img src={invoiceData['business_logo']}></img>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                    {/*<span>Business Name{invoiceData['business_name']}</span>*/}
                                    {/*<span>{invoiceData['business_mobile']}</span>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            {/*/!*<br></br>*!/*/}
                            {/*/!*</div>*!/*/}
                            {/*/!*<div className="">*!/*/}
                                {/*/!*<div className="col-md-6"> *!/*/}
                                    {/*/!*<span></span>*!/*/}
                                {/*/!*</div>*!/*/}
                            {/*/!*</div>*!/*/}
                            {/*<br></br>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-3"></div>*/}
                        {/*<div className="col-md-3" style={{textAlign:'right'}}>*/}
                            {/*<br></br>*/}
                            {/*/!*<div className="col-md-12">*!/*/}
                                {/*<div>*/}
                                    {/*{invoiceData['business_gstin'] ? "GSTIN: "+invoiceData['business_gstin'] : ""}*/}
                                    {/*/!*{invoiceData['gstin_number']}*!/*/}
                                {/*</div>*/}
                                {/*<div>{invoiceData['business_address']}</div>*/}
                            {/*/!*</div>*!/*/}
                            {/*<br></br>*/}
                        {/*</div>*/}
                    </div>
                    <div className="customer-header">
                        <span className="customer-invoice-heading">Invoice to:</span>
                        <div className="customer-details">
                            <div className="customer-name">
                                <span>{invoiceData['customer_business_name']}</span>
                                <span>{invoiceData['customer_name']}</span>
                                <span>{invoiceData['mobile']}</span>
                                <span>{(invoiceData['address'] && invoiceData['pincode']) ? `${invoiceData['address']}, ${invoiceData['pincode']}` : ''}</span>
                            </div>
                            <div className="customer-invoice-details">
                                <span>{invoiceData['gstin_number'] ? "GSTIN: "+invoiceData['gstin_number'] : ""}</span>
                                <span>Invoice ID: {invoiceData['invoice_id']}</span>
                                <span>Date: {handleOnboardingDate(invoiceData['invoice_date'])}</span>
                            </div>
                        </div>

                        {/*<div className="row">*/}
                            {/*<div className="col-md-6">*/}
                                {/*<div className="col-md-12">{invoiceData['customer_business_name']}</div>*/}
                                {/*<div className="col-md-12">{invoiceData['customer_name']}</div>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-3"></div>*/}
                            {/*<div className="col-md-3" style={{textAlign:'right'}}>{invoiceData['gstin_number'] ? "GSTIN: "+invoiceData['gstin_number'] : ""}</div>*/}
                            {/*/!* <div className="colmd-6">{invoiceData['mobile']}</div> *!/*/}
                        {/*</div>*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-md-6">{invoiceData['mobile']}</div>*/}
                            {/*<div className="col-md-6" style={{textAlign:'right'}}> Invoice ID: {invoiceData['invoice_id']}</div>*/}
                        {/*</div>*/}
                        {/*/!*<br />*!/*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-md-6"> {(invoiceData['address'] && invoiceData['pincode']) ? `${invoiceData['address']}, ${invoiceData['pincode']}` : ''}</div>*/}
                            {/*<div className="col-md-6" style={{textAlign:'right'}}>*/}
                                {/*Date: {handleOnboardingDate(invoiceData['invoice_date'])}*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>


                    <div className="container3">
                        {/*<div className="col-md-12">*/}
                            <div className="table-responsive" style={{border:"1px solid #ddd", borderRadius:"12px"}}>
                                <table className="description-table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Items Description</th>
                                            <th></th>
                                            <th>Qty.</th>
                                            <th>Rate</th> 
                                            {description.some(d => d.gst_id !== null) && <th>HSN/SAC</th>}
                                            {description.some(d => d.tax !== null) && <th>Tax (%)</th>}
                                            {description.some(d => d.gst !== null) && <th>GST (%)</th>} 
                                            {description.some(d => d.sst !== null) && <th>SST (%)</th>} 
                                            {description.some(d => d.vat !== null) && <th>VAT (%)</th>}
                                            {description.some(d => d.ppn !== null) && <th>PPN (%)</th>}
                                            {description.some(d => d.igst !== null) && <th>IGST (₹)</th>}
                                            {description.some(d => d.sgst !== null) && <th>SGST (₹)</th>}
                                            {description.some(d => d.cgst !== null) && <th>CGST (₹)</th>}
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {description.map((desc, index) => (
                                            <tr key={desc.id}>
                                                <td>{index + 1}</td>
                                                <td>{desc.item_name}</td>
                                                <td></td>
                                                <td>{desc.units}</td>
                                                <td>{desc.price_per_unit}</td>
                                                {/* <td>{desc.tax}</td> */}
                                                {desc.gst_id !== null && <td>{desc.gst_id}</td>}
                                                {desc.tax !== null && <td>{desc.tax}</td>} 
                                                {desc.gst !== null && <td>{desc.gst}</td>}
                                                {desc.sst !== null && <td>{desc.sst}</td>}
                                                {desc.vat !== null && <td>{desc.vat}</td>}
                                                {desc.ppn !== null && <td>{desc.ppn}</td>}
                                                {desc.igst !== null && <td>{desc.igst}</td>}
                                                {desc.sgst !== null && <td>{desc.sgst}</td>}
                                                {desc.cgst !== null && <td>{desc.cgst}</td>}
                                                {/* <td></td> */}
                                                <td>{desc.amount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        {/*</div>*/}
                        <div className="invoice-footer">
                            <div style={{flexGrow:'3'}}></div>
                            <div style={{flexGrow:'1'}}>
                                <div className="invoice-footer-items">
                                    <span className="left-item">Items Total:</span>
                                    <span>₹ {invoiceData['item_amount'] ? parseFloat(invoiceData['item_amount']).toFixed(2) : '0.00'}</span>
                                </div>
                                <div className="invoice-footer-items">
                                    <span className="left-item">Tax:</span>
                                    <span>₹ {invoiceData['total_tax'] ? parseFloat(invoiceData['total_tax']).toFixed(2) : '0.00'}</span>
                                </div>
                                <div className="invoice-footer-items">
                                    <span className="left-item">Fee:</span>
                                    <span>₹ {invoiceData['total_fee'] ? parseFloat(invoiceData['total_fee']).toFixed(2) : '0.00'}</span>
                                </div>
                                <div className="invoice-footer-items">
                                    <span className="left-item">Discount:</span>
                                    <span>₹ {invoiceData['discount'] ? parseFloat(invoiceData['discount']).toFixed(2) : '0.00'}</span>
                                </div>
                                <div className="invoice-footer-items">
                                    <span className="left-item">Total:</span>
                                    <span>₹ {invoiceData['total_amount'] ? parseFloat(invoiceData['total_amount']).toFixed(2) : '0.00'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="invoice-thankyou">Thank you for your business</div>
                        { (invoiceData['business_bank_name'] != "") && <>
                        
                    
                        <span className="customer-invoice-heading" style={{color:'#9f9d9d'}}>Bank Details</span>
                        <div className="customer-details">
                            <div className="customer-name">
                              
                                <span style={{marginTop:'10px'}}>Bank : &nbsp;{invoiceData['business_bank_name']}</span>
                                <span>Account Name : &nbsp;{invoiceData['business_bank_account_name']}</span>
                                <span>Account No : &nbsp;{invoiceData['business_bank_account_number']}</span>
                                <span>IFSC : &nbsp;{invoiceData['business_bank_ifsc']}</span>
                            </div>
                            
                        </div>
                        </>
                   
}


                    </div>
                </div>
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Invoice_view;