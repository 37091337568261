import './App.css';
import Router from './routing/Router';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

function App() {

  return (
    <Router />
  );
}

export default App;
