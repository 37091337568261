import './button-footer.scss';
import Button from '../../customer-application/button/Button';

const ButtonFooter = ({buttonContent, isActive}:any) => {
  return (
    <div className='button-footer'>
      <Button buttonContent={buttonContent} isActive={isActive}/>
    </div>
  )
}

export default ButtonFooter;
