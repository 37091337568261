import React, { useEffect, useState } from "react";
import './short-cut.scss';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const AddArthToHomeScreen = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const MySwal = withReactContent(Swal);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    const MySwal = withReactContent(Swal);
    if (deferredPrompt) {
      MySwal.fire({
        title: 'Add To Home Screen',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Add',
        position: 'bottom',
        toast: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          deferredPrompt.prompt();
          const choiceResult = await deferredPrompt.userChoice;
          if (choiceResult.outcome === 'accepted') {
          }
        }
        setDeferredPrompt(null);
      });
    }
  }, [deferredPrompt]);

  return (
    <div></div>
  );
}

export default AddArthToHomeScreen;
