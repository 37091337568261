import React, { useEffect, useState } from "react";
import NavbarComponent from "../../../components/ice-application/navbar/NavbarComponent";
import PieChartComponent from "../../../components/ice-application/charts/PieChart";
import LineChartComponent from "../../../components/ice-application/charts/LineChart";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import "./home.scss";
import BarChartComponent from "../../../components/ice-application/charts/BarChart";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

Chart.register(CategoryScale);

function Ice() {
  const navigate = useNavigate();
 
  useEffect(() => {
    if (!localStorage.getItem("ICELOGGEDIN")) {
      navigate("/ice/");
    }
  }, []);
  const [chartData, setChartData] = useState();
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const requestOptions = {
      headers: {'Authorization': process.env.REACT_APP_ICE_AUTHORIZATION}
    }
    const response = await fetch(
      process.env.REACT_APP_ICE_BASE_URL + "/leads-summary/", requestOptions
    );
    const data = await response.json();
    setChartData(data.data);
  };

  if (chartData && localStorage.getItem("ICELOGGEDIN")) {
    return (
      <>
        <NavbarComponent active={"Dashboard"} />
        <div className="mainContainer">
          <div className="contentContainer">
            <Row className="chartContainer">
              <div className="textHeader">Leads Analysis</div>
              <div className="chartHolder">
                <Col className="chartTile">
                  <LineChartComponent
                    title={"Leads"}
                    chartData={chartData}
                    type={"leads"}
                  />
                </Col>

                <Col className="chartTile">
                  <PieChartComponent
                    title={"Center"}
                    chartData={chartData}
                    type={"center"}
                  />
                </Col>
                <Col className="chartTile">
                  <PieChartComponent
                    title={"Occupation"}
                    chartData={chartData}
                    type={"occupation"}
                  />
                </Col>
              </div>
            </Row>

            <Row className="chartHolder">
              <Col className="singleChartContainer">
                <div className="textHeader">Approved</div>
                <div className="chartTile">
                  <BarChartComponent chartData={chartData} />
                </div>
              </Col>
              <Col className="singleChartContainer">
                <div className="textHeader">Risk Spread-Distribution</div>
                <div className="chartTile">
                  <LineChartComponent chartData={chartData} type={"risk"} />
                </div>
              </Col>
              <Col className="singleChartContainer">
                <div className="textHeader">Customer Category</div>
                <div className="chartTile">
                  <PieChartComponent chartData={chartData} type={"customer"} />
                </div>
              </Col>
            </Row>

            <Row className="chartContainer">
              <div className="textHeader">Approved</div>
              <div className="chartHolder">
                <Col className="chartTile">
                  <PieChartComponent
                    title={"Center"}
                    chartData={chartData}
                    type={"center-approved"}
                  />
                </Col>

                <Col className="chartTile">
                  <PieChartComponent
                    title={"Gender"}
                    chartData={chartData}
                    type={"gender"}
                  />
                </Col>
                <Col className="chartTile">
                  <PieChartComponent
                    title={"Age"}
                    chartData={chartData}
                    type={"age"}
                  />
                </Col>
              </div>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default Ice;
