import React from "react";
import "./table-component.scss";
import Status from "../Status/Status";

const TableComponent = ({ data, navigate }) => {
  
  function handleClick(key) {
    navigate(`/ice/customer_details?id=${key}`);
  }

  const obj = data;

  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const result = [];
  const id_list = [];
  obj.forEach(createNew);

  function createNew(value) {
    const newDate = new Date(value.create_ts);
    let status = value.status;

    const newData = {
      ID: value.customer_ucic,
      Name: value.customer_name,
      Date: `${newDate.getDate()} ${
        monthNames[newDate.getMonth()]
      } ${newDate.getFullYear()}`,
      Partner: value.partner,
      Type: value.customer_type,
      Center: value.location,
      Product: value.product,
      status: <Status status={status} />,
    };

    result.push(newData);
    id_list.push(value.common_onboard_id);
  }

  const headers = [
    "ID",
    "Name",
    "Date",
    "Partner",
    "Type",
    "Center",
    "Product",
    "Status",
  ];
  const rows = result.map((item) => Object.values(item));

  return (
    <div className="data-table table-responsive">
      <table classname="table">
        <thead className="thead border-props">
          <tr>
            {headers.map((header) => (
              <th scope="col" style={{ fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: "#707070",
                paddingLeft: "20px"}}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-props">
          {rows.map((row, index) => (
            <tr
              className="table-row"
              onClick={() => handleClick(id_list[index])}
              key={id_list[index]}
            >
              {row.map((cell, index) => (
                <td style={{paddingBlock: "10px", fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: "12px",
                  color: "#3E3E3E", 
                  paddingLeft: "20px"}}  key={index}>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
