export const OfferDetails = (offer) => {
  return {
    type: 'CUSTOMER_OFFER',
    payload: offer
  }
}

export const userProfile = (profile) => {
  return {
    type: 'USER_PROFILE',
    payload: profile
  }
}

export const allProfileData = (data) => {
  return {
    type: 'ALL_PROFILE_DATA',
    payload: data
  }
}

export const loanDetails = (details) => {
  return {
    type: 'LOAN_DETAILS',
    payload: details
  }
}

export const journeySteps = (steps) => {
  return {
    type: 'JOURNEY_STEPS',
    payload: steps
  }
}

export const currentAction = (action) => {
  return {
    type: 'CURRENT_ACTION',
    payload: action
  }
}

export const lenderTermsAndCondition = (tnc) => {
  return {
    type: 'LENDER_TNC',
    payload: tnc
  }
}

export const loaderState = (state) => {
  return {
    type: "LOADER_STATE",
    payload: state
  }
}