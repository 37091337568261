import "./navbar-component.scss";

import { ArthLogo } from "../../../assets/svg";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

function NavbarComponent({ active, profileLetter }) {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear("ISLOGGEDIN");
    localStorage.clear("username");
    navigate("/ice/");
  };
  const username = localStorage.getItem("username");

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-arth-logo">
          <ArthLogo />
        </div>
        <div
          className={`navbar-dashboard-text ${
            active !== "Dashboard" && "inactive-tab"
          }`}
          onClick={() => navigate("/ice/home")}
        >
          Dashboard
        </div>
        {active === "Dashboard" && <div className="navbar-underline-d"></div>}
        <div
          className={`navbar-customer-text ${
            active !== "Customers" && "inactive-tab"
          }`}
          onClick={() => navigate("/ice/customers")}
        >
          Customers
        </div>
        {active === "Customers" && <div className="navbar-underline-c"></div>}
        <div style={{ display: "flex" }}>
          {/* <div className="navbar-circle">
            <div className="navbar-circle-text">
              {username.charAt(0).toUpperCase()}
            </div>
          </div> */}
          <div
            style={{
              marginTop: "18px",
              marginRight: "15px",
              fontSize: "30px",
              cursor: "pointer",
              marginLeft: "auto"
            }}
            onClick={handleLogout}
          >
            {/* <i class="bi bi-box-arrow-right"></i> */}
            <LogoutIcon />
          </div>
        </div>
      </div>
    </>
  );
}

export default NavbarComponent;
