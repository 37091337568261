import React from 'react';
import './button.scss';

const Button = ({buttonContent, isActive=true}:any) => {

  return (
    <div className={`button ${isActive? 'active-color' : 'inactive-color'}`}>
      {buttonContent}
    </div>
  )
}

export default Button;
