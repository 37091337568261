import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../constants/localStorage";
import { loaderState } from "../../store/actions/customer-application/offerAction"

export class LoaderService {

  dispatch = useDispatch();
  navigate = useNavigate();
  partnerSlug = localStorage.getItem(LocalStorage.PARTNER_SLUG);
  customerCode = localStorage.getItem(LocalStorage.CUSTOMER_CODE);

  showLoader = () => {
    this.dispatch(loaderState(true));
  }

  hideLoader = () => {
    this.dispatch(loaderState(false));
  }

  errorService = (error:any) => {
    if(error.response){
      if(error.response.data.error === 'Please login to continue.'){
        this.navigate(`/${this.partnerSlug}/utils/${this.customerCode}`);
      } else {
        alert(error.response.data.error);
      }
    } else {
      alert("No response from API");
    }
  }
}