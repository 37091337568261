import "./details-box.scss";

function CoBorrowerDemographics({ demographicsData }) {

  const demographicOne = {
    Name: demographicsData?.name,
    Age: demographicsData?.age,
    Gender: demographicsData?.gender,
  };

  const demographicTwo = {
    "Mobile Number": demographicsData?.mobile
  };

  const demographicThree = {
    Occupation: demographicsData?.occupation
  };

  const demographicFour = {
    Relation: demographicsData?.relation
  };

  const demographicFive = {
    Address: demographicsData?.address
  };
  return (
    <div className={`demographics-container big-box`}>
      <div class="row">
        <div class="col-md hdr-style">Demographics</div>
      </div>
      <div>
        {Object.keys(demographicOne).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicOne[key]}</div>
          </div>
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(demographicTwo).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicTwo[key]}</div>
          </div>
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(demographicThree).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicThree[key]}</div>
          </div>
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(demographicFour).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicFour[key]}</div>
          </div>
        ))}
      </div>
      <div className="seperator" />
      <div>
        {Object.keys(demographicFive).map((key) => (
          <div class={`row basic-details-body`}>
            <div class="col-md-auto big-basic-left-body">{key}</div>
            <div class={`col-md basic-right-body`}>{demographicFive[key]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CoBorrowerDemographics;
