import { useState } from "react";
import RadioInput from "../radio-input/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import './language-card.scss';
import { changeLanguage } from "../../../store/actions/customer-application/languageAction";

const LanguageCard = () => {

  const displayText = useSelector((state:any) => (state.language.languageText));
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('English');

  const handleLanguageCardClick = (lang:string) => {
    return () => {
      setSelectedLanguage(lang);
      dispatch(changeLanguage(lang));
    }
  }

  return (
    <div>
      <div className="language-select">
        {displayText.select_your_language}
      </div>
        <div className="language-card">
          <div className={`card ${selectedLanguage === 'English' ? 'active' : ''}`} onClick={handleLanguageCardClick('English')}>
            <div className="language-input">
              <RadioInput value={'English'} selectedValue={selectedLanguage} setSelectedValue={setSelectedLanguage} />
            </div>
            <div className="language">
              English
            </div>
          </div>
          <div className={`card card-2 ${selectedLanguage === 'Hindi' ? 'active' : ''}`} onClick={handleLanguageCardClick('Hindi')}>
            <div className="language-input">
              <RadioInput value={'Hindi'} selectedValue={selectedLanguage} setSelectedValue={setSelectedLanguage} />
            </div>
            <div className="language">
              हिंदी
            </div>
          </div>
        </div>
    </div>
  )
}

export default LanguageCard
