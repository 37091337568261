import { useEffect, useState } from "react";

import "./login.scss";
import LogInBackground from "./loginBackground";
// import { getSHA256Hash } from "boring-webcrypto-sha256";
import { sha256 } from "js-sha256";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/sales-application/Loader/Loader";

function ICELogIn() {

  
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("ICELOGGEDIN")) {
      navigate("/ice/home");
    }
  }, []);
  
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = useState("");
  const [invalidCreds, setInvalidCreds] = useState(false);

  const handlePwdInput = (event) => {
    setPassword(event.target.value);
  };
  const handleLogin = async () => {
    try {
      setLoader(true);
      const data = await axios.post(
        process.env.REACT_APP_ICE_LOGIN_URL,
        {
          userName: email,
          password: sha256(password),
        }
      );
      setInvalidCreds(false);

      if (
        data.data.AuthenticationResult.IdToken &&
        data.data.AuthenticationResult.RefreshToken
      ) {
        localStorage.setItem(
          "tokenData",
          JSON.stringify(data.data.AuthenticationResult.IdToken)
        );
        localStorage.setItem("username", email);
        localStorage.setItem("ICELOGGEDIN", JSON.stringify(true));
        navigate("/ice/home");
      }
    } catch (error) {
      setInvalidCreds(true);
      setLoader(false);
      console.error(error);
    }
  };

  if (loader) {
    return (
      <div className="loaderStyle">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <LogInBackground />
      <div>
        <div className="login-container">
          <img
            src="https://arth-banner.s3.ap-south-1.amazonaws.com/Rectangle.png"
            className="login-image"
            alt="login-bg-img"
          />
          <div className="login-text">Login</div>
          <div className="login-email-text">Email ID</div>
          <div>
            <input
              className="login-email-input"
              onChange={handleEmailInput}
              onKeyDown={(event) => {
                if (event.code === "Enter") {
                  handleLogin()
                }
              }}
            ></input>
          </div>
          <div className="login-password-text">Password</div>
          <div>
            <input
              className="login-password-input"
              type="password"
              onChange={handlePwdInput}
              onKeyDown={(event) => {
                if (event.code === "Enter") {
                  handleLogin()
                }
              }}
            ></input>
          </div>

          <div>
            <button
              className="login-button-footer"
              type="button"
              onClick={handleLogin}
            >
              <p className="login-button-footer-text">Login</p>
            </button>
          </div>
          {invalidCreds && (
            <div className="invalid-creds">
              Invalid Credentials. Please provide valid credentials and try
              again!
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ICELogIn;
