
import React, { useEffect } from 'react';
import { Select, FormControl, Checkbox, MenuItem, ListItemText } from '@mui/material';
import '../text-input/text-input.scss';
import './dropdown.scss';

const MultiSelect = (props:any) => {

  const {
    list,
    value,
    heading,
    identifier,
    handleInput,
    isCompulsoryField,
    supportiveTextGreen,
    supportiveTextRed,
    disabled
  } = props;

  const [selectedItem, setSelectedItem] = React.useState<string[]>(value);

  useEffect(() => {
    setSelectedItem(value);
  }, [value])

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedItem(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    handleInput(identifier,value);
  };

  return (
    <div className="">
      <div className='partner-heading'>{heading}<span>{isCompulsoryField && '*'}</span></div>
      {/* <select className={`form-select ${supportiveTextRed && 'red-border'}`} onChange={handleDropdownSelect} value={value} disabled={disabled} >
        <option className='partner-heading'>Select</option>
        {list?.map((key:string, index:number) => {
          return (
            <option key={index} value={key}>{key}</option>
          )
        })}
      </select> */}
      <div>
      <FormControl className={`multiselect-style ${supportiveTextRed && 'red-border'}`}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedItem}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          disabled={disabled}
          // MenuProps={MenuProps}
        >
          {list?.map((name:any) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedItem.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
      <div className='partner-supportive-text-red'>{supportiveTextRed}</div>
      <div className='partner-supportive-text-green'>{supportiveTextGreen}</div>
    </div>
  )
}

export default MultiSelect;
