import React from 'react';
import './button.scss';

const PrimeryButton = (props:any) => {

  const {buttonContent} = props;

  return (
    <div className='partner-action-button-active'>
      {buttonContent}
    </div>
  )
}

export default PrimeryButton;
