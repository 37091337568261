const initialState = {
  userProfile: null,
  prequalificationDetails: null,
  completeOfferDetails: null,
};

const initialProfile = {
  profile: null,
};

const loan = {
  loan: null,
};

export const OfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CUSTOMER_OFFER":
      const { userProfile, prequalificationDetails, completeOfferDetails } =
        action.payload;
      return {
        ...state,
        prequalificationDetails: prequalificationDetails,
        userProfile: userProfile,
        completeOfferDetails: completeOfferDetails,
      };
    default:
      return state;
  }
};

export const UserProfileReducer = (state = initialProfile, action) => {
  switch (action.type) {
    case "USER_PROFILE":
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};

export const AllUserProfileReducer = (state = initialProfile, action) => {
  switch (action.type) {
    case "ALL_PROFILE_DATA":
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};

export const LoanDetailsReducer = (state = loan, action) => {
  switch (action.type) {
    case "LOAN_DETAILS":
      return {
        ...state,
        loan: action.payload,
      };
    default:
      return state;
  }
};

export const JourneyStepsReducer = (state = [], action) => {
  switch (action.type) {
    case "JOURNEY_STEPS":
      return {
        ...state,
        list:
          JSON.parse(localStorage.getItem("journeySteps")) || action.payload,
      };
    default:
      return state;
  }
};

export const CurrentActionReducer = (state = "login", action) => {
  switch (action.type) {
    case "CURRENT_ACTION":
      return {
        ...state,
        action: action.payload,
      };
    default:
      return state;
  }
};

export const LenderTermsAndCondition = (state = "", action) => {
  switch (action.type) {
    case "LENDER_TNC":
      return {
        ...state,
        tnc: action.payload,
      };
    default:
      return state;
  }
};

export const LoaderState = (state = false, action) => {
  switch (action.type) {
    case "LOADER_STATE":
      return action.payload;
    default:
      return state;
  }
};
