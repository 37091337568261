import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

function Gauge({ bureauData }) {
  return (
    <div style={{ alignItems: "center" }}>
      <ReactSpeedometer
        segments={3}
        segmentColors={["red", "orange", "green"]}
        value={bureauData?.credit_score}
        maxValue={800}
        customSegmentStops={[0, 266, 533, 800]}
        // forceRender={true}
        ringWidth={10}
        needleColor="black"
        labelFontSize={0}
        valueTextFontSize={"24px"}
        textColor={"black"}
        valueTextFontWeight={400}
        height={180}
      />
    </div>
  );
}

export default Gauge;
