import React from 'react';
import './breadcrumb.scss';

const BreadCrumb = ({breadCrumbData}) => {
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadCrumbData?.map((data:any, index:number) => {
            if(data.path){
              return (
                <li key={index} className="breadcrumb-item"><a href={data.path}>{data.name}</a></li>
              )
            } else {
              return (
                <li key={index} className="breadcrumb-item active" aria-current="page">{data.name}</li>
              )
            }
          }) }
          {/* <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item"><a href="#">Library</a></li>
          <li className="breadcrumb-item active" aria-current="page">Data</li> */}
        </ol>
      </nav>
    </div>
  )
}
 
export default BreadCrumb
